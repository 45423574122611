import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Route, Navigate } from 'react-router-dom';

// Components
import CommonTransit from './CommonTransit';

//Actions
import { } from 'Actions';


class CommonRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  componentDidMount() {
    //const thisComponent = this.constructor.name;
  }

  
  
  render() {
    const { permission, path, exact, key, userRoutePermissions } = this.props;

    let otherProps = this.props;

    /**********************************************
     * Received a request to load a page that hasn't got any permission requirements
    **********************************************/

    /*
    console.log("this.props");
    console.log(this.props);
    console.log(JSON.stringify(this.props));
    console.log(`====== Common Route ======`);

    */
    let ComponentToRender = this.props.component;
    //console.log("ComponentToRender");
    //console.log(ComponentToRender);
    return (
      <ComponentToRender path={path} permission={permission} {...otherProps}  />
      
    )

 


    /*
    const DynamicRouteComponent = this.props.component;
    console.log("this.props.component");
    console.log(this.props.component);

    return (
      <Route key={key} exact={true} path={path} 
      element={<DynamicRouteComponent 
        userHasRoutePermission={true} 
        {...props} /> }
      />
      
    )
    */

    /*
    return (
      <Route render={(props) => <DynamicRouteComponent 
        userHasRoutePermission={true} 
        userBusinessRoutePermissions={false} 
        {...props} /> } path={path} />
        
    )
    */
      
  }
}


const mapStateToProps = (state, ownProps) => {

  return {
    
  };

};


const mapDispatchToProps = {
 
};


//export const CommonRoute = connect(mapStateToProps, mapDispatchToProps)(CommonRouteComponent);




export default connect(mapStateToProps, mapDispatchToProps)(CommonRoute);
/*
export default compose(connect(mapStateToProps, mapDispatchToProps))(CommonRoute);
*/
