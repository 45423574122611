import React from 'react';
import { render } from 'react-dom';
import { Provider } from 'react-redux';
import { createRoot } from 'react-dom/client';    //new

import Root from 'Containers/Root';
import store from 'Store/ConfigureStore';                                   //Setup Local Redux Store
import firebaseAuth from 'Store/ConfigureFirebase';                         //Setup Firebase
import { saveLocalStorageState } from './store/localStorage';               //Storage Options - Local Storage
import { saveSessionStorageState } from './store/sessionStorage';           //Storage Options - Session Storage

/*
//Langauge Support
import {I18nextProvider} from "react-i18next";
import i18next from "i18next";


import common_en from "./translations/en/common.json";
import common_pt from "./translations/pt/common.json";
import common_de from "./translations/de/common.json";


i18next.init({
  interpolation: { escapeValue: false },  // React already does escaping
  lng: 'en',                              // language to use
  resources: {
      en: {
          common: common_en               // 'common' is our custom namespace
      },
      pt: {
        common: common_pt
      },
      de: {
          common: common_de
      },
  },
});
*/


if (process.env.NODE_ENV !== 'production') {
  console.log('Looks like we are in development mode!');
}

__webpack_nonce__ = '<%=nonce%>';

store.subscribe(() => {
  //saveLocalStorageState(store.getState());
  saveSessionStorageState(store.getState());
});

const container = document.getElementById('root');
const root = createRoot(container); 
root.render(
  <Provider store={store}>
    <React.StrictMode>
      <Root />
    </React.StrictMode>
   </Provider>
);


