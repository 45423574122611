import { v1 as uuidv1 } from 'uuid';
import { userChartOptionsUpdateV1R, toggleBusinessFilterSelectionV1R, setBusinessFilterSelectionV1R } from 'Reducers/Analytics';






/********************************************************************
* 
********************************************************************/
export function toggleBusinessFilterSelectionV1(data = {}) {
  return (dispatch, getState) => {
    return new Promise(((resolve, reject) => {
      dispatch(toggleBusinessFilterSelectionV1R(JSON.parse(JSON.stringify(data))));
      resolve();
    }));
  };
}
export function setBusinessFilterSelectionV1(data = {}) {
  return (dispatch, getState) => {
    return new Promise(((resolve, reject) => {
      dispatch(setBusinessFilterSelectionV1R(JSON.parse(JSON.stringify(data))));
      resolve();
    }));
  };
}


/********************************************************************
* 
********************************************************************/
export function setStateAnalyticsUserChartOptions(data = {}) {
  return (dispatch, getState) => {
    return new Promise(((resolve, reject) => {

      let { fieldPath = "", fieldValue = "",  } = data;

      dispatch(userChartOptionsUpdateV1R({fieldPath: fieldPath, fieldValue: fieldValue}));
      resolve();
      
    }));
  };
}

/********************************************************************
* Updating State by Field Path Id
********************************************************************/
export function updateStateByPathId(data = {}) {
  return (dispatch, getState) => {
    return new Promise(((resolve, reject) => {

      let { fieldPathId = "", fieldValue = "",  } = data;

      //Note: Dynamic updates in state don't appear to be working. Workaround as follows:
      if (fieldPathId === "Analytics_userChartOptions_locale") {
        dispatch(userChartOptionsUpdateV1R({fieldPath: "locale", fieldValue: fieldValue}));
      }
      if (fieldPathId === "Analytics_userChartOptions_weekdayStart") {
        dispatch(userChartOptionsUpdateV1R({fieldPath: "weekdayStart", fieldValue: fieldValue}));
      }
      if (fieldPathId === "Analytics_userChartOptions_weekdayNameFormat") {
        dispatch(userChartOptionsUpdateV1R({fieldPath: "weekdayNameFormat", fieldValue: fieldValue}));
      }
      if (fieldPathId === "Analytics_userChartOptions_dateFormatHour") {
        dispatch(userChartOptionsUpdateV1R({fieldPath: "dateFormatHour", fieldValue: fieldValue}));
      }
      if (fieldPathId === "Analytics_userChartOptions_dateFormatDay") {
        dispatch(userChartOptionsUpdateV1R({fieldPath: "dateFormatDay", fieldValue: fieldValue}));
      }
      if (fieldPathId === "Analytics_userChartOptions_dateFormatMonth") {
        dispatch(userChartOptionsUpdateV1R({fieldPath: "dateFormatMonth", fieldValue: fieldValue}));
      }
      if (fieldPathId === "Analytics_userChartOptions_dateFormatYear") {
        dispatch(userChartOptionsUpdateV1R({fieldPath: "dateFormatYear", fieldValue: fieldValue}));
      }

      if (fieldPathId === "Analytics_userChartOptions_timezone") {
        dispatch(userChartOptionsUpdateV1R({fieldPath: "timezone", fieldValue: fieldValue}));
      }
      if (fieldPathId === "Analytics_userChartOptions_timezoneName") {
        dispatch(userChartOptionsUpdateV1R({fieldPath: "timezoneName", fieldValue: fieldValue}));
      }
      
 
    }));
  };
}

