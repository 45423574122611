import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  userEmail: '',
  subscriptions: [],
  subscriptionData: {
    subscriptionActive: false,
  },
  fireAccessToken: '',
  fireUserId: '',
  hudAccessToken: '',
  hudRefreshToken: '',
  fireUser: '',
  portalLoginStatus: false,
};
  
const resetState = {
  userEmail: '',
  subscriptions: [],
  subscriptionData: {},
  fireAccessToken: '',
  fireUserId: '',
  hudAccessToken: '',
  hudRefreshToken: '',
  fireUser: '',
  loginStatus: false,
};


const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
        logoutUserR(state, action) {
          state.userEmail = '';
          state.fireAccessToken = '';
          state.fireUserId = '';
          state.hudAccessToken = '';
          state.hudRefreshToken = '';
          state.fireUser = '';
          state.portalLoginStatus = false;
        },
        setPortalLoginStatusR(state, action) {
          state.portalLoginStatus = action.payload;
        },
        setFireUserDataR(state, action) {
          console.log("action");
          console.log(action);
          console.log("action.payload");
          console.log(action.payload);

          let uid = '';
          try {
            uid = action.payload.user.uid;    //TO CHECK
          } catch (e) {
            uid = '';
          }
          if (uid === undefined) {
            uid = '';
          }
          let fireAccessToken = '';
          try {
            fireAccessToken = action.payload.user.accessToken;
          } catch (e) {
            fireAccessToken = '';
          }
          if (fireAccessToken === undefined) {
            fireAccessToken = '';
          }
          let userEmail = '';
          try {
            userEmail = action.payload.user.email;
          } catch (e) {
            userEmail = '';
          }
          if (userEmail === undefined) {
            userEmail = '';
          }
          
          state.userEmail = userEmail;
          state.fireUser = action.payload.user;
          state.fireUserId = uid;
          state.fireAccessToken = fireAccessToken;
   
        },
        setLocalAccessTokenR(state, action) {
          console.log("action");
          console.log(action);
          console.log("action.payload");
          console.log(action.payload);
          let accessToken = '';
          try {
            accessToken = action.payload.accessToken;
          } catch (e) {
            accessToken = '';
          }
          if (accessToken === undefined) {
            accessToken = '';
          }
          
          state.hudAccessToken = accessToken;
        },
        setLocalRefreshTokenR(state, action) {
          console.log("action");
          console.log(action);
          console.log("action.payload");
          console.log(action.payload);
          let refreshToken = '';
          try {
            refreshToken = action.payload.refreshToken;
          } catch (e) {
            refreshToken = '';
          }
          if (refreshToken === undefined) {
            refreshToken = '';
          }
          
          state.hudRefreshToken = refreshToken;
        },
        setLocalDataFromAccessTokenR(state, action) {
          console.log("action");
          console.log(action);
          console.log("action.payload");
          console.log(action.payload);
          let userEmail = '';
          try {
            userEmail = action.payload.email;
          } catch (e) {
            userEmail = '';
          }
          if (userEmail === undefined) {
            userEmail = '';
          }
          let fireUserId = '';
          try {
            fireUserId = action.payload.uid;
          } catch (e) {
            fireUserId = '';
          }
          if (fireUserId === undefined) {
            fireUserId = '';
          }
          //ORIGINAL AS OBJECT
          let subscriptionData = '';
          try {
            subscriptionData = action.payload.subscriptionData;
          } catch (e) {
            subscriptionData = '';
          }
          if (subscriptionData === undefined) {
            subscriptionData = '';
          }
          //NEW AS ARRAY
          let subscriptions = '';
          try {
            subscriptions = action.payload.subscriptionData;
          } catch (e) {
            subscriptions = '';
          }
          if (subscriptions === undefined) {
            subscriptions = '';
          }


          //[{"customerId":"cus_MF78uveluD8VNr","subscriptionId":"sub_1LWd4KF3qIr5i0iiMczvMfg2","priceId":"price_1LWFhNF3qIr5i0ii4Ua3jaVq","active":true,"status":"complete","planName":"Basic","planPrice":"$1.00","planId":"sub01","periodStart":1660469116,"periodEnd":1663147516}]


          
          state.subscriptions = subscriptions;          //NEW
          state.subscriptionData = subscriptionData;    //EXISTING/OLD
          state.userEmail = userEmail;
          state.fireUserId = fireUserId;

          
        },
        setSubscriptionsR(state, action) {
          let subscriptions = '';
          try {
            subscriptions = action.payload.subscriptionData;
          } catch (e) {
            subscriptions = '';
          }
          if (subscriptions === undefined) {
            subscriptions = '';
          }

          
          state.subscriptions = subscriptions;          //NEW
 
        },

        
    }
})

export const { setPortalLoginStatusR, setFireUserDataR, setLocalAccessTokenR, setLocalRefreshTokenR, setLocalDataFromAccessTokenR, logoutUserR, setSubscriptionsR } = authSlice.actions;

export default authSlice.reducer;


/** 
 * const { updateVersion } = appSlice.actions;
 * dispatch(updateVersion())
*/

//import { updateVersion } from './features/users/usersSlice'

//store.dispatch(updateVersion())

/*
//https://redux.js.org/tutorials/essentials/part-5-async-logic
const postsSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    // omit existing reducers here
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPosts.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchPosts.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched posts to the array
        state.posts = state.posts.concat(action.payload)
      })
      .addCase(fetchPosts.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
  }
})
*/