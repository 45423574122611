import { createSlice } from '@reduxjs/toolkit';
import { v1 as uuidv1 } from 'uuid';
import KeyMappings from 'Core/KeyMappings';

import {
  compareAsc,
  compareDesc,
} from "date-fns";

const initialState = {
  benchmarks: [
    {
      name: 'publicEngagementRate',
      title: 'Public Engagement Rate',
      desc: 'Public Engagement Rate Description',
      platform: 'facebook',   //all
      interval: 'monthly',
      metrics: [
        {
          fieldEndTime: "Wed, 1 Jun 2022 14:00:00 GMT",
          fieldValue: '0.49%',   //missing month on purpose for testing
        },
        {
          fieldEndTime: "Mon, 1 Aug 2022 14:00:00 GMT",
          fieldValue: '0.52%',
        },
        {
          fieldEndTime: "Thu, 1 Sep 2022 14:00:00 GMT",
          fieldValue: '0.52%',
        },
        {
          fieldEndTime: "Sat, 1 Oct 2022 14:00:00 GMT",
          fieldValue: '0.52%',
        },
        {
          fieldEndTime: "Tue, 1 Nov 2022 14:00:00 GMT",
          fieldValue: '0.63%',
        },
        {
          fieldEndTime: "Thu, 1 Dec 2022 14:00:00 GMT",
          fieldValue: '0.35%',
        }
      ]
    },
    {
      name: 'privateEngagementRate',
      title: 'Private Engagement Rate',
      desc: 'Private Engagement Rate Description',
      platform: 'facebook',   //all
      interval: 'monthly',
      metrics: [
        {
          fieldEndTime: "Wed, 1 Jun 2022 14:00:00 GMT",
          fieldValue: '4.70',   //missing month on purpose for testing
        },
        {
          fieldEndTime: "Mon, 1 Aug 2022 14:00:00 GMT",
          fieldValue: '4.90',
        },
        {
          fieldEndTime: "Thu, 1 Sep 2022 14:00:00 GMT",
          fieldValue: '4.85',
        },
        {
          fieldEndTime: "Sat, 1 Oct 2022 14:00:00 GMT",
          fieldValue: '4.24',
        },
        {
          fieldEndTime: "Tue, 1 Nov 2022 14:00:00 GMT",
          fieldValue: '5.03',
        },
        {
          fieldEndTime: "Thu, 1 Dec 2022 14:00:00 GMT",
          fieldValue: '3.95',
        }
      ]
    },
    {
      name: 'averageDailyImpressions',
      title: 'Average Daily Impressions',
      desc: 'desc',
      platform: 'facebook',   //all
      interval: 'monthly',
      metrics: [
        {
          fieldEndTime: "Wed, 1 Jun 2022 14:00:00 GMT",
          fieldValue: '4000',   //missing month on purpose for testing
        },
        {
          fieldEndTime: "Mon, 1 Aug 2022 14:00:00 GMT",
          fieldValue: '5000',
        },
        {
          fieldEndTime: "Thu, 1 Sep 2022 14:00:00 GMT",
          fieldValue: '5500',
        },
        {
          fieldEndTime: "Sat, 1 Oct 2022 14:00:00 GMT",
          fieldValue: '6000',
        },
        {
          fieldEndTime: "Tue, 1 Nov 2022 14:00:00 GMT",
          fieldValue: '5800',
        },
        {
          fieldEndTime: "Thu, 1 Dec 2022 14:00:00 GMT",
          fieldValue: '5300',
        }
      ]
    },
  ],



  //User Chart Selection / Grouping
  userDataSelection: {
    //Determine if we are showing a group or an individual
    activeSelection: [
      /*
      {
        type: "group",
        name: "fitnessLeaders",
        groupId: "1",
        searchIds: ["7708595435072660", "99989797897897897897789"],
      },
      {
        type: "single",
        name: "Bent Out of Shape Jewelry 423",
        pageId: "7708595435072660",
        searchIds: ["7708595435072660"],
      }
      */
    ],

    //Groups of your accounts that you have access to in user defined groups
    groups: [
      /*
      {
        groupId: "1",
        name: "fitnessLeaders",
        businesses: [
          {
            pageId:"7708595435072660",
            platform:"facebook",
            name:"Bent Out of Shape Jewelry 123",
            brandName:"Bent Out of Shape Jewelry 423",
            username:"bent out of shape jewelry 394",
            ssCategory:"Shopping District",
            category:"Shopping District",
          },
          {
            pageId:"99989797897897897897789",
            platform:"facebook",
            name:"Bent Out of Shape Jewelry 456",
            brandName:"Bent Out of Shape Jewelry 423",
            username:"bent out of shape jewelry 394",
            ssCategory:"Shopping District",
            category:"Shopping District",
          }
        ]
      },
      {
        groupId: "2",
        name: "marketLeaders",
        businesses: [
          {
            pageId:"7708595435072660",
            platform:"facebook",
            name:"Bent Out of Shape Jewelry 789",
            brandName:"Bent Out of Shape Jewelry 423",
            username:"bent out of shape jewelry 394",
            ssCategory:"Shopping District",
            category:"Shopping District",
          },
          {
            pageId:"99989797897897897897789",
            platform:"facebook",
            name:"Bent Out of Shape Jewelry 019",
            brandName:"Bent Out of Shape Jewelry 423",
            username:"bent out of shape jewelry 394",
            ssCategory:"Shopping District",
            category:"Shopping District",
          }
        ]
      }
      */
    ],
    //List of all accounts that you have access to
    pages: [
      /*
      {
        pageId:"7708595435072660",
        platform:"facebook",
        name:"Bent Out of Shape Jewelry 4233",
        brandName:"Bent Out of Shape Jewelry 423",
        username:"bent out of shape jewelry 394",
        ssCategory:"Shopping District",
        category:"Shopping District",
      },
      {
        pageId:"99989797897897897897789",
        platform:"facebook",
        name:"Bent Out of Shape Jewelry 4232",
        brandName:"Bent Out of Shape Jewelry 423",
        username:"bent out of shape jewelry 394",
        ssCategory:"Shopping District",
        category:"Shopping District",
      }
      */
    ]
  },



  userChartOptions:{
    timezone: 'Australia/Brisbane',
    timezoneName: '',
    weekdayStart: 0,
    weekdayNameFormat: 'long',        //short(Sun), long(Sunday)
    dateFormatYear: 'numeric',        //'numeric' | '2-digit'
    dateFormatMonth: 'short',       //'numeric' | '2-digit' | 'narrow' | 'short' | 'long'
    dateFormatDay: 'numeric',         //'numeric' | '2-digit'
    dateFormatHour: 'numeric',        //'numeric' | '2-digit'
    dateFormatTimeZoneName: 'short',  //'short' | 'long'
    dateFormat12Hour: true,           //Boolean
    locale: 'en-us',                  //en-au, en-us, etc




    //To be setup
    groupByQuarterDateFormatMonth: 'short',  //'inherit', 'numeric' | '2-digit' | 'narrow' | 'short' | 'long'
    //May need to not use intlFormat if we want Q1/Q2/Q3
    //if we are using Q1 etc, we need to of broken the dataset into the customer Q's before hand



    dateFormatQuarters: 'short',      //short(Jul), long(July), quarterName(Q1, Q2, Q3,Q4)
    finanicalYearStart: '7',          //7(July), allows for custom quatrs
    


    //custom finanical years

  },
  pageData: [],
  postData: [],
};
  

const resetState = {
  
};

function getVal(params) {
  let {source, defaultValue} = params;
  let value = defaultValue;
  try {
    value = source;
  } catch (e) {
    value = defaultValue;
  }
  if (value === undefined) {
    value = defaultValue;
  }
  return value;
}


const analyticsSlice = createSlice({
    name: 'analytics',
    initialState,
    reducers: {
        resetLocalStateAnalyticsR: () => resetState,
        /*
        resetLocalStateGameR(state, action) {
          console.log("resetLocalStateGameR");
            
          return resetState;


        },
        */

        toggleCommentVisibilityOnPostsV1R(state, action) {
          let showComments = 0;
          for (let x = 0; x < state.postData.length; x++) {
            if (state.postData[x]._id === action.payload) {
              showComments = getVal({source: state.postData[x].showComments,  defaultValue: 0,});
              if (showComments === 0) {
                state.postData[x].showComments = 1;
              } else {
                state.postData[x].showComments = 0;
              }
              x = state.postData.length;
            }
          }

        },
        toggleMetricsVisibilityOnPostsV1R(state, action) {
          let showMetrics = 0;
          for (let x = 0; x < state.postData.length; x++) {
            if (state.postData[x]._id === action.payload) {
              showMetrics = getVal({source: state.postData[x].showMetrics,  defaultValue: 0,});
              if (showMetrics === 0) {
                state.postData[x].showMetrics = 1;
              } else {
                state.postData[x].showMetrics = 0;
              }
              x = state.postData.length;
            }
          }

        },



        


        dynamicFieldUpdateV1R(state, action) {
          console.log("action");
          console.log(action);
          console.log("action.payload");
          console.log(action.payload);
          console.log(JSON.stringify(action.payload));

          //Doesn't appear to work...
          state[action.payload.fieldPath] = action.payload.fieldValue;
   


        },

        toggleBusinessFilterSelectionV1R(state, action) {
          console.log("action");
          console.log(action);
          console.log("action.payload");
          console.log(action.payload);
          console.log(JSON.stringify(action.payload));

          let displayName = getVal({source: action.payload?.displayName,  defaultValue: "",});
          let searchIds = getVal({source: action.payload?.searchIds,  defaultValue: [],});
          let itemType = getVal({source: action.payload?.type,  defaultValue: "single",});
          let selectionIdentifer = "";
          let businessNames = [];
          let platforms = [];
          let platform = "";
          if (itemType === "group") {
            selectionIdentifer = getVal({source: action.payload?.groupId,  defaultValue: "single",});
            businessNames = getVal({source: action.payload?.businessNames,  defaultValue: [],});
            platforms = getVal({source: action.payload?.platforms,  defaultValue: [],});
          } else if (itemType === "single") {
            selectionIdentifer = getVal({source: action.payload?.pageId,  defaultValue: "single",});
            platform = getVal({source: action.payload?.platform,  defaultValue: "",});
          }

          /********************************************************************************************************
           * userDataSelection.activeSelection is an array of what the user wants to see on the charts
           * - users can toggle these on/off whether to show in the reports or not
           * 
           * - type = group or single
           * 
           * - Find if the its already selected, and remove
           * - If not found, add
           *******************************************************************************************************/
          let foundExistingEntry = false;
          for (let x = 0; x < state.userDataSelection.activeSelection.length; x++) {
            if (itemType === "group") {
              if (state.userDataSelection.activeSelection[x].groupId === selectionIdentifer) {
                foundExistingEntry = true;
                state.userDataSelection.activeSelection.splice(x, 1);
              }
            } else if (itemType === "single") {
              if (state.userDataSelection.activeSelection[x].pageId === selectionIdentifer) {
                foundExistingEntry = true;
                state.userDataSelection.activeSelection.splice(x, 1);
              }
            }
          }
          if (foundExistingEntry === false) {
            //Add the item
            if (itemType === "group") {
              state.userDataSelection.activeSelection.push({
                type: itemType,
                name: displayName,
                groupId: selectionIdentifer,
                searchIds: searchIds,
                businessNames: businessNames,
                platforms: platforms,             
              });
            } else if (itemType === "single") {
              state.userDataSelection.activeSelection.push({
                type: itemType,
                name: displayName,
                pageId: selectionIdentifer,
                searchIds: searchIds,
                platform: platform,               
              });
            }
          }
        },

        
        setBusinessFilterSelectionV1R(state, action) {
          console.log("action");
          console.log(action);
          console.log("action.payload");
          console.log(action.payload);
          console.log(JSON.stringify(action.payload));

          let name = getVal({source: action.payload?.displayName,  defaultValue: "",});
          let searchIds = getVal({source: action.payload?.searchIds,  defaultValue: [],});
          let itemType = getVal({source: action.payload?.itemType,  defaultValue: "single",});
          let selectionIdentifer = "";
          let businessNames = [];
          let platforms = [];
          let platform = "";
          if (itemType === "group") {
            selectionIdentifer = getVal({source: action.payload?.groupId,  defaultValue: "single",});
            businessNames = getVal({source: action.payload?.businessNames,  defaultValue: [],});
            platforms = getVal({source: action.payload?.platforms,  defaultValue: [],});
          } else if (itemType === "single") {
            selectionIdentifer = getVal({source: action.payload?.pageId,  defaultValue: "single",});
            platform = getVal({source: action.payload?.platform,  defaultValue: "",});
          }

          /********************************************************************************************************
           * userDataSelection.activeSelection is an array of what the user wants to see on the charts
           * - users can toggle these on/off whether to show in the reports or not
           * 
           * - Reset to only the selected
           *******************************************************************************************************/
  
          //Clear and set to the new value only
          if (itemType === "group") {
            state.userDataSelection.activeSelection = [{
              type: itemType,
              name: name,
              groupId: selectionIdentifer,
              searchIds: searchIds,
              businessNames: businessNames,
              platforms: platforms,             
            }];
          } else if (itemType === "single") {
            state.userDataSelection.activeSelection = [{
              type: itemType,
              name: name,
              pageId: selectionIdentifer,
              searchIds: searchIds,
              platform: platform,               
            }];
          }
          
        },


        
        addAccountToPagesV1R(state, action) {
          //userDataSelection.groups
          //userDataSelection.pages

          let foundExistingRecord = false;
          for (let x = 0; x < state.userDataSelection.pages.length; x++) {
            if (state.userDataSelection.pages[x].name === action.payload?.name) {
              foundExistingRecord = true;
              for (const [key, value] of Object.entries(action.payload)) {
                if (key !== "name") {
                  //set the key to equal the value of the incoming object.
                  state.userDataSelection.pages[x][key] = action.payload[key];
                }
              }


            }
          }
          if (foundExistingRecord === false) {
            state.userDataSelection.pages.push(action.payload);
          }

        },

        createAccountGroupV1R(state, action) {
          //userDataSelection.groups
          //userDataSelection.pages

          state.userDataSelection.groups.push(action.payload);
  
        },
        
        userChartOptionsUpdateV1R(state, action) {
          console.log("action");
          console.log(action);
          console.log("action.payload");
          console.log(action.payload);
          console.log(JSON.stringify(action.payload));

          let allowedFieldUpdates = ["timezone","timezoneName","weekdayStart","weekdayNameFormat","dateFormatYear","dateFormatMonth","dateFormatDay","dateFormatHour",
          "dateFormatTimeZoneName","dateFormat12Hour","locale","groupByQuarterDateFormatMonth","dateFormatQuarters","finanicalYearStart",];

          if (allowedFieldUpdates.includes(action.payload.fieldPath)) {
            state.userChartOptions[action.payload.fieldPath] = action.payload.fieldValue;
          }

        },



        storeTestDataV1R(state, action) {
          console.log("action");
          console.log(action);
          console.log("action.payload");
          console.log(action.payload);

          state.pageData = action.payload;


        },
        addTestPageDataV1R(state, action) {
          console.log("action");
          console.log(action);
          console.log("action.payload");
          console.log(action.payload);

          state.pageData.push(action.payload);


        },


        storePostDataV1R(state, action) {
          console.log("action");
          console.log(action);
          console.log("action.payload");
          console.log(action.payload);

          state.postData = action.payload;


        },

        addPostDataV1R(state, action) {
          console.log("action");
          console.log(action);
          console.log("action.payload");
          console.log(action.payload);

          for (let x = 0; x < action.payload.length; x++) {
            let foundExistingPostId = false;
            for (let y = 0; y < state.postData.length; y++) {
              if (state.postData[y].postId === action.payload[x].postId) {
                foundExistingPostId = true;
                state.postData[y] = action.payload[x];    //overwrite
              }
            }
            if (foundExistingPostId === false) {
              state.postData.push(action.payload[x]);
            }
          }
          state.postData.sort((a,b) => {
            return compareDesc(new Date(a.postData?.created_time), new Date(b.postData?.created_time));
          })
        },
        

        /************************
         * ADD PROFILES
         * - Retrieve profiles from DB
         * - Store profiles in State / Update if already exists.
         ************************/
        addAccountProfileDataV1R(state, action) {
          let pageId = getVal({source: action.payload?.pageId,  defaultValue: false,});
          let insertRecord = true;
          if (pageId === false) {
            insertRecord = true;
          }

          for (let x = 0; x < state.pageData.length; x++) {
            if (state.pageData[x].pageId === pageId) {
              //Update
              insertRecord = false;
              let pageData = getVal({source: action.payload?.pageData,  defaultValue: false,});
              if (pageData !== false) { state.pageData[x].pageData = pageData }
              let pagePerf = getVal({source: action.payload?.pagePerf,  defaultValue: false,});
              if (pagePerf !== false) { state.pageData[x].pagePerf = pagePerf }
              
              //Do not worry about Metric updates or management here. Will be processed separately.

            }
          }
          if (insertRecord === true) {
            //INSERT
            state.pageData.push(action.payload);
          }
        },



        /************************
         * ADD/UPDATE PROFILE METRICS
         * - For a profile, find and add/update metrics
         * - This may be run multiple times as we page new data via separate API calls for data collection.
         * - Reason, too much data to retrieve to the browser in one go (413 error)
         ************************/
        updateProfileMetricsDataV1R(state, action) {
          let pageId = getVal({source: action.payload?.pageId,  defaultValue: false,});
          if (pageId === false) {
            return false;
          }
          let pageMetricsDaily = getVal({source: action.payload?.pageMetrics?.daily,  defaultValue: [],});
          let pageMetricsDay28 = getVal({source: action.payload?.pageMetrics?.day28,  defaultValue: [],});
          let pageMetricsLifetime = getVal({source: action.payload?.pageMetrics?.lifetime,  defaultValue: [],});

          for (let x = 0; x < state.pageData.length; x++) {
            if (state.pageData[x].pageId === pageId) {
              //Process the records against the correct pageId

              
              let state_pageMetrics = getVal({source: state.pageData[x].pageMetrics,  defaultValue: false,});
              if (state_pageMetrics === false) {
                state.pageData[x].pageMetrics = {
                  daily: [],
                  day28: [],
                  lifetime: [],
                }
              }
              let state_pageMetricsDaily = getVal({source: state.pageData[x].pageMetrics.daily,  defaultValue: false,});
              if (state_pageMetricsDaily === false) {
                state.pageData[x].pageMetrics.daily = [];
              }
              let state_pageMetricsDay28 = getVal({source: state.pageData[x].pageMetrics.day28,  defaultValue: false,});
              if (state_pageMetricsDay28 === false) {
                state.pageData[x].pageMetrics.day28 = [];
              }
              let state_pageMetricsLifetime = getVal({source: state.pageData[x].pageMetrics.lifetime,  defaultValue: false,});
              if (state_pageMetricsLifetime === false) {
                state.pageData[x].pageMetrics.lifetime = [];
              }



              for (let y = 0; y < pageMetricsDaily.length; y++) {
                let foundMatchingMetric = false;
                for (let z = 0; z < state.pageData[x].pageMetrics.daily.length; z++) {
                  if (pageMetricsDaily[y].name === state.pageData[x].pageMetrics.daily[z].name) {
                    //found a matching metric
                    foundMatchingMetric = true;


                    let mergedArray = state.pageData[x].pageMetrics.daily[z].metrics.map(obj1 => {
                      let obj2 = pageMetricsDaily[y].metrics.find(obj => obj.fieldEndTime === obj1.fieldEndTime);
                      return obj2 ? obj2 : obj1;
                    }).concat( pageMetricsDaily[y].metrics.filter( obj2 => !state.pageData[x].pageMetrics.daily[z].metrics.find(obj1 => obj1.fieldEndTime === obj2.fieldEndTime)));

                    //console.log("mergedArray");
                    //console.log(mergedArray);
                    //console.log(JSON.stringify(mergedArray));

                    state.pageData[x].pageMetrics.daily[z].metrics = mergedArray;

                  }
                }
                if (foundMatchingMetric === false) {
                  //insert
                  state.pageData[x].pageMetrics.daily.push(pageMetricsDaily[y]);
                }

              }




              for (let y = 0; y < pageMetricsDay28.length; y++) {
                let foundMatchingMetric = false;
                for (let z = 0; z < state.pageData[x].pageMetrics.day28.length; z++) {
                  if (pageMetricsDay28[y].name === state.pageData[x].pageMetrics.day28[z].name) {
                    //found a matching metric
                    foundMatchingMetric = true;


                    let mergedArray = state.pageData[x].pageMetrics.day28[z].metrics.map(obj1 => {
                      let obj2 = pageMetricsDay28[y].metrics.find(obj => obj.fieldEndTime === obj1.fieldEndTime);
                      return obj2 ? obj2 : obj1;
                    }).concat( pageMetricsDay28[y].metrics.filter( obj2 => !state.pageData[x].pageMetrics.day28[z].metrics.find(obj1 => obj1.fieldEndTime === obj2.fieldEndTime)));

                    //console.log("mergedArray");
                    //console.log(mergedArray);
                    //console.log(JSON.stringify(mergedArray));

                    state.pageData[x].pageMetrics.day28[z].metrics = mergedArray;

                  }
                }
                if (foundMatchingMetric === false) {
                  //insert
                  state.pageData[x].pageMetrics.day28.push(pageMetricsDay28[y]);
                }

              }







              /*
              if (state.pageData[x].pageMetrics.daily.length === 0) {
                //Insert all the records.
                state.pageData[x].pageMetrics.daily = pageMetricsDaily;

              } else {
                //Traverse the records
                for (let y = 0; y < state.pageData[x].pageMetrics.daily.length; y++) {
                  let metricName = getVal({source: state.pageData[x].pageMetrics.daily[y].name,  defaultValue: '',});
                  let foundMetricName = false;
                  for (let z = 0; z < pageMetricsDaily.length; z++) {
                    if (pageMetricsDaily[z].name === metricName) {
                      foundMetricName = true;
                      //Looking at the same metric in state and from the api call.
                      //Merge these two

                      let mergedArray = state.pageData[x].pageMetrics.daily[y].metrics.map(obj1 => {
                        let obj2 = pageMetricsDaily[z].metrics.find(obj => obj.fieldEndTime === obj1.fieldEndTime);
                        return obj2 ? obj2 : obj1;
                      }).concat( pageMetricsDaily[z].metrics.filter( obj2 => !state.pageData[x].pageMetrics.daily[y].metrics.find(obj1 => obj1.fieldEndTime === obj2.fieldEndTime)));

                      console.log("mergedArray");
                      console.log(mergedArray);
                      console.log(JSON.stringify(mergedArray));
                      state.pageData[x].pageMetrics.daily[y].metrics = mergedArray;


                      //state.pageData[x].pageMetrics.daily[y].metrics.push.apply(state.pageData[x].pageMetrics.daily[y].metrics, pageMetricsDaily[z].metrics);
                    }
                  }
                  if (foundMetricName === false) {
                    //add the metric
                    state.pageData[x].pageMetrics.daily[y].metrics.push()
                  }
                }
              }
              */
              /*
              for (let y = 0; y < state.pageData[x].pageMetrics.day28.length; y++) {
                let metricName = getVal({source: state.pageData[x].pageMetrics.day28[y].name,  defaultValue: '',});
                for (let z = 0; z < pageMetricsDay28.length; z++) {
                  if (pageMetricsDay28[z].name === metricName) {
                    state.pageData[x].pageMetrics.day28[y].metrics.push.apply(state.pageData[x].pageMetrics.day28[y].metrics, pageMetricsDay28[z].metrics);
                  }
                }
              }
              */


              /*
              /////////////////////////
              // CHECK Daily Data
              /////////////////////////
              for (let y = 0; y < state.pageData[x].pageMetrics.daily.length; y++) {
                let metricName = getVal({source: state.pageData[x].pageMetrics.daily[y].name,  defaultValue: '',});
                for (let z = 0; z < pageMetricsDaily.length; z++) {
                  if (pageMetricsDaily[z].name === metricName) {
                    //Check each metric for updates or inserts
                    let insertMetricField = true;
                    for (let a = 0; a < state.pageData[x].pageMetrics.daily[y].metrics.length; a++) {
                      for (let b = 0; b < pageMetricsDaily[z].metrics.length; b++) {
                        if (state.pageData[x].pageMetrics.daily[y].metrics[a].fieldEndTime === pageMetricsDaily[z].metrics[b].fieldEndTime) {
                          insertMetricField = false;
                          state.pageData[x].pageMetrics.daily[y].metrics[a].fieldValue = pageMetricsDaily[z].metrics[b].fieldValue;
                          b = pageMetricsDaily[z].metrics.length;
                        }
                      }
                    }
                    if (insertMetricField === true) {
                      state.pageData[x].pageMetrics.daily[y].metrics.push({
                        fieldEndTime: pageMetricsDaily[z].metrics[b].fieldEndTime,
                        fieldValue: pageMetricsDaily[z].metrics[b].fieldValue,
                      })
                    }
                    z = pageMetricsDay28.length;
                  }
                }
                
              }

              /////////////////////////
              // CHECK 28 Day Data
              /////////////////////////
              for (let y = 0; y < state.pageData[x].pageMetrics.day28.length; y++) {
                let metricName = getVal({source: state.pageData[x].pageMetrics.day28[y].name,  defaultValue: '',});
                for (let z = 0; z < pageMetricsDay28.length; z++) {
                  if (pageMetricsDay28[z].name === metricName) {
                    //Check each metric for updates or inserts
                    let insertMetricField = true;
                    for (let a = 0; a < state.pageData[x].pageMetrics.day28[y].metrics.length; a++) {
                      for (let b = 0; b < pageMetricsDay28[z].metrics.length; b++) {
                        if (state.pageData[x].pageMetrics.day28[y].metrics[a].fieldEndTime === pageMetricsDay28[z].metrics[b].fieldEndTime) {
                          insertMetricField = false;
                          state.pageData[x].pageMetrics.day28[y].metrics[a].fieldValue = pageMetricsDay28[z].metrics[b].fieldValue;
                          b = pageMetricsDay28[z].metrics.length;
                        }
                      }
                    }
                    if (insertMetricField === true) {
                      state.pageData[x].pageMetrics.day28[y].metrics.push({
                        fieldEndTime: pageMetricsDay28[z].metrics[b].fieldEndTime,
                        fieldValue: pageMetricsDay28[z].metrics[b].fieldValue,
                      })
                    }
                    z = pageMetricsDay28.length;
                  }
                }
                
              }
              */



            }
          }


        },

        

    }
})

export const { resetLocalStateAnalyticsR, toggleCommentVisibilityOnPostsV1R, toggleMetricsVisibilityOnPostsV1R, dynamicFieldUpdateV1R, toggleBusinessFilterSelectionV1R, setBusinessFilterSelectionV1R, addAccountToPagesV1R, createAccountGroupV1R, userChartOptionsUpdateV1R, storeTestDataV1R, addTestPageDataV1R, storePostDataV1R, addPostDataV1R,
  addAccountProfileDataV1R, updateProfileMetricsDataV1R,

} = analyticsSlice.actions;

export default analyticsSlice.reducer;


