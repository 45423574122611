import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  uiApiActivity: [],          //Manage the visibility of API Calls

};
  
const resetState = {
  uiApiActivity: [],          //Manage the visibility of API Calls

};


const apiUiSlice = createSlice({
    name: 'apiui',
    initialState,
    reducers: {
        setApiActivityR(state, action) {
          console.log("action");
          console.log(action);
          console.log("action.payload");
          console.log(action.payload);

          //{"btnId":"d09acc60-1c84-11ed-b1b4-e35fdbff87b6"}
          let btnId = '';
          try {
            btnId = action.payload.btnData.btnId;
          } catch (e) {
            btnId = '';
          }
          if (btnId === undefined) {
            btnId = '';
          }
          console.log(`REDUCER | setApiActivity | btnId: ${btnId}`);

          let foundRecord = false;
          for (let x = 0; x < state.uiApiActivity.length; x++) {
            if (state.uiApiActivity[x].id === btnId) {
              foundRecord = true;
            }
          }
          if (foundRecord === false) {
            state.uiApiActivity.push({
              id: btnId,
              status: 'inprogress', // 'inprogress/successful/failed'
              startTime: Date.now(),
            });
          }
        },
        updateApiActivityR(state, action) {
          //btnData: {"btnId":"d09acc60-1c84-11ed-b1b4-e35fdbff87b6"}, true/false
          let btnId = '';
          try {
            btnId = action.payload.btnData.btnId;
          } catch (e) {
            btnId = '';
          }
          if (btnId === undefined) {
            btnId = '';
          }
          console.log(`REDUCER | setApiActivity | btnId: ${btnId}`);
          let status = null;
          try {
            status = action.payload.status;
          } catch (e) {
            status = null;
          }
          if (status === undefined) {
            status = null;
          }
          console.log(`REDUCER | setApiActivity | status: ${status}`);

          if (status === true || status === false) {
            let foundRecord = false;
            for (let x = 0; x < state.uiApiActivity.length; x++) {
              if (state.uiApiActivity[x].id === btnId) {
                foundRecord = true;
                if (status === true) {
                  state.uiApiActivity[x].status = "successful";
                } else {
                  state.uiApiActivity[x].status = "failed";
                }
              }
            }
          }

          
        },
        removeApiActivityR(state, action) {
          let btnId = '';
          try {
            btnId = action.payload.btnData.btnId;
          } catch (e) {
            btnId = '';
          }
          if (btnId === undefined) {
            btnId = '';
          }
          for (let x = 0; x < state.uiApiActivity.length; x++) {
            if (state.uiApiActivity[x].id === btnId) {
              state.uiApiActivity.splice(x, 1);
            }
          }
        },
 
    }
})

export const { setApiActivityR, updateApiActivityR, removeApiActivityR } = apiUiSlice.actions;

export default apiUiSlice.reducer;

