import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    version: '',
    info: '',
};
  
const resetState = {
    version: '',
    info: '',
};


const appSlice = createSlice({
    name: 'app',
    initialState,
    reducers: {
        updateVersion(state, action) {
            console.log("action");
            console.log(action);
            console.log("action.payload");
            console.log(action.payload);

            state.version = "111aaaabbb";
        },
        infoVersion(state, action) {
            const { id, title, content } = action.payload
            const existingPost = state.find(post => post.id === id)
            if (existingPost) {
              existingPost.title = title
              existingPost.content = content
            }
          }
    }
})

export const { updateVersion } = appSlice.actions;

export default appSlice.reducer;


/** 
 * const { updateVersion } = appSlice.actions;
 * dispatch(updateVersion())
*/

//import { updateVersion } from './features/users/usersSlice'

//store.dispatch(updateVersion())

/*
//https://redux.js.org/tutorials/essentials/part-5-async-logic
const postsSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    // omit existing reducers here
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPosts.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchPosts.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched posts to the array
        state.posts = state.posts.concat(action.payload)
      })
      .addCase(fetchPosts.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
  }
})
*/