import Request from './Request';
import RequestRegion from './RequestRegion';

class GH_billingApi {

  static billingPaymentIntent(body, hudAccessToken) {
    console.warn('API | POST | Billing - Payment Intent');
    return Request.POST_json(`${process.env.GH_billingApi}/payment-intent/v1`, body, hudAccessToken);
  }
  static billingCreateCustomer(body, hudAccessToken) {
    console.warn('API | POST | Billing - Create Customer');
    return Request.POST_json(`${process.env.GH_billingApi}/create-customer/v1`, body, hudAccessToken);
  }
  static checkStatusOfOrder(body, hudAccessToken) {
    console.warn('API | POST | Billing - Check Status of Order');
    return Request.POST_json(`${process.env.GH_billingApi}/check-order/v1`, body, hudAccessToken);
  }
  static subscriptionCancel(body, hudAccessToken) {
    console.warn('API | POST | Billing - Subscription Cancel');
    return Request.POST_json(`${process.env.GH_billingApi}/cancel-subscription/v1`, body, hudAccessToken);
  }
  static subscribeToFreePlan(body, hudAccessToken) {
    console.warn('API | POST | Billing - Subscribe to Free Plan');
    return Request.POST_json(`${process.env.GH_billingApi}/free-subscription/v1`, body, hudAccessToken);
  }


}

export default GH_billingApi;

