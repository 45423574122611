import { createSlice } from '@reduxjs/toolkit';
import { v1 as uuidv1 } from 'uuid';
import KeyMappings from 'Core/KeyMappings';

const initialState = {
  socketStatus: false,        
  socketEventLog: [],
  glassId: '',
  demoStatus: false,
  loginStatus: false,
  loginTimestamp: 0,
  keyStateMappings: [],     //primary reference to keymaps  - onload, always referesh the keyStateMappings
  userkeyStateMappings: [          //each user and their key states
  /*
    {
      uid: 'Selby8923748278934728949234',
      keyStates: [],        //replica of keyStateMappings with userState fields. cutdown - controlName, defaultGameKey, value. (on/off/data)

    },
    {
      uid: 'Anton2934029042304',
      keyStates: [],        //replica of keyStateMappings with userState fields (Anton's key states) - components referenced by another uid will show their state.

    },
  */
  ],        
  accessToken: '',
  user: {},
  gameSync: true,

  keyQueues: [],    //queues for specific keys that may have relationships

  coPilot: {
    coPilotCrewId: "",            //My coPilot Crew Id (I'm pilot, others enter this in to become copilots)                   
    activeCoPilotId: "X2SF33YU",  //Who we are currently co-piloting for
    pilots: [
      {
        pilotCrewId: "X2SF33YU",
        pilotName: "SmokeyBarge",
      },
      {
        pilotCrewId: "YY34GD32F",
        pilotName: "Phil",
      },
      {
        pilotCrewId: "QWE218DDW",
        pilotName: "Ling Tow",
      },
    ],
  },

  activeProfileId: 'BASE_PROFILE',
  profiles: [
    /*
    {
      profileId: 'BASE_PROFILE',
      profileName: 'BASE001',
      layouts: {
        screenBarTop: true,
        screenBarBottom: false,
        controlBarLeft: true,
        controlBarRight: false,
      },
      controlBarTopList: [
        {
          type: 'button',
          controlName: 'chat',
          displayText: 'Chat',
          position: 1,
        },
        {
          type: 'button',
          controlName: 'visor',
          displayText: 'Wipe Visor',
          position: 2,
        },
        {
          type: 'button',
          controlName: 'emoteDance',
          displayText: 'Dance',
          position: 3,
        },
      ],
      controlBarBottomList: [
        {
          type: 'button',
          controlName: 'atc',
          displayText: 'ATC',
          position: 1,
        },
        {
          type: 'button',
          controlName: 'mobi',
          displayText: 'Mobi',
          position: 2,
        },
        {
          type: 'button',
          controlName: 'starmap',
          displayText: 'Star Map',
          position: 3,
        }
      ],
      activeScreen: 'ShipScreen',
      screens: [
        {
          name: 'ShipScreen',
          displayText: 'Ship',
          position: 1,
          layout: {
            columns: [
              {
                column: 1,
                panels: [
                  {
                    panelName: 'Emergency',
                    panelVersion: 1,
                  },
                  {
                    panelName: 'ShipGearVtol',
                    panelVersion: 1,
                  },
                  {
                    panelName: 'PowerFlow',
                    panelVersion: 1,
                  }
                ]
              },
              {
                column: 2,
                panels: [
                  {
                    panelName: 'PowerTriange',
                    panelVersion: 1,
                  },
                  {
                    panelName: 'ShieldDistribution',
                    panelVersion: 1,
                  },
                ]
              },
              {
                column: 3,
                panels: [
                  {
                    panelName: 'CombatTarget',
                    panelVersion: 1,
                  },
                  {
                    panelName: 'CombatMissles',
                    panelVersion: 1,
                  },
                  {
                    panelName: 'ShipScanning',
                    panelVersion: 1,
                  }
                ]
              },
            ]
          }
        },
        {
          name: 'PersonScreen',
          displayText: 'Person',
          position: 1,
          layout: {
            columns: [
              {
                column: 1,
                panels: [
                  {
                    panelName: 'Emergency',
                    panelVersion: 1,
                  },
                ]
              },
              {
                column: 2,
                panels: [
                  {
                    panelName: 'PowerTriange',
                    panelVersion: 1,
                  },
                ]
              },
              {
                column: 3,
                panels: [
                  {
                    panelName: 'CombatTarget',
                    panelVersion: 1,
                  },
                ]
              },
            ]
          }
        },
        {
          name: 'VehicleScreen',
          displayText: 'Vehicle',
          position: 1,
          layout: {
            columns: [
              {
                column: 1,
                panels: [
                  {
                    panelName: 'Emergency',
                    panelVersion: 1,
                  },
                ]
              },
              {
                column: 2,
                panels: [
                  {
                    panelName: 'PowerTriange',
                    panelVersion: 1,
                  },
                ]
              },
              {
                column: 3,
                panels: [
                  {
                    panelName: 'CombatTarget',
                    panelVersion: 1,
                  },
                ]
              },
            ]
          }
        },
        {
          name: 'CoPilotScreen',
          displayText: 'Crew',
          position: 1,
          layout: {
            columns: [
              {
                column: 1,
                panels: [
                  {
                    panelName: 'Emergency',
                    panelVersion: 1,
                  },
                ]
              },
              {
                column: 2,
                panels: [
                  {
                    panelName: 'PowerTriange',
                    panelVersion: 1,
                  },
                ]
              },
              {
                column: 3,
                panels: [
                  {
                    panelName: 'CombatTarget',
                    panelVersion: 1,
                  },
                ]
              },
            ]
          }
        },
        {
          name: 'MiningScreen',
          displayText: 'Mine',
          position: 1,
          layout: {
            columns: [
              {
                column: 1,
                panels: [
                  {
                    panelName: 'Emergency',
                    panelVersion: 1,
                  },
                ]
              },
              {
                column: 2,
                panels: [
                  {
                    panelName: 'PowerTriange',
                    panelVersion: 1,
                  },
                ]
              },
              {
                column: 3,
                panels: [
                  {
                    panelName: 'CombatTarget',
                    panelVersion: 1,
                  },
                ]
              },
            ]
          }
        }
      ]
    }
    */
  ]
};
  

const resetState = {
  socketStatus: false,       
  socketEventLog: [],
  glassId: '',
  demoStatus: false,
  loginStatus: false,
  loginTimestamp: 0,
  keyStateMappings: [],     
  userkeyStateMappings: [],        
  accessToken: '',
  user: {},
  gameSync: true,
  keyQueues: [],   
  coPilot: {
    coPilotCrewId: "",                           
    activeCoPilotId: "",  
    pilots: [],
  },
  activeProfileId: 'BASE_PROFILE',
  profiles: []
};

const gameSlice = createSlice({
    name: 'game',
    initialState,
    reducers: {
        resetLocalStateGameR: () => resetState,
        /*
        resetLocalStateGameR(state, action) {
          console.log("resetLocalStateGameR");
            
          return resetState;


        },
        */
        addPilotR(state, action) {
          console.log("action");
          console.log(action);
          console.log("action.payload");
          console.log(action.payload);

          let pilotCrewId = action.payload.pilotCrewId;
          let pilotName = action.payload.pilotName;
          console.log(`pilotCrewId: ${pilotCrewId}`);
          console.log(`pilotName: ${pilotName}`);

          //check if the pilot is already setup
          let pilotAlreadySetup = false;
          for (let x = 0; x < state.coPilot.pilots.length; x++) {
            if (state.coPilot.pilots[x].pilotCrewId === pilotCrewId) {
              state.coPilot.pilots[x].pilotName = pilotName;
            }
          }
          if (pilotAlreadySetup === false) {
            state.coPilot.pilots.push({
              pilotCrewId: pilotCrewId,
              pilotName: pilotName,
            });
          }

          /*****************************************************
           * ENSURE THERE IS A KEY MAPPINGS FOR THIS Pilot.
           ****************************************************/
          let foundUserKeyMappingsForCoPilot = false;
          for (let x = 0; x < state.userkeyStateMappings.length; x++) {
            if (state.userkeyStateMappings[x].uid === pilotCrewId) {
              //We already have a keyMappings for this co-pilot/pilot
              foundUserKeyMappingsForCoPilot = true;
            }
          } 
          if (foundUserKeyMappingsForCoPilot === false) {
            state.userkeyStateMappings.push({
              uid: pilotCrewId,
              keyStates: KeyMappings,   //array of keymappings
            });
          }


        },
        removePilotR(state, action) {
          console.log("action");
          console.log(action);
          console.log("action.payload");
          console.log(action.payload);

          let pilotCrewId = action.payload.pilotCrewId;
          console.log(`pilotCrewId: ${pilotCrewId}`);

          for (let x = 0; x < state.coPilot.pilots.length; x++) {
            if (state.coPilot.pilots[x].pilotCrewId === pilotCrewId) {
              state.coPilot.pilots.splice(x, 1);
            }
          }

          /*****************************************************
           * ENSURE THERE IS A KEY MAPPINGS FOR THIS Pilot.
           ****************************************************/
          for (let x = 0; x < state.userkeyStateMappings.length; x++) {
            if (state.userkeyStateMappings[x].uid === pilotCrewId) {
              state.userkeyStateMappings.splice(x, 1);
            }
          } 

        },
        setActivePilotR(state, action) {
          console.log("action");
          console.log(action);
          console.log("action.payload");
          console.log(action.payload);

          let pilotCrewId = action.payload.pilotCrewId;
          console.log(`pilotCrewId: ${pilotCrewId}`);

          state.coPilot.activeCoPilotId = pilotCrewId;

        },
        setCopilotCrewIdR(state, action) {
          console.log("action");
          console.log(action);
          console.log("action.payload");
          console.log(action.payload);

          let coPilotCrewId = action.payload.coPilotCrewId;
          console.log(`coPilotCrewId: ${coPilotCrewId}`);

          state.coPilot.coPilotCrewId = coPilotCrewId;

        },
        removeCopilotCrewIdR(state, action) {
          console.log("action");
          console.log(action);
          console.log("action.payload");
          console.log(action.payload);

          
          state.coPilot.coPilotCrewId = "";

        },
        shiftKeyQueueItemR(state, action) {
          console.log("action");
          console.log(action);
          console.log("action.payload");
          console.log(action.payload);

          let keyQueueName = action.payload;

          for (let x = 0; x < state.keyQueues.length; x++) {
            if (state.keyQueues[x].queueName === keyQueueName) {
              let currentQueue = state.keyQueues[x].queue;
              currentQueue.shift();
              state.keyQueues[x].queue = currentQueue;
            }
          }

        },
        shiftKeyInputQueueItemR(state, action) {
          console.log("action");
          console.log(action);
          console.log("action.payload");
          console.log(action.payload);

          console.log('testing removing an item from the key input queue to allow for rendering updates to components base on key input.');


        },
        setupBaseProfileR(state, action) {
          console.log("action");
          console.log(action);
          console.log("action.payload");
          console.log(action.payload);

          let baseProfile = {
            profileId: 'BASE_PROFILE',
            profileName: 'BASE001',
            layouts: {
              screenBarTop: true,
              screenBarBottom: false,
              controlBarLeft: true,
              controlBarRight: false,
            },
            controlBarTopList: [
              {
                type: 'button',
                controlName: 'chat',
                displayText: 'Chat',
                position: 1,
              },
              {
                type: 'button',
                controlName: 'visor',
                displayText: 'Wipe Visor',
                position: 2,
              },
              {
                type: 'button',
                controlName: 'emoteDance',
                displayText: 'Dance',
                position: 3,
              },
            ],
            controlBarBottomList: [
              {
                type: 'button',
                controlName: 'atc',
                displayText: 'ATC',
                position: 1,
              },
              {
                type: 'button',
                controlName: 'mobi',
                displayText: 'Mobi',
                position: 2,
              },
              {
                type: 'button',
                controlName: 'starmap',
                displayText: 'Star Map',
                position: 3,
              }
            ],
            activeScreen: 'ShipScreen',
            screens: [
              {
                name: 'ShipScreen',
                displayText: 'Ship',
                position: 1,
                layout: {
                  columns: [
                    {
                      column: 1,
                      panels: [
                        {
                          panelName: 'Emergency',
                          panelVersion: 1,
                        },
                        {
                          panelName: 'ShipGearVtol',
                          panelVersion: 1,
                        },
                        {
                          panelName: 'PowerFlow',
                          panelVersion: 1,
                        }
                      ]
                    },
                    {
                      column: 2,
                      panels: [
                        {
                          panelName: 'PowerTriange',
                          panelVersion: 1,
                        },
                        {
                          panelName: 'ShieldDistribution',
                          panelVersion: 1,
                        },
                      ]
                    },
                    {
                      column: 3,
                      panels: [
                        {
                          panelName: 'CombatTarget',
                          panelVersion: 1,
                        },
                        {
                          panelName: 'CombatMissles',
                          panelVersion: 1,
                        },
                        {
                          panelName: 'ShipScanning',
                          panelVersion: 1,
                        }
                      ]
                    },
                  ]
                }
              },
              {
                name: 'PersonScreen',
                displayText: 'Person',
                position: 1,
                layout: {
                  columns: [
                    {
                      column: 1,
                      panels: [
                        {
                          panelName: 'Emergency',
                          panelVersion: 1,
                        },
                      ]
                    },
                    {
                      column: 2,
                      panels: [
                        {
                          panelName: 'PowerTriange',
                          panelVersion: 1,
                        },
                      ]
                    },
                    {
                      column: 3,
                      panels: [
                        {
                          panelName: 'CombatTarget',
                          panelVersion: 1,
                        },
                      ]
                    },
                  ]
                }
              },
              {
                name: 'VehicleScreen',
                displayText: 'Vehicle',
                position: 1,
                layout: {
                  columns: [
                    {
                      column: 1,
                      panels: [
                        {
                          panelName: 'Emergency',
                          panelVersion: 1,
                        },
                      ]
                    },
                    {
                      column: 2,
                      panels: [
                        {
                          panelName: 'PowerTriange',
                          panelVersion: 1,
                        },
                      ]
                    },
                    {
                      column: 3,
                      panels: [
                        {
                          panelName: 'CombatTarget',
                          panelVersion: 1,
                        },
                      ]
                    },
                  ]
                }
              },
              {
                name: 'CoPilotScreen',
                displayText: 'Crew',
                position: 1,
                layout: {
                  columns: [
                    {
                      column: 1,
                      panels: [
                        {
                          panelName: 'Emergency',
                          panelVersion: 1,
                        },
                      ]
                    },
                    {
                      column: 2,
                      panels: [
                        {
                          panelName: 'PowerTriange',
                          panelVersion: 1,
                        },
                      ]
                    },
                    {
                      column: 3,
                      panels: [
                        {
                          panelName: 'CombatTarget',
                          panelVersion: 1,
                        },
                      ]
                    },
                  ]
                }
              },
              {
                name: 'MiningScreen',
                displayText: 'Mine',
                position: 1,
                layout: {
                  columns: [
                    {
                      column: 1,
                      panels: [
                        {
                          panelName: 'Emergency',
                          panelVersion: 1,
                        },
                      ]
                    },
                    {
                      column: 2,
                      panels: [
                        {
                          panelName: 'PowerTriange',
                          panelVersion: 1,
                        },
                      ]
                    },
                    {
                      column: 3,
                      panels: [
                        {
                          panelName: 'CombatTarget',
                          panelVersion: 1,
                        },
                      ]
                    },
                  ]
                }
              }
            ]
          };
    
          //this overrides any existing profiles (as part of inital build and design)
          state.profiles = [baseProfile];

        },
        changeProfileScreenR(state, action) {
          console.log("action");
          console.log(action);
          console.log("action.payload");
          console.log(action.payload);

          //Find current profile, change screen target
          let activeProfileId = action.payload.activeProfileId;
          let screenTarget = action.payload.screenTarget;

          console.log(`activeProfileId: ${activeProfileId}`);
          console.log(`screenTarget: ${screenTarget}`);
        
          const indexOfActiveProfile = state.profiles.findIndex(profiles => profiles.profileId === activeProfileId);   //array === array
          console.log(`indexOfActiveProfile: ${indexOfActiveProfile}`);

          state.profiles[indexOfActiveProfile].activeScreen = screenTarget;
          

        },
        toggleGameSyncR(state, action) {
          console.log("action");
          console.log(action);
          console.log("action.payload");
          console.log(action.payload);

          let currentGameSync = state.gameSync;

          if (currentGameSync === true) {
            state.gameSync = false;
          } else {
            state.gameSync = true;
          }

        },
        setUserDataR(state, action) {
          console.log("action");
          console.log(action);
          console.log("action.payload");
          console.log(action.payload);

          if (action.payload === undefined) {
            //EXIT
            console.warn("setUserDataR  |action.payload === undefined")
            return null;
          } 

          state.user = action.payload.user;
          try {
            state.accessToken = action.payload.user.accessToken;
          } catch (e) {}

          try {
            state.loginStatus = true;
            state.loginTimestamp = Date.now();

          } catch (e) {}

          ///////////////////////////////////////////////////////
          // Get current user profile
          ///////////////////////////////////////////////////////
          let userId = '';
          try {
            userId = action.payload.user.uid;
          } catch (e) {
            userId = '';
          }
          if (userId === undefined) {
            userId = '';
          }
          console.log("userId");
          console.log(userId);


          ///////////////////////////////////////////////////////
          // Setup state if not initialised | Sync if already exists
          ///////////////////////////////////////////////////////
          if (state.userkeyStateMappings.length === 0) {
            
            console.log("userkeyStateMappings | push the new user object");
            //Setup the current user. All crew/co-pilots are setup elsewhere
            state.userkeyStateMappings = [{
              uid: userId,
              keyStates: KeyMappings,   //array of keymappings
            }];
            
          } else {
            //Check if there are differences
            //Find the current user data (if not exist, then create as above)
            let userStatesFound = false;
            let userStatesFoundIndex = null;
            for (let x = 0; x < state.userkeyStateMappings.length; x++) {
              if (state.userkeyStateMappings[x].uid === userId) {
                userStatesFound = true;
                userStatesFoundIndex = x;
              }
            }
            //Do we want to clear existing records at this stage??
            if (userStatesFound === false) {
              console.log("userkeyStateMappings | push the new user object | user wasn't found");
              state.userkeyStateMappings = [{
                uid: userId,
                keyStates: KeyMappings,   //array of keymappings
              }];
            } 

            if (userStatesFound === true) {
              console.log("userkeyStateMappings | update each field and add missing ones for the user");
              //We want to check if there are any new keymappings to update / or the key bindings that may have changed.
              let foundControlName = false;
              for (let x = 0; x < KeyMappings.length; x++) {
                foundControlName = false;
                for (let y = 0; y < state.userkeyStateMappings[userStatesFoundIndex].keyStates.length; y++) {
                  if (KeyMappings[x].controlName === state.userkeyStateMappings[userStatesFoundIndex].keyStates[y].controlName) {
                    //matching record
                    state.userkeyStateMappings[userStatesFoundIndex].keyStates[y].defaultGameKey = KeyMappings[x].defaultGameKey; //defaultGameKey
                    foundControlName = true;

                  }
                }
                if (foundControlName === false) {
                  state.userkeyStateMappings[userStatesFoundIndex].keyStates.push(KeyMappings[x]);
                }
              }
            } 
          }

        },
        generateGlassIdR(state, action) {
          console.log("action");
          console.log(action);
          console.log("action.payload");
          console.log(action.payload);

          state.glassId = uuidv1();


          
          ///////////////////////////////////////////////////////
          // Setup state if not initialised
          ///////////////////////////////////////////////////////
          if (state.keyStateMappings.length === 0) {
            //load inital state
            state.keyStateMappings = KeyMappings;
          }
          ///////////////////////////////////////////////////////
          // Profiles (SETUP INITAL SCREEN)
          ///////////////////////////////////////////////////////
          if (state.profiles.length === 0) {
            //load inital state
            state.profiles.push({
              profileId: 'BASE_PROFILE',
              profileName: 'BASE001',
              layouts: {
                screenBarTop: true,
                screenBarBottom: false,
                controlBarLeft: true,
                controlBarRight: false,
              },
              controlBarTopList: [
                {
                  type: 'button',
                  controlName: 'globalChat',
                  displayText: 'Chat',
                  position: 1,
                },
                {
                  type: 'button',
                  controlName: 'wipeVisor',
                  displayText: 'Wipe Visor',
                  position: 2,
                },
              ],
              controlBarBottomList: [
                {
                  type: 'button',
                  controlName: 'atc',
                  displayText: 'ATC',
                  position: 1,
                },
                {
                  type: 'button',
                  controlName: 'mobi',
                  displayText: 'Mobi',
                  position: 2,
                },
                {
                  type: 'button',
                  controlName: 'starMap',
                  displayText: 'Star Map',
                  position: 3,
                }
              ],
              activeScreen: 'ShipScreen',
              screens: [
                {
                  name: 'ShipScreen',
                  displayText: 'Ship',
                  position: 1,
                  layout: {
                    columns: [
                      {
                        column: 1,
                        panels: [
                          {
                            panelName: 'Emergency',
                            panelVersion: 1,
                          },
                          {
                            panelName: 'ShipGearVtol',
                            panelVersion: 1,
                          },
                          {
                            panelName: 'PowerFlow',
                            panelVersion: 1,
                          }
                        ]
                      },
                      {
                        column: 2,
                        panels: [
                          {
                            panelName: 'PowerTriange',
                            panelVersion: 1,
                          },
                          {
                            panelName: 'ShieldDistribution',
                            panelVersion: 1,
                          },
                        ]
                      },
                      {
                        column: 3,
                        panels: [
                          {
                            panelName: 'CombatTarget',
                            panelVersion: 1,
                          },
                          {
                            panelName: 'CombatMissles',
                            panelVersion: 1,
                          },
                          {
                            panelName: 'ShipScanning',
                            panelVersion: 1,
                          }
                        ]
                      },
                    ]
                  }
                },
                {
                  name: 'PersonScreen',
                  displayText: 'Person',
                  position: 1,
                  layout: {
                    columns: [
                      {
                        column: 1,
                        panels: [
                          {
                            panelName: 'Emergency',
                            panelVersion: 1,
                          },
                        ]
                      },
                      {
                        column: 2,
                        panels: [
                          {
                            panelName: 'PowerTriange',
                            panelVersion: 1,
                          },
                        ]
                      },
                      {
                        column: 3,
                        panels: [
                          {
                            panelName: 'CombatTarget',
                            panelVersion: 1,
                          },
                        ]
                      },
                    ]
                  }
                },
                {
                  name: 'VehicleScreen',
                  displayText: 'Vehicle',
                  position: 1,
                  layout: {
                    columns: [
                      {
                        column: 1,
                        panels: [
                          {
                            panelName: 'Emergency',
                            panelVersion: 1,
                          },
                        ]
                      },
                      {
                        column: 2,
                        panels: [
                          {
                            panelName: 'PowerTriange',
                            panelVersion: 1,
                          },
                        ]
                      },
                      {
                        column: 3,
                        panels: [
                          {
                            panelName: 'CombatTarget',
                            panelVersion: 1,
                          },
                        ]
                      },
                    ]
                  }
                },
                {
                  name: 'CoPilotScreen',
                  displayText: 'Crew',
                  position: 1,
                  layout: {
                    columns: [
                      {
                        column: 1,
                        panels: [
                          {
                            panelName: 'Emergency',
                            panelVersion: 1,
                          },
                        ]
                      },
                      {
                        column: 2,
                        panels: [
                          {
                            panelName: 'PowerTriange',
                            panelVersion: 1,
                          },
                        ]
                      },
                      {
                        column: 3,
                        panels: [
                          {
                            panelName: 'CombatTarget',
                            panelVersion: 1,
                          },
                        ]
                      },
                    ]
                  }
                },
                {
                  name: 'MiningScreen',
                  displayText: 'Mine',
                  position: 1,
                  layout: {
                    columns: [
                      {
                        column: 1,
                        panels: [
                          {
                            panelName: 'Emergency',
                            panelVersion: 1,
                          },
                        ]
                      },
                      {
                        column: 2,
                        panels: [
                          {
                            panelName: 'PowerTriange',
                            panelVersion: 1,
                          },
                        ]
                      },
                      {
                        column: 3,
                        panels: [
                          {
                            panelName: 'CombatTarget',
                            panelVersion: 1,
                          },
                        ]
                      },
                    ]
                  }
                }
              ]
            })
          }

        },

        
        setDemoStatusR(state, action) {
          //Set DemoStatus
          state.demoStatus = true;


        },
        setLoginStatusR(state, action) {
          console.log("action");
          console.log(action);
          console.log("action.payload");
          console.log(action.payload);
          //Turn off demoStatus if we've logged in
          state.demoStatus = false;
          //Flag the account for login
          state.loginStatus = true;
          state.loginTimestamp = Date.now();


        },
        setLogoutStatusR(state, action) {
          console.log("action");
          console.log(action);
          console.log("action.payload");
          console.log(action.payload);

          state.demoStatus = false;
          state.loginStatus = false;
          state.loginTimestamp = 0;

        },
        updateKeyStateV2R(state, action) {
          console.log("action");
          console.log(action);
          console.log("action.payload");
          console.log(action.payload);

          //
          ///////////////////////////////////////////////////////
          // Get current user profile
          ///////////////////////////////////////////////////////
          let loggedInUserId = '';
          try {
            loggedInUserId = state.user.uid;
          } catch (e) {
            loggedInUserId = '';
          }
          if (loggedInUserId === undefined) {
            loggedInUserId = '';
          }
          console.log("loggedInUserId");
          console.log(loggedInUserId);

          ///////////////////////////////////////////////////////
          // Received Data
          ///////////////////////////////////////////////////////

          // RECEIVE CO-PILOT DATA
          let coPilotAction = '';
          try {
            coPilotAction = action.payload.coPilotAction;
          } catch (e) {
            coPilotAction = '';
          }
          if (coPilotAction === undefined) {
            coPilotAction = '';
          }
          let coPilotId = '';
          try {
            coPilotId = action.payload.coPilotId;
          } catch (e) {
            coPilotId = '';
          }
          if (coPilotId === undefined) {
            coPilotId = '';
          }

          let keyCommand = action.payload.keyCommand;
          let keyCommandT = action.payload.keyCommandT;

          //////////////////////////
          //Get the keySearch Data
          let keySearch = {};
          try {
            keySearch = action.payload.keySearch;
          } catch (e) {
            keySearch = {};
          }
          if (keySearch === undefined) {
            keySearch = {};
          }
          //////////////////////////

          let keyWasHeldDown = '';
          try {
            keyWasHeldDown = keySearch.keyWasHeldDown;
          } catch (e) {
            keyWasHeldDown = '';
          }
          if (keyWasHeldDown === undefined) {
            keyWasHeldDown = '';
          }
          let secondsHeld = '';
          try {
            secondsHeld = keySearch.secondsHeld;
          } catch (e) {
            secondsHeld = '';
          }
          if (secondsHeld === undefined) {
            secondsHeld = '';
          }
          let activeScreen = '';
          try {
            activeScreen = keySearch.activeScreen;
          } catch (e) {
            activeScreen = '';
          }
          if (activeScreen === undefined) {
            activeScreen = '';
          }

          /////////////////////////


          let userId = '';
          try {
            userId = action.payload.userId;
          } catch (e) {
            userId = '';
          }
          if (userId === undefined) {
            userId = '';
          }
          
          if (userId === '') {
            userId = loggedInUserId;
          }

          let setToValue = '';
          try {
            setToValue = action.payload.setToValue;
          } catch (e) {
            setToValue = '';
          }
          if (setToValue === undefined) {
            setToValue = '';
          }


          console.log(`keyCommand: ${JSON.stringify(keyCommand)}`);
          console.log(`keyCommandT: ${JSON.stringify(keyCommandT)}`);
          console.log(`userId: ${JSON.stringify(userId)}`);
          console.log(`coPilotAction: ${JSON.stringify(coPilotAction)}`);
          console.log(`coPilotId: ${JSON.stringify(coPilotId)}`);
          console.log(`setToValue: ${JSON.stringify(setToValue)}`);
          console.log(`secondsHeld: ${JSON.stringify(secondsHeld)}`);
          console.log(`keyWasHeldDown: ${JSON.stringify(keyWasHeldDown)}`);
          console.log(`activeScreen: ${JSON.stringify(activeScreen)}`);

          

          /**************************************************************
           * REQUIRED to update all state values of the key press.
           * If multiple commands use the same key, they should all transition state.
           * e.g. button to switch power on, should also toggle another control if its watching the button press.
           * --> For this reason, we update button command rather than control name.
           **************************************************************/
          //let indexOfUserData = null;
          //let keyMappingIndexesToUpdate = [];
          let keyCommandLowerCase = [];
          for (let x = 0; x < keyCommand.length; x++) {
            keyCommandLowerCase.push(keyCommand[x].toString().toLowerCase());
          }

          //For lookup, determine if its a press or hold key event
          let defaultGameKeyEvent = "press";
          if (keyWasHeldDown === true) {
            defaultGameKeyEvent = "hold";
          }


          console.log(`keyCommandLowerCase: ${JSON.stringify(keyCommandLowerCase)}`);

          for (let x = 0; x < state.userkeyStateMappings.length; x++) {
            if (coPilotAction === true) {
              console.log(`(coPilotId) coPilotAction === true`);
              /*************************************************
              * IF CO-PILOT COMMAND
              *************************************************/

              if (state.userkeyStateMappings[x].uid === coPilotId) {
                //indexOfUserData = x;
                for (let y = 0; y < state.userkeyStateMappings[x].keyStates.length; y++) {
                  if (JSON.stringify(state.userkeyStateMappings[x].keyStates[y].defaultGameKeyLookup) === JSON.stringify(keyCommandLowerCase) &&
                      state.userkeyStateMappings[x].keyStates[y].defaultGameKeyEvent === defaultGameKeyEvent) {
                    //keyMappingIndexesToUpdate.push(y);
                    ///////////////// UPDATE THIS FIELD (START) ////////////////////////

                    let selectionState = false;
                    let selectionValue = '';

                    console.log(`(coPilotId) keyCommandT: ${keyCommandT}`);
                    if (keyCommandT === "00") {
                      //This is an unknown state
                      //Get current selectionState
                      try {
                        selectionState = state.userkeyStateMappings[x].keyStates[y].selectionState;
                      } catch (e) {
                        console.log(e);
                        console.log("(coPilotId) had error getting selectionState");
                        selectionState = false;
                      }
                      if (selectionState === undefined) {
                        selectionState = false;
                      }
                      //Toggle selectionState
                      if (selectionState === false) {
                        selectionState = true;
                      } else {
                        selectionState = false;
                      }
                    } else if (keyCommandT === "01") {
                      console.log(`(coPilotId) Transitioning state to true (from false)`);
                      selectionState = true;
                    } else if (keyCommandT === "10") {
                      console.log(`(coPilotId) Transitioning state to false (from true)`);
                      selectionState = false;
                    } else if (keyCommandT === "SET") {
                      console.log(`(coPilotId) Set a value`);
                      selectionValue = setValue;
                      selectionState = false;
                    }

                    //console.log(`${JSON.stringify(keyCommand)} === ${JSON.stringify(["f5"])} OR ${JSON.stringify(keyCommand)} === ${JSON.stringify(["f6"])} OR ${JSON.stringify(keyCommand)} === ${JSON.stringify(["f7"])}`);
                    if (JSON.stringify(keyCommand) === JSON.stringify(["f5"]) || 
                        JSON.stringify(keyCommand) === JSON.stringify(["f6"]) || 
                        JSON.stringify(keyCommand) === JSON.stringify(["f7"]) ||
                        JSON.stringify(keyCommand) === JSON.stringify(["f8"]) ||
                        JSON.stringify(keyCommand) === JSON.stringify(["F5"]) || 
                        JSON.stringify(keyCommand) === JSON.stringify(["F6"]) || 
                        JSON.stringify(keyCommand) === JSON.stringify(["F7"]) || 
                        JSON.stringify(keyCommand) === JSON.stringify(["F8"]) ) {
                      //console.log('found matching key for keyQueues (f key pressed');
                      //Add to keyQueue for processing
                      let foundItem = false;
                      for (let x = 0; x < state.keyQueues.length; x++) {
                        if (state.keyQueues[x].queueName === 'powerTriangle') {
                          foundItem = true;
                          let currentQueue = state.keyQueues[x].queue;
                          currentQueue.push(keyCommand);
                          state.keyQueues[x].queue = currentQueue;
                        }
                      }
                      if (foundItem === false) {
                        state.keyQueues = [
                          {
                            queueName: 'powerTriangle',
                            keys: ['f5', 'f6', 'f7', 'f8'],
                            queue: [keyCommand],
                          }
                        ];
                      }
                    }

                    state.userkeyStateMappings[x].keyStates[y].selectionState = selectionState;

                    //Handling components like PowerTriange and ShieldPower
                    if (keyCommandT === "SET") {
                      state.userkeyStateMappings[x].keyStates[y].selectionValue = selectionValue;
                    }
              
                    

                    ///////////////// UPDATE THIS FIELD (END) ////////////////////////
                  }
                }
              }
            } else {
              /*************************************************
              * IF LOCAL USER COMMAND
              *************************************************/

              if (state.userkeyStateMappings[x].uid === userId) {
                //indexOfUserData = x;
                for (let y = 0; y < state.userkeyStateMappings[x].keyStates.length; y++) {
                  if (JSON.stringify(state.userkeyStateMappings[x].keyStates[y].defaultGameKeyLookup) === JSON.stringify(keyCommandLowerCase) &&
                      state.userkeyStateMappings[x].keyStates[y].defaultGameKeyEvent === defaultGameKeyEvent) {
                    //keyMappingIndexesToUpdate.push(y);
                    ///////////////// UPDATE THIS FIELD (START) ////////////////////////

                    let selectionState = false;
                    let selectionValue = '';

                    console.log(`keyCommandT: ${keyCommandT}`);
                    if (keyCommandT === "00") {
                      //This is an unknown state
                      //Get current selectionState
                      try {
                        selectionState = state.userkeyStateMappings[x].keyStates[y].selectionState;
                      } catch (e) {
                        console.log(e);
                        console.log("had error getting selectionState");
                        selectionState = false;
                      }
                      if (selectionState === undefined) {
                        selectionState = false;
                      }
                      //Toggle selectionState
                      if (selectionState === false) {
                        selectionState = true;
                      } else {
                        selectionState = false;
                      }
                    } else if (keyCommandT === "01") {
                      console.log(`Transitioning state to true (from false)`);
                      selectionState = true;
                    } else if (keyCommandT === "10") {
                      console.log(`Transitioning state to false (from true)`);
                      selectionState = false;
                    } else if (keyCommandT === "SET") {
                      console.log(`Set a value`);
                      selectionValue = setValue;
                      selectionState = false;
                    }

                    //console.log(`${JSON.stringify(keyCommand)} === ${JSON.stringify(["f5"])} OR ${JSON.stringify(keyCommand)} === ${JSON.stringify(["f6"])} OR ${JSON.stringify(keyCommand)} === ${JSON.stringify(["f7"])}`);
                    if (JSON.stringify(keyCommand) === JSON.stringify(["f5"]) || 
                        JSON.stringify(keyCommand) === JSON.stringify(["f6"]) || 
                        JSON.stringify(keyCommand) === JSON.stringify(["f7"]) ||
                        JSON.stringify(keyCommand) === JSON.stringify(["f8"]) ||
                        JSON.stringify(keyCommand) === JSON.stringify(["F5"]) || 
                        JSON.stringify(keyCommand) === JSON.stringify(["F6"]) || 
                        JSON.stringify(keyCommand) === JSON.stringify(["F7"]) || 
                        JSON.stringify(keyCommand) === JSON.stringify(["F8"]) ) {
                      //console.log('found matching key for keyQueues (f key pressed');
                      //Add to keyQueue for processing
                      let foundItem = false;
                      for (let x = 0; x < state.keyQueues.length; x++) {
                        if (state.keyQueues[x].queueName === 'powerTriangle') {
                          foundItem = true;
                          let currentQueue = state.keyQueues[x].queue;
                          currentQueue.push(keyCommand);
                          state.keyQueues[x].queue = currentQueue;
                        }
                      }
                      if (foundItem === false) {
                        state.keyQueues = [
                          {
                            queueName: 'powerTriangle',
                            keys: ['f5', 'f6', 'f7', 'f8'],
                            queue: [keyCommand],
                          }
                        ];
                      }
                    }

                    state.userkeyStateMappings[x].keyStates[y].selectionState = selectionState;

                    //Handling components like PowerTriange and ShieldPower
                    if (keyCommandT === "SET") {
                      state.userkeyStateMappings[x].keyStates[y].selectionValue = selectionValue;
                    }


                    ///////////////// UPDATE THIS FIELD (END) ////////////////////////
                  }
                }
              }
            } //end if copilot command
          }

        },
        updateKeyStateR(state, action) {
          console.log("action");
          console.log(action);
          console.log("action.payload");
          console.log(action.payload);

          ///////////////////////////////////////////////////////
          // Setup state if not initialised
          ///////////////////////////////////////////////////////
          if (state.keyStateMappings.length === 0) {
            //load inital state
            state.keyStateMappings = KeyMappings;
          }
          ///////////////////////////////////////////////////////
          // Update state
          ///////////////////////////////////////////////////////
          console.log(`EXAMPLE RECORD | ${state.keyStateMappings[0].controlName} | ${state.keyStateMappings[0].buttonName}`);

          let keyCommand = action.payload.keyCommand;
          console.log(`keyCommand: ${JSON.stringify(keyCommand)}`);
          let keyCommandT = action.payload.keyCommandT;
          console.log(`keyCommandT: ${JSON.stringify(keyCommandT)}`);


          ///////////////////////////////////////////////////////
          // Convert Key Command
          ///////////////////////////////////////////////////////
          //keyCommand = ["Left Control","Z"] >>> ["left","control","z"];
          let translatedKeyCommand = [];
          for (let x = 0; x < keyCommand.length; x++) {
            if (keyCommand[x] === "Left Control") {
              translatedKeyCommand.push("left");
              translatedKeyCommand.push("control");
            } else if (keyCommand[x] === "Right Control") {
              translatedKeyCommand.push("right");
              translatedKeyCommand.push("control");
            } else if (keyCommand[x] === "Left Shift") {
              translatedKeyCommand.push("left");
              translatedKeyCommand.push("shift");
            } else if (keyCommand[x] === "Right Shift") {
              translatedKeyCommand.push("right");
              translatedKeyCommand.push("shift");
            } else if (keyCommand[x] === "Left Meta") {
              translatedKeyCommand.push("left");
              translatedKeyCommand.push("meta");
            } else if (keyCommand[x] === "Right Meta") {
              translatedKeyCommand.push("right");
              translatedKeyCommand.push("meta");
            } else if (keyCommand[x] === "Left Alt") {
              translatedKeyCommand.push("left");
              translatedKeyCommand.push("alt");
            } else if (keyCommand[x] === "Right Alt") {
              translatedKeyCommand.push("right");
              translatedKeyCommand.push("alt");
            } else {
              translatedKeyCommand.push(keyCommand[x].toLowerCase());
            }
          }
          console.log(`translatedKeyCommand: ${JSON.stringify(translatedKeyCommand)}`);
          
          /**************************************************************
           * REQUIRED to update all state values of the key press.
           * If multiple commands use the same key, they should all transition state.
           * e.g. button to switch power on, should also toggle another control if its watching the button press.
           * --> For this reason, we update button command rather than control name.
           **************************************************************/
          let keyMappingIndexesToUpdate = [];
          for (let x = 0; x < state.keyStateMappings.length; x++) {
            if (JSON.stringify(state.keyStateMappings[x].defaultGameKey) === JSON.stringify(translatedKeyCommand)) {
              keyMappingIndexesToUpdate.push(x);
            }
          }
          console.log(`Number of Indexes to update: ${keyMappingIndexesToUpdate.length}`);

          //// FIND THE RELEVANT KEY, CHANGE THE STATE
          //const indexOfKeyCommand = state.keyStateMappings.findIndex(keys => JSON.stringify(keys.defaultGameKey) === JSON.stringify(keyCommand));   //array === array
          //console.log(`Index of Key: ${indexOfKeyCommand}`);

          for (let x = 0; x < keyMappingIndexesToUpdate.length; x++) {

            let selectionState = false;

            console.log(`keyCommandT: ${keyCommandT}`);
            if (keyCommandT === "00") {
              //This is an unknown state
              //Get current selectionState
              console.log(`Attempting to get keyStateMapping for index: ${keyMappingIndexesToUpdate[x]}`);
              try {
                selectionState = state.keyStateMappings[keyMappingIndexesToUpdate[x]].selectionState;
              } catch (e) {
                console.log(e);
                console.log("had error getting selectionState");
                selectionState = false;
              }
              if (selectionState === undefined) {
                selectionState = false;
              }
              //Toggle selectionState
              if (selectionState === false) {
                selectionState = true;
              } else {
                selectionState = false;
              }
            } else if (keyCommandT === "01") {
              console.log(`Transitioning state to true (from false)`);
              selectionState = true;
            } else if (keyCommandT === "10") {
              console.log(`Transitioning state to false (from true)`);
              selectionState = false;
            }

            //TESTING KEY QUEUE FOR COMPONENT VISUAL AND FUNCTIONAL CHANGES


            //keyQueues

      
            //console.log(`${JSON.stringify(keyCommand)} === ${JSON.stringify(["f5"])} OR ${JSON.stringify(keyCommand)} === ${JSON.stringify(["f6"])} OR ${JSON.stringify(keyCommand)} === ${JSON.stringify(["f7"])}`);
            if (JSON.stringify(keyCommand) === JSON.stringify(["f5"]) || 
                JSON.stringify(keyCommand) === JSON.stringify(["f6"]) || 
                JSON.stringify(keyCommand) === JSON.stringify(["f7"]) ||
                JSON.stringify(keyCommand) === JSON.stringify(["f8"]) ||
                JSON.stringify(keyCommand) === JSON.stringify(["F5"]) || 
                JSON.stringify(keyCommand) === JSON.stringify(["F6"]) || 
                JSON.stringify(keyCommand) === JSON.stringify(["F7"]) || 
                JSON.stringify(keyCommand) === JSON.stringify(["F8"]) ) {
              //console.log('found matching key for keyQueues (f key pressed');
              //Add to keyQueue for processing
              let foundItem = false;
              for (let x = 0; x < state.keyQueues.length; x++) {
                if (state.keyQueues[x].queueName === 'powerTriangle') {
                  foundItem = true;
                  let currentQueue = state.keyQueues[x].queue;
                  currentQueue.push(keyCommand);
                  state.keyQueues[x].queue = currentQueue;
                }
              }
              if (foundItem === false) {
                state.keyQueues = [
                  {
                    queueName: 'powerTriangle',
                    keys: ['f5', 'f6', 'f7', 'f8'],
                    queue: [keyCommand],
                  }
                ];
              }
            }






            let keyInputQueue = [];
            try {
              keyInputQueue = state.keyStateMappings[keyMappingIndexesToUpdate[x]].keyInputQueue;
            } catch (e) {
              keyInputQueue = [];
            }
            if (keyInputQueue === undefined) {
              keyInputQueue = [];
            }

            keyInputQueue.push(keyCommand);




            
            console.log(`Updating state for index: ${keyMappingIndexesToUpdate[x]}`);
            console.log('adding: keyInputQueue');
            //Store selectionState
            state.keyStateMappings[keyMappingIndexesToUpdate[x]].selectionState = selectionState;
            state.keyStateMappings[keyMappingIndexesToUpdate[x]].keyInputQueue = keyInputQueue;
      
              
      

          } //end for loop



          /* ORIGINAL. 
          //// FIND THE RELEVANT KEY, CHANGE THE STATE
          const indexOfKeyCommand = state.keyStateMappings.findIndex(keys => JSON.stringify(keys.defaultGameKey) === JSON.stringify(translatedKeyCommand));   //array === array
          console.log(`Index of Key: ${indexOfKeyCommand}`);

          if (indexOfKeyCommand !== -1) {
            //Get current selectionState
            let selectionState = false;
            try {
              selectionState = state.keyStateMappings[indexOfKeyCommand].selectionState;
            } catch (e) {
              selectionState = false;
            }
            if (selectionState === undefined) {
              selectionState = false;
            }
            //Toggle selectionState
            if (selectionState === false) {
              selectionState = true;
            } else {
              selectionState = false;
            }

            if (keyCommandT === '01') {
              state.keyStateMappings[indexOfKeyCommand].selectionState = true;
            } else if (keyCommandT === '10') {
              state.keyStateMappings[indexOfKeyCommand].selectionState = false;
            } else {
              //Store selectionState
              state.keyStateMappings[indexOfKeyCommand].selectionState = selectionState;
            }


            

          } else {
            console.log('unable to find record - likely an issue setting the state store.');
          }
          */
        
        },
        updateLocalKeyStateV2R(state, action) {
          console.warn("updateLocalKeyStateV2R");

          console.log("action");
          console.log(action);
          console.log("action.payload");
          console.log(action.payload);

          ///////////////////////////////////////////////////////
          // Updating local state (will be provided the userId to update)
          ///////////////////////////////////////////////////////


          console.log(`action: ${JSON.stringify(action)}`);
          console.log(`action.payload: ${JSON.stringify(action.payload)}`);



          ///////////////////////////////////////////////////////
          // Received Data
          ///////////////////////////////////////////////////////
          // RECEIVE CO-PILOT DATA
          let coPilotAction = '';
          try {
            coPilotAction = action.payload.gameKeyStateUpdate.coPilotAction;
          } catch (e) {
            coPilotAction = '';
          }
          if (coPilotAction === undefined) {
            coPilotAction = '';
          }
          let coPilotId = '';
          try {
            coPilotId = action.payload.gameKeyStateUpdate.coPilotId;
          } catch (e) {
            coPilotId = '';
          }
          if (coPilotId === undefined) {
            coPilotId = '';
          }
          // RECEIVE GENERAL KEY DATA
          let keyCommand = [];
          try {
            keyCommand = action.payload.gameKeyStateUpdate.keyCommand;
          } catch (e) {
            keyCommand = [];
          }
          if (keyCommand === undefined) {
            keyCommand = [];
          }

          //When a ui button is pressed, receive data if it was held down
          let keyWasHeldDown = false;
          try {
            keyWasHeldDown = action.payload.gameKeyStateUpdate.keyWasHeldDown;
          } catch (e) {
            keyWasHeldDown = false;
          }
          if (keyWasHeldDown === undefined) {
            keyWasHeldDown = false;
          }

          let keyCommandT = '';
          try {
            keyCommandT = action.payload.gameKeyStateUpdate.keyCommandT;
          } catch (e) {
            keyCommandT = '';
          }
          if (keyCommandT === undefined) {
            keyCommandT = '';
          }
          let userId = '';
          try {
            userId = action.payload.gameKeyStateUpdate.userId;
          } catch (e) {
            userId = '';
          }
          if (userId === undefined) {
            userId = '';
          }

          let setToValue = '';
          try {
            setToValue = action.payload.gameKeyStateUpdate.setToValue;
          } catch (e) {
            setToValue = '';
          }
          if (setToValue === undefined) {
            setToValue = '';
          }


          console.log(`keyCommand: ${JSON.stringify(keyCommand)}`);
          console.log(`keyCommandT: ${JSON.stringify(keyCommandT)}`);
          console.log(`userId: ${JSON.stringify(userId)}`);
          console.log(`coPilotAction: ${JSON.stringify(coPilotAction)}`);
          console.log(`coPilotId: ${JSON.stringify(coPilotId)}`);
          console.log(`setToValue: ${JSON.stringify(setToValue)}`);
          console.log(`keyWasHeldDown: ${JSON.stringify(keyWasHeldDown)}`);

          

          /**************************************************************
           * REQUIRED to update all state values of the key press.
           * If multiple commands use the same key, they should all transition state.
           * e.g. button to switch power on, should also toggle another control if its watching the button press.
           * --> For this reason, we update button command rather than control name.
           **************************************************************/


          //For lookup, determine if its a press or hold key event
          let defaultGameKeyEvent = "press";
          if (keyWasHeldDown === true) {
            defaultGameKeyEvent = "hold";
          }


          //let indexOfUserData = null;
          //let keyMappingIndexesToUpdate = [];
          for (let x = 0; x < state.userkeyStateMappings.length; x++) {
            
            if (coPilotAction === true) {
              //console.log(`(coPilotId) coPilotAction === true`);
              /*************************************************
              * IF CO-PILOT COMMAND
              *************************************************/

              //console.log(`(coPilotId) ${state.userkeyStateMappings[x].uid} === ${coPilotId}`);
              if (state.userkeyStateMappings[x].uid === coPilotId) {
                
                //console.log(`(coPilotId) state.userkeyStateMappings[x].uid === coPilotId`);
                //indexOfUserData = x;
                for (let y = 0; y < state.userkeyStateMappings[x].keyStates.length; y++) {
                  
                  //console.log(`(coPilotId) Go through all keystates`);
                  if (JSON.stringify(state.userkeyStateMappings[x].keyStates[y].defaultGameKeyLookup) === JSON.stringify(keyCommand) &&
                      state.userkeyStateMappings[x].keyStates[y].defaultGameKeyEvent === defaultGameKeyEvent) {

                    // && state.userkeyStateMappings[x].keyStates[y].defaultGameKeyEvent === defaultGameKeyEvent



                    //keyMappingIndexesToUpdate.push(y);
                    ///////////////// UPDATE THIS FIELD (START) ////////////////////////
      
                    let selectionState = false;
                    let selectionValue = '';
      
                    console.log(`(coPilotId) keyCommandT: ${keyCommandT}`);
                    if (keyCommandT === "00") {
                      //This is an unknown state
                      //Get current selectionState
                      try {
                        selectionState = state.userkeyStateMappings[x].keyStates[y].selectionState;
                      } catch (e) {
                        console.log(e);
                        console.log("(coPilotId) had error getting selectionState");
                        selectionState = false;
                      }
                      if (selectionState === undefined) {
                        selectionState = false;
                      }
                      //Toggle selectionState
                      if (selectionState === false) {
                        selectionState = true;
                      } else {
                        selectionState = false;
                      }
                    } else if (keyCommandT === "01") {
                      console.log(`(coPilotId) Transitioning state to true (from false)`);
                      selectionState = true;
                    } else if (keyCommandT === "10") {
                      console.log(`(coPilotId) Transitioning state to false (from true)`);
                      selectionState = false;
                    } else if (keyCommandT === "SET") {
                      console.log(`(coPilotId) Set a value`);
                      selectionValue = setToValue;
                      selectionState = false;
                    }
      
                    //console.log(`${JSON.stringify(keyCommand)} === ${JSON.stringify(["f5"])} OR ${JSON.stringify(keyCommand)} === ${JSON.stringify(["f6"])} OR ${JSON.stringify(keyCommand)} === ${JSON.stringify(["f7"])}`);
                    if (JSON.stringify(keyCommand) === JSON.stringify(["f5"]) || 
                        JSON.stringify(keyCommand) === JSON.stringify(["f6"]) || 
                        JSON.stringify(keyCommand) === JSON.stringify(["f7"]) ||
                        JSON.stringify(keyCommand) === JSON.stringify(["f8"]) ||
                        JSON.stringify(keyCommand) === JSON.stringify(["F5"]) || 
                        JSON.stringify(keyCommand) === JSON.stringify(["F6"]) || 
                        JSON.stringify(keyCommand) === JSON.stringify(["F7"]) || 
                        JSON.stringify(keyCommand) === JSON.stringify(["F8"]) ) {
                      //console.log('found matching key for keyQueues (f key pressed');
                      //Add to keyQueue for processing
                      let foundItem = false;
                      for (let x = 0; x < state.keyQueues.length; x++) {
                        if (state.keyQueues[x].queueName === 'powerTriangle') {
                          foundItem = true;
                          let currentQueue = state.keyQueues[x].queue;
                          currentQueue.push(keyCommand);
                          state.keyQueues[x].queue = currentQueue;
                        }
                      }
                      if (foundItem === false) {
                        state.keyQueues = [
                          {
                            queueName: 'powerTriangle',
                            keys: ['f5', 'f6', 'f7', 'f8'],
                            queue: [keyCommand],
                          }
                        ];
                      }
                    }
      
                    state.userkeyStateMappings[x].keyStates[y].selectionState = selectionState;

                    //Handling components like PowerTriange and ShieldPower
                    if (keyCommandT === "SET") {
                      console.warn("REDUCER | SETTING SELECTION-VALUE");
                      state.userkeyStateMappings[x].keyStates[y].selectionValue = selectionValue;
                    }
              
                    ///////////////// UPDATE THIS FIELD (END) ////////////////////////
                  }
                }
              }


            } else {
              /*************************************************
              * IF LOCAL USER COMMAND
              *************************************************/

              if (state.userkeyStateMappings[x].uid === userId) {
                //indexOfUserData = x;
                for (let y = 0; y < state.userkeyStateMappings[x].keyStates.length; y++) {
                  console.warn("cycle keyStates");
                  
                  //console.warn(`${JSON.stringify(state.userkeyStateMappings[x].keyStates[y].defaultGameKeyLookup)} === ${JSON.stringify(keyCommand)}`);
                  if (JSON.stringify(state.userkeyStateMappings[x].keyStates[y].defaultGameKeyLookup) === JSON.stringify(keyCommand) &&
                  state.userkeyStateMappings[x].keyStates[y].defaultGameKeyEvent === defaultGameKeyEvent) {
                    console.warn("============= < FOUND GAMEKEY MATCH > =======================");
                    //keyMappingIndexesToUpdate.push(y);
                    ///////////////// UPDATE THIS FIELD (START) ////////////////////////
      
                    let selectionState = false;
                    let selectionValue = '';
      
                    console.warn(`PROCESSING FOR USER: keyCommandT: ${keyCommandT}`);
                    if (keyCommandT === "00") {
                      //This is an unknown state
                      //Get current selectionState
                      try {
                        selectionState = state.userkeyStateMappings[x].keyStates[y].selectionState;
                      } catch (e) {
                        console.log(e);
                        console.log("had error getting selectionState");
                        selectionState = false;
                      }
                      if (selectionState === undefined) {
                        selectionState = false;
                      }
                      //Toggle selectionState
                      if (selectionState === false) {
                        selectionState = true;
                      } else {
                        selectionState = false;
                      }
                    } else if (keyCommandT === "01") {
                      console.log(`Transitioning state to true (from false)`);
                      selectionState = true;
                    } else if (keyCommandT === "10") {
                      console.log(`Transitioning state to false (from true)`);
                      selectionState = false;
                    } else if (keyCommandT === "SET") {
                      console.log(`Set a value`);
                      selectionValue = setToValue;
                      selectionState = false;
                    }
      
                    //console.log(`${JSON.stringify(keyCommand)} === ${JSON.stringify(["f5"])} OR ${JSON.stringify(keyCommand)} === ${JSON.stringify(["f6"])} OR ${JSON.stringify(keyCommand)} === ${JSON.stringify(["f7"])}`);
                    if (JSON.stringify(keyCommand) === JSON.stringify(["f5"]) || 
                        JSON.stringify(keyCommand) === JSON.stringify(["f6"]) || 
                        JSON.stringify(keyCommand) === JSON.stringify(["f7"]) ||
                        JSON.stringify(keyCommand) === JSON.stringify(["f8"]) ||
                        JSON.stringify(keyCommand) === JSON.stringify(["F5"]) || 
                        JSON.stringify(keyCommand) === JSON.stringify(["F6"]) || 
                        JSON.stringify(keyCommand) === JSON.stringify(["F7"]) || 
                        JSON.stringify(keyCommand) === JSON.stringify(["F8"]) ) {
                      //console.log('found matching key for keyQueues (f key pressed');
                      //Add to keyQueue for processing
                      let foundItem = false;
                      for (let x = 0; x < state.keyQueues.length; x++) {
                        if (state.keyQueues[x].queueName === 'powerTriangle') {
                          foundItem = true;
                          let currentQueue = state.keyQueues[x].queue;
                          currentQueue.push(keyCommand);
                          state.keyQueues[x].queue = currentQueue;
                        }
                      }
                      if (foundItem === false) {
                        state.keyQueues = [
                          {
                            queueName: 'powerTriangle',
                            keys: ['f5', 'f6', 'f7', 'f8'],
                            queue: [keyCommand],
                          }
                        ];
                      }
                    }
      
      
      
      
      
                    /*
                    let keyInputQueue = [];
                    try {
                      keyInputQueue = state.keyStateMappings[keyMappingIndexesToUpdate[x]].keyInputQueue;
                    } catch (e) {
                      keyInputQueue = [];
                    }
                    if (keyInputQueue === undefined) {
                      keyInputQueue = [];
                    }
      
                    keyInputQueue.push(keyCommand);
                    */
      
      
      
      
                    
                    //console.log(`Updating state for index: ${keyMappingIndexesToUpdate[x]}`);
                    //console.log('adding: keyInputQueue');
      
      
                    //Store selectionState
                    //state.keyStateMappings[keyMappingIndexesToUpdate[x]].selectionState = selectionState;
                    //state.keyStateMappings[keyMappingIndexesToUpdate[x]].keyInputQueue = keyInputQueue;
      
      
                    state.userkeyStateMappings[x].keyStates[y].selectionState = selectionState;

                    //Handling components like PowerTriange and ShieldPower
                    if (keyCommandT === "SET") {
                      console.warn("REDUCER | SETTING SELECTION-VALUE");
                      state.userkeyStateMappings[x].keyStates[y].selectionValue = selectionValue;
                    }
              
      
      
                    ///////////////// UPDATE THIS FIELD (END) ////////////////////////
                  }
                }
              }


            }

          } //end for loop

        },
        updateLocalKeyStateR(state, action) {
          console.log("action");      //OLD - LIKELY NOT USED.... 
          console.log(action);
          console.log("action.payload");
          console.log(action.payload);

          ///////////////////////////////////////////////////////
          // Setup state if not initialised
          ///////////////////////////////////////////////////////
          if (state.keyStateMappings.length === 0) {
            //load inital state
            state.keyStateMappings = KeyMappings;
          }
          ///////////////////////////////////////////////////////
          // Update state
          ///////////////////////////////////////////////////////
          console.log(`EXAMPLE RECORD | ${state.keyStateMappings[0].controlName} | ${state.keyStateMappings[0].buttonName}`);

          let gameKeyStateUpdate = action.payload.gameKeyStateUpdate;
          /*
          let gameKeyStateUpdate = {
            keyCommand: keyCommand,   //Command key to lookup and update
            keyCommandT: keyCommandT,
            updatePattern: 'toggle',  //toggle, setTo, 
            setToValue: '',
          }
          */

          let keyCommand = gameKeyStateUpdate.keyCommand;
          console.log(`keyCommand: ${JSON.stringify(keyCommand)}`);
          let keyCommandT = gameKeyStateUpdate.keyCommandT;
          console.log(`keyCommandT: ${JSON.stringify(keyCommandT)}`);
          let updatePattern = gameKeyStateUpdate.updatePattern;
          let setToValue = gameKeyStateUpdate.setToValue;
          console.log(`updatePattern: ${JSON.stringify(updatePattern)}`);
          console.log(`setToValue: ${JSON.stringify(setToValue)}`);

          /**************************************************************
           * REQUIRED to update all state values of the key press.
           * If multiple commands use the same key, they should all transition state.
           * e.g. button to switch power on, should also toggle another control if its watching the button press.
           * --> For this reason, we update button command rather than control name.
           **************************************************************/
          let keyMappingIndexesToUpdate = [];
          for (let x = 0; x < state.keyStateMappings.length; x++) {
            if (JSON.stringify(state.keyStateMappings[x].defaultGameKey) === JSON.stringify(keyCommand)) {
              keyMappingIndexesToUpdate.push(x);
            }
          }
          console.log(`Number of Indexes to update: ${keyMappingIndexesToUpdate.length}`);

          //// FIND THE RELEVANT KEY, CHANGE THE STATE
          //const indexOfKeyCommand = state.keyStateMappings.findIndex(keys => JSON.stringify(keys.defaultGameKey) === JSON.stringify(keyCommand));   //array === array
          //console.log(`Index of Key: ${indexOfKeyCommand}`);

          for (let x = 0; x < keyMappingIndexesToUpdate.length; x++) {

            if (updatePattern === 'toggle') {

              let selectionState = false;
      
              console.log(`keyCommandT: ${keyCommandT}`);
              if (keyCommandT === "00") {
                //This is an unknown state
                //Get current selectionState
                console.log(`Attempting to get keyStateMapping for index: ${keyMappingIndexesToUpdate[x]}`);
                try {
                  selectionState = state.keyStateMappings[keyMappingIndexesToUpdate[x]].selectionState;
                } catch (e) {
                  console.log(e);
                  console.log("had error getting selectionState");
                  selectionState = false;
                }
                if (selectionState === undefined) {
                  selectionState = false;
                }
                //Toggle selectionState
                if (selectionState === false) {
                  selectionState = true;
                } else {
                  selectionState = false;
                }
              } else if (keyCommandT === "01") {
                console.log(`Transitioning state to true (from false)`);
                selectionState = true;
              } else if (keyCommandT === "10") {
                console.log(`Transitioning state to false (from true)`);
                selectionState = false;
              }

              
              console.log(`Updating state for index: ${keyMappingIndexesToUpdate[x]}`);
              //Store selectionState
              state.keyStateMappings[keyMappingIndexesToUpdate[x]].selectionState = selectionState;
        
              
      
            }

          } //end for loop
          
        },
        updateSocketStatusR(state, action) {
          console.log("action");
          console.log(action);
          console.log("action.payload");
          console.log(action.payload);

          let socketStatus = action.payload;
          console.log("socketStatus");
          console.log(socketStatus);
          
          state.socketStatus = socketStatus;

        },
        setSocketConnectionR(state, action) {
          console.log("action");
          console.log(action);
          console.log("action.payload");
          console.log(action.payload);

          
          state.socketStatus = true;

        },
        setSocketDisconnectionR(state, action) {
          console.log("action");
          console.log(action);
          console.log("action.payload");
          console.log(action.payload);

          
          state.socketStatus = false;

        },
        updateSocketEventLogR(state, action) {
          console.log("action");
          console.log(action);
          console.log("action.payload");
          console.log(action.payload);

          //TODO - if X logs, remove old ones...

          
          state.socketEventLog.push(action.payload);

        },
        /*
        xxxxxxxxxxxxx(state, action) {
          console.log("action");
          console.log(action);
          console.log("action.payload");
          console.log(action.payload);

        },
        */
        

    }
})

export const { addPilotR, removePilotR, setActivePilotR, setCopilotCrewIdR, removeCopilotCrewIdR, 
  shiftKeyQueueItemR, shiftKeyInputQueueItemR, setupBaseProfileR, changeProfileScreenR, toggleGameSyncR, 
  setUserDataR, generateGlassIdR, setLoginStatusR, setDemoStatusR, setLogoutStatusR, updateSocketStatusR, updateKeyStateV2R, 
  updateKeyStateR, updateLocalKeyStateV2R, updateLocalKeyStateR, 

  setSocketConnectionR, setSocketDisconnectionR, updateSocketEventLogR, resetLocalStateGameR

} = gameSlice.actions;

export default gameSlice.reducer;


/** 
 * const { updateVersion } = appSlice.actions;
 * dispatch(updateVersion())
*/

//import { updateVersion } from './features/users/usersSlice'

//store.dispatch(updateVersion())

/*
//https://redux.js.org/tutorials/essentials/part-5-async-logic
const postsSlice = createSlice({
  name: 'posts',
  initialState,
  reducers: {
    // omit existing reducers here
  },
  extraReducers(builder) {
    builder
      .addCase(fetchPosts.pending, (state, action) => {
        state.status = 'loading'
      })
      .addCase(fetchPosts.fulfilled, (state, action) => {
        state.status = 'succeeded'
        // Add any fetched posts to the array
        state.posts = state.posts.concat(action.payload)
      })
      .addCase(fetchPosts.rejected, (state, action) => {
        state.status = 'failed'
        state.error = action.error.message
      })
  }
})
*/