import _ from 'lodash';

import { v1 as uuidv1 } from 'uuid';
import KeyMappings from 'Core/KeyMappings';


const initialState = {
  glassId: '',
  loginStatus: false,
  loginTimestamp: 0,
  keyStateMappings: [],     //primary reference to keymaps  - onload, always referesh the keyStateMappings
  userkeyStateMappings: [          //each user and their key states
  /*
    {
      uid: 'Selby8923748278934728949234',
      keyStates: [],        //replica of keyStateMappings with userState fields. cutdown - controlName, defaultGameKey, value. (on/off/data)

    },
    {
      uid: 'Anton2934029042304',
      keyStates: [],        //replica of keyStateMappings with userState fields (Anton's key states) - components referenced by another uid will show their state.

    },
  */
  ],        
  accessToken: '',
  user: {},
  socketStatus: false,
  gameSync: true,

  keyQueues: [],    //queues for specific keys that may have relationships

  coPilot: {
    coPilotCrewId: "",            //My coPilot Crew Id (I'm pilot, others enter this in to become copilots)                   
    activeCoPilotId: "X2SF33YU",  //Who we are currently co-piloting for
    pilots: [
      {
        pilotCrewId: "X2SF33YU",
        pilotName: "SmokeyBarge",
      },
      {
        pilotCrewId: "YY34GD32F",
        pilotName: "Phil",
      },
      {
        pilotCrewId: "QWE218DDW",
        pilotName: "Ling Tow",
      },
    ],
  },

  activeProfileId: 'BASE_PROFILE',
  profiles: [
    /*
    {
      profileId: 'BASE_PROFILE',
      profileName: 'BASE001',
      layouts: {
        screenBarTop: true,
        screenBarBottom: false,
        controlBarLeft: true,
        controlBarRight: false,
      },
      controlBarTopList: [
        {
          type: 'button',
          controlName: 'chat',
          displayText: 'Chat',
          position: 1,
        },
        {
          type: 'button',
          controlName: 'visor',
          displayText: 'Wipe Visor',
          position: 2,
        },
        {
          type: 'button',
          controlName: 'emoteDance',
          displayText: 'Dance',
          position: 3,
        },
      ],
      controlBarBottomList: [
        {
          type: 'button',
          controlName: 'atc',
          displayText: 'ATC',
          position: 1,
        },
        {
          type: 'button',
          controlName: 'mobi',
          displayText: 'Mobi',
          position: 2,
        },
        {
          type: 'button',
          controlName: 'starmap',
          displayText: 'Star Map',
          position: 3,
        }
      ],
      activeScreen: 'ShipScreen',
      screens: [
        {
          name: 'ShipScreen',
          displayText: 'Ship',
          position: 1,
          layout: {
            columns: [
              {
                column: 1,
                panels: [
                  {
                    panelName: 'Emergency',
                    panelVersion: 1,
                  },
                  {
                    panelName: 'ShipGearVtol',
                    panelVersion: 1,
                  },
                  {
                    panelName: 'PowerFlow',
                    panelVersion: 1,
                  }
                ]
              },
              {
                column: 2,
                panels: [
                  {
                    panelName: 'PowerTriange',
                    panelVersion: 1,
                  },
                  {
                    panelName: 'ShieldDistribution',
                    panelVersion: 1,
                  },
                ]
              },
              {
                column: 3,
                panels: [
                  {
                    panelName: 'CombatTarget',
                    panelVersion: 1,
                  },
                  {
                    panelName: 'CombatMissles',
                    panelVersion: 1,
                  },
                  {
                    panelName: 'ShipScanning',
                    panelVersion: 1,
                  }
                ]
              },
            ]
          }
        },
        {
          name: 'PersonScreen',
          displayText: 'Person',
          position: 1,
          layout: {
            columns: [
              {
                column: 1,
                panels: [
                  {
                    panelName: 'Emergency',
                    panelVersion: 1,
                  },
                ]
              },
              {
                column: 2,
                panels: [
                  {
                    panelName: 'PowerTriange',
                    panelVersion: 1,
                  },
                ]
              },
              {
                column: 3,
                panels: [
                  {
                    panelName: 'CombatTarget',
                    panelVersion: 1,
                  },
                ]
              },
            ]
          }
        },
        {
          name: 'VehicleScreen',
          displayText: 'Vehicle',
          position: 1,
          layout: {
            columns: [
              {
                column: 1,
                panels: [
                  {
                    panelName: 'Emergency',
                    panelVersion: 1,
                  },
                ]
              },
              {
                column: 2,
                panels: [
                  {
                    panelName: 'PowerTriange',
                    panelVersion: 1,
                  },
                ]
              },
              {
                column: 3,
                panels: [
                  {
                    panelName: 'CombatTarget',
                    panelVersion: 1,
                  },
                ]
              },
            ]
          }
        },
        {
          name: 'CoPilotScreen',
          displayText: 'Crew',
          position: 1,
          layout: {
            columns: [
              {
                column: 1,
                panels: [
                  {
                    panelName: 'Emergency',
                    panelVersion: 1,
                  },
                ]
              },
              {
                column: 2,
                panels: [
                  {
                    panelName: 'PowerTriange',
                    panelVersion: 1,
                  },
                ]
              },
              {
                column: 3,
                panels: [
                  {
                    panelName: 'CombatTarget',
                    panelVersion: 1,
                  },
                ]
              },
            ]
          }
        },
        {
          name: 'MiningScreen',
          displayText: 'Mine',
          position: 1,
          layout: {
            columns: [
              {
                column: 1,
                panels: [
                  {
                    panelName: 'Emergency',
                    panelVersion: 1,
                  },
                ]
              },
              {
                column: 2,
                panels: [
                  {
                    panelName: 'PowerTriange',
                    panelVersion: 1,
                  },
                ]
              },
              {
                column: 3,
                panels: [
                  {
                    panelName: 'CombatTarget',
                    panelVersion: 1,
                  },
                ]
              },
            ]
          }
        }
      ]
    }
    */
  ]
};

const resetState = {
  glassId: '',
  loginStatus: false,
  loginTimestamp: 0,
  keyStateMappings: [],
  userkeyStateMappings: [],
  accessToken: '',
  user: {},
  socketStatus: false,
  gameSync: true,
  keyQueues: [],    //queues for specific keys that may have relationships
  coPilot: {
    coPilotCrewId: "",
    activeCoPilotId: "",  //Who we are currently co-piloting for
    pilots: [],
  },
  activeProfileId: 'BASE_PROFILE',
  profiles: [],
};





const StarGlassReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'RESET_LOCAL_STATE':
    {
      return resetState;
    }




    case 'ADD_PILOT': {
      const clone = Object.assign({}, state);
      console.log("ADD_PILOT");

      let pilotCrewId = action.data.pilotCrewId;
      let pilotName = action.data.pilotName;
      console.log(`pilotCrewId: ${pilotCrewId}`);
      console.log(`pilotName: ${pilotName}`);

      //check if the pilot is already setup
      let pilotAlreadySetup = false;
      for (let x = 0; x < clone.coPilot.pilots.length; x++) {
        if (clone.coPilot.pilots[x].pilotCrewId === pilotCrewId) {
          clone.coPilot.pilots[x].pilotName = pilotName;
        }
      }
      if (pilotAlreadySetup === false) {
        clone.coPilot.pilots.push({
          pilotCrewId: pilotCrewId,
          pilotName: pilotName,
        });
      }

      

      /*****************************************************
       * ENSURE THERE IS A KEY MAPPINGS FOR THIS Pilot.
       ****************************************************/
      let foundUserKeyMappingsForCoPilot = false;
      for (let x = 0; x < clone.userkeyStateMappings.length; x++) {
        if (clone.userkeyStateMappings[x].uid === pilotCrewId) {
          //We already have a keyMappings for this co-pilot/pilot
          foundUserKeyMappingsForCoPilot = true;
        }
      } 
      if (foundUserKeyMappingsForCoPilot === false) {
        clone.userkeyStateMappings.push({
          uid: pilotCrewId,
          keyStates: KeyMappings,   //array of keymappings
        });
      }

      return clone;
    }
    case 'REMOVE_PILOT': {
      const clone = Object.assign({}, state);
      console.log("REMOVE_PILOT");

      let pilotCrewId = action.data.pilotCrewId;
      console.log(`pilotCrewId: ${pilotCrewId}`);

      for (let x = 0; x < clone.coPilot.pilots.length; x++) {
        if (clone.coPilot.pilots[x].pilotCrewId === pilotCrewId) {
          clone.coPilot.pilots.splice(x, 1);
        }
      }

      /*****************************************************
       * ENSURE THERE IS A KEY MAPPINGS FOR THIS Pilot.
       ****************************************************/
      for (let x = 0; x < clone.userkeyStateMappings.length; x++) {
        if (clone.userkeyStateMappings[x].uid === pilotCrewId) {
          clone.userkeyStateMappings.splice(x, 1);
        }
      } 
      
 
      return clone;
    }
    case 'SET_ACTIVE_PILOT': {
      const clone = Object.assign({}, state);
      console.log("SET_ACTIVE_PILOT");

      let pilotCrewId = action.data.pilotCrewId;
      console.log(`pilotCrewId: ${pilotCrewId}`);

      clone.coPilot.activeCoPilotId = pilotCrewId;

      return clone;
    }
    case 'SET_COPILOT_CREWID': {
      const clone = Object.assign({}, state);
      console.log("SET_COPILOT_CREWID");


      console.log("action");
      console.log(action);
      console.log("action.data");
      console.log(action.data);


      let coPilotCrewId = action.data.coPilotCrewId;
      console.log(`coPilotCrewId: ${coPilotCrewId}`);

      clone.coPilot.coPilotCrewId = coPilotCrewId;

      return clone;
    }
    case 'REMOVE_COPILOT_CREWID': {
      const clone = Object.assign({}, state);
      console.log("REMOVE_COPILOT_CREWID");

      clone.coPilot.coPilotCrewId = "";

      return clone;
    }





    

















    

    case 'SHIFT_KEY_QUEUE_ITEM': {
      const clone = Object.assign({}, state);
      console.log("SHIFT_KEY_QUEUE_ITEM");

      let keyQueueName = action.data;

      for (let x = 0; x < clone.keyQueues.length; x++) {
        if (clone.keyQueues[x].queueName === keyQueueName) {
          let currentQueue = clone.keyQueues[x].queue;
          currentQueue.shift();
          clone.keyQueues[x].queue = currentQueue;
        }
      }

      return clone;
    }
    
    case 'SHIFT_KEY_INPUT_QUEUE_ITEM': {
      const clone = Object.assign({}, state);
      console.log("SHIFT_KEY_INPUT_QUEUE_ITEM");

      console.log('testing removing an item from the key input queue to allow for rendering updates to components base on key input.');

      return clone;
    }



    case 'SETUP_BASE_PROFILE': {
      const clone = Object.assign({}, state);
      console.log("SETUP_BASE_PROFILE");

      

      let baseProfile = {
        profileId: 'BASE_PROFILE',
        profileName: 'BASE001',
        layouts: {
          screenBarTop: true,
          screenBarBottom: false,
          controlBarLeft: true,
          controlBarRight: false,
        },
        controlBarTopList: [
          {
            type: 'button',
            controlName: 'chat',
            displayText: 'Chat',
            position: 1,
          },
          {
            type: 'button',
            controlName: 'visor',
            displayText: 'Wipe Visor',
            position: 2,
          },
          {
            type: 'button',
            controlName: 'emoteDance',
            displayText: 'Dance',
            position: 3,
          },
        ],
        controlBarBottomList: [
          {
            type: 'button',
            controlName: 'atc',
            displayText: 'ATC',
            position: 1,
          },
          {
            type: 'button',
            controlName: 'mobi',
            displayText: 'Mobi',
            position: 2,
          },
          {
            type: 'button',
            controlName: 'starmap',
            displayText: 'Star Map',
            position: 3,
          }
        ],
        activeScreen: 'ShipScreen',
        screens: [
          {
            name: 'ShipScreen',
            displayText: 'Ship',
            position: 1,
            layout: {
              columns: [
                {
                  column: 1,
                  panels: [
                    {
                      panelName: 'Emergency',
                      panelVersion: 1,
                    },
                    {
                      panelName: 'ShipGearVtol',
                      panelVersion: 1,
                    },
                    {
                      panelName: 'PowerFlow',
                      panelVersion: 1,
                    }
                  ]
                },
                {
                  column: 2,
                  panels: [
                    {
                      panelName: 'PowerTriange',
                      panelVersion: 1,
                    },
                    {
                      panelName: 'ShieldDistribution',
                      panelVersion: 1,
                    },
                  ]
                },
                {
                  column: 3,
                  panels: [
                    {
                      panelName: 'CombatTarget',
                      panelVersion: 1,
                    },
                    {
                      panelName: 'CombatMissles',
                      panelVersion: 1,
                    },
                    {
                      panelName: 'ShipScanning',
                      panelVersion: 1,
                    }
                  ]
                },
              ]
            }
          },
          {
            name: 'PersonScreen',
            displayText: 'Person',
            position: 1,
            layout: {
              columns: [
                {
                  column: 1,
                  panels: [
                    {
                      panelName: 'Emergency',
                      panelVersion: 1,
                    },
                  ]
                },
                {
                  column: 2,
                  panels: [
                    {
                      panelName: 'PowerTriange',
                      panelVersion: 1,
                    },
                  ]
                },
                {
                  column: 3,
                  panels: [
                    {
                      panelName: 'CombatTarget',
                      panelVersion: 1,
                    },
                  ]
                },
              ]
            }
          },
          {
            name: 'VehicleScreen',
            displayText: 'Vehicle',
            position: 1,
            layout: {
              columns: [
                {
                  column: 1,
                  panels: [
                    {
                      panelName: 'Emergency',
                      panelVersion: 1,
                    },
                  ]
                },
                {
                  column: 2,
                  panels: [
                    {
                      panelName: 'PowerTriange',
                      panelVersion: 1,
                    },
                  ]
                },
                {
                  column: 3,
                  panels: [
                    {
                      panelName: 'CombatTarget',
                      panelVersion: 1,
                    },
                  ]
                },
              ]
            }
          },
          {
            name: 'CoPilotScreen',
            displayText: 'Crew',
            position: 1,
            layout: {
              columns: [
                {
                  column: 1,
                  panels: [
                    {
                      panelName: 'Emergency',
                      panelVersion: 1,
                    },
                  ]
                },
                {
                  column: 2,
                  panels: [
                    {
                      panelName: 'PowerTriange',
                      panelVersion: 1,
                    },
                  ]
                },
                {
                  column: 3,
                  panels: [
                    {
                      panelName: 'CombatTarget',
                      panelVersion: 1,
                    },
                  ]
                },
              ]
            }
          },
          {
            name: 'MiningScreen',
            displayText: 'Mine',
            position: 1,
            layout: {
              columns: [
                {
                  column: 1,
                  panels: [
                    {
                      panelName: 'Emergency',
                      panelVersion: 1,
                    },
                  ]
                },
                {
                  column: 2,
                  panels: [
                    {
                      panelName: 'PowerTriange',
                      panelVersion: 1,
                    },
                  ]
                },
                {
                  column: 3,
                  panels: [
                    {
                      panelName: 'CombatTarget',
                      panelVersion: 1,
                    },
                  ]
                },
              ]
            }
          }
        ]
      };

      //this overrides any existing profiles (as part of inital build and design)
      clone.profiles = [baseProfile];

      return clone;
    }

    //this.props.changeProfileScreen({activeProfileId: this.props.activeProfileId, screenTarget: screenTarget});

    

    case 'CHANGE_PROFILE_SCREEN': {
      const clone = Object.assign({}, state);
      console.log("CHANGE_PROFILE_SCREEN");

      //Find current profile, change screen target
      let activeProfileId = action.data.activeProfileId;
      let screenTarget = action.data.screenTarget;

      console.log("action.data");
      console.log(action.data);
      console.log(`activeProfileId: ${activeProfileId}`);
      console.log(`screenTarget: ${screenTarget}`);
     
      const indexOfActiveProfile = clone.profiles.findIndex(profiles => profiles.profileId === activeProfileId);   //array === array
      console.log(`indexOfActiveProfile: ${indexOfActiveProfile}`);

      clone.profiles[indexOfActiveProfile].activeScreen = screenTarget;
      
      return clone;
    }

    
    case 'TOGGLE_GAME_SYNC': {
      const clone = Object.assign({}, state);
      console.log("TOGGLE_GAME_SYNC");

      let currentGameSync = clone.gameSync;

      if (currentGameSync === true) {
        clone.gameSync = false;
      } else {
        clone.gameSync = true;
      }

      return clone;
    }

    case 'SET_USER_DATA': {
      const clone = Object.assign({}, state);
      console.log("SET_USER_DATA");
      console.log("action");
      console.log(action);

      clone.user = action.data.user;
      try {
        clone.accessToken = action.data.user.accessToken;
      } catch (e) {}

      try {
        clone.loginStatus = true;
        clone.loginTimestamp = Date.now();

      } catch (e) {}

      ///////////////////////////////////////////////////////
      // Get current user profile
      ///////////////////////////////////////////////////////
      let userId = '';
      try {
        userId = action.data.user.uid;
      } catch (e) {
        userId = '';
      }
      if (userId === undefined) {
        userId = '';
      }
      console.log("userId");
      console.log(userId);


      ///////////////////////////////////////////////////////
      // Setup state if not initialised | Sync if already exists
      ///////////////////////////////////////////////////////
      if (clone.userkeyStateMappings.length === 0) {
        
        console.log("userkeyStateMappings | push the new user object");
        //Setup the current user. All crew/co-pilots are setup elsewhere
        clone.userkeyStateMappings = [{
          uid: userId,
          keyStates: KeyMappings,   //array of keymappings
        }];
        
      } else {
        //Check if there are differences
        //Find the current user data (if not exist, then create as above)
        let userStatesFound = false;
        let userStatesFoundIndex = null;
        for (let x = 0; x < clone.userkeyStateMappings.length; x++) {
          if (clone.userkeyStateMappings[x].uid === userId) {
            userStatesFound = true;
            userStatesFoundIndex = x;
          }
        }
        //Do we want to clear existing records at this stage??
        if (userStatesFound === false) {
          console.log("userkeyStateMappings | push the new user object | user wasn't found");
          clone.userkeyStateMappings = [{
            uid: userId,
            keyStates: KeyMappings,   //array of keymappings
          }];
        } 

        if (userStatesFound === true) {
          console.log("userkeyStateMappings | update each field and add missing ones for the user");
          //We want to check if there are any new keymappings to update / or the key bindings that may have changed.
          let foundControlName = false;
          for (let x = 0; x < KeyMappings.length; x++) {
            foundControlName = false;
            for (let y = 0; y < clone.userkeyStateMappings[userStatesFoundIndex].keyStates.length; y++) {
              if (KeyMappings[x].controlName === clone.userkeyStateMappings[userStatesFoundIndex].keyStates[y].controlName) {
                //matching record
                clone.userkeyStateMappings[userStatesFoundIndex].keyStates[y].defaultGameKey = KeyMappings[x].defaultGameKey; //defaultGameKey
                foundControlName = true;

              }
            }
            if (foundControlName === false) {
              clone.userkeyStateMappings[userStatesFoundIndex].keyStates.push(KeyMappings[x]);
            }
          }
        } 
      }

      return clone;
    }

    case 'GENERATE_GLASS_ID': {
      const clone = Object.assign({}, state);
      console.log("GENERATE_GLASS_ID");
      
      clone.glassId = uuidv1();


      ///////////////////////////////////////////////////////
      // Setup state if not initialised
      ///////////////////////////////////////////////////////
      if (clone.keyStateMappings.length === 0) {
        //load inital state
        clone.keyStateMappings = KeyMappings;
      }
      ///////////////////////////////////////////////////////
      // Profiles (SETUP INITAL SCREEN)
      ///////////////////////////////////////////////////////
      if (clone.profiles.length === 0) {
        //load inital state
        clone.profiles.push({
          profileId: 'BASE_PROFILE',
          profileName: 'BASE001',
          layouts: {
            screenBarTop: true,
            screenBarBottom: false,
            controlBarLeft: true,
            controlBarRight: false,
          },
          controlBarTopList: [
            {
              type: 'button',
              controlName: 'globalChat',
              displayText: 'Chat',
              position: 1,
            },
            {
              type: 'button',
              controlName: 'wipeVisor',
              displayText: 'Wipe Visor',
              position: 2,
            },
          ],
          controlBarBottomList: [
            {
              type: 'button',
              controlName: 'atc',
              displayText: 'ATC',
              position: 1,
            },
            {
              type: 'button',
              controlName: 'mobi',
              displayText: 'Mobi',
              position: 2,
            },
            {
              type: 'button',
              controlName: 'starMap',
              displayText: 'Star Map',
              position: 3,
            }
          ],
          activeScreen: 'ShipScreen',
          screens: [
            {
              name: 'ShipScreen',
              displayText: 'Ship',
              position: 1,
              layout: {
                columns: [
                  {
                    column: 1,
                    panels: [
                      {
                        panelName: 'Emergency',
                        panelVersion: 1,
                      },
                      {
                        panelName: 'ShipGearVtol',
                        panelVersion: 1,
                      },
                      {
                        panelName: 'PowerFlow',
                        panelVersion: 1,
                      }
                    ]
                  },
                  {
                    column: 2,
                    panels: [
                      {
                        panelName: 'PowerTriange',
                        panelVersion: 1,
                      },
                      {
                        panelName: 'ShieldDistribution',
                        panelVersion: 1,
                      },
                    ]
                  },
                  {
                    column: 3,
                    panels: [
                      {
                        panelName: 'CombatTarget',
                        panelVersion: 1,
                      },
                      {
                        panelName: 'CombatMissles',
                        panelVersion: 1,
                      },
                      {
                        panelName: 'ShipScanning',
                        panelVersion: 1,
                      }
                    ]
                  },
                ]
              }
            },
            {
              name: 'PersonScreen',
              displayText: 'Person',
              position: 1,
              layout: {
                columns: [
                  {
                    column: 1,
                    panels: [
                      {
                        panelName: 'Emergency',
                        panelVersion: 1,
                      },
                    ]
                  },
                  {
                    column: 2,
                    panels: [
                      {
                        panelName: 'PowerTriange',
                        panelVersion: 1,
                      },
                    ]
                  },
                  {
                    column: 3,
                    panels: [
                      {
                        panelName: 'CombatTarget',
                        panelVersion: 1,
                      },
                    ]
                  },
                ]
              }
            },
            {
              name: 'VehicleScreen',
              displayText: 'Vehicle',
              position: 1,
              layout: {
                columns: [
                  {
                    column: 1,
                    panels: [
                      {
                        panelName: 'Emergency',
                        panelVersion: 1,
                      },
                    ]
                  },
                  {
                    column: 2,
                    panels: [
                      {
                        panelName: 'PowerTriange',
                        panelVersion: 1,
                      },
                    ]
                  },
                  {
                    column: 3,
                    panels: [
                      {
                        panelName: 'CombatTarget',
                        panelVersion: 1,
                      },
                    ]
                  },
                ]
              }
            },
            {
              name: 'CoPilotScreen',
              displayText: 'Crew',
              position: 1,
              layout: {
                columns: [
                  {
                    column: 1,
                    panels: [
                      {
                        panelName: 'Emergency',
                        panelVersion: 1,
                      },
                    ]
                  },
                  {
                    column: 2,
                    panels: [
                      {
                        panelName: 'PowerTriange',
                        panelVersion: 1,
                      },
                    ]
                  },
                  {
                    column: 3,
                    panels: [
                      {
                        panelName: 'CombatTarget',
                        panelVersion: 1,
                      },
                    ]
                  },
                ]
              }
            },
            {
              name: 'MiningScreen',
              displayText: 'Mine',
              position: 1,
              layout: {
                columns: [
                  {
                    column: 1,
                    panels: [
                      {
                        panelName: 'Emergency',
                        panelVersion: 1,
                      },
                    ]
                  },
                  {
                    column: 2,
                    panels: [
                      {
                        panelName: 'PowerTriange',
                        panelVersion: 1,
                      },
                    ]
                  },
                  {
                    column: 3,
                    panels: [
                      {
                        panelName: 'CombatTarget',
                        panelVersion: 1,
                      },
                    ]
                  },
                ]
              }
            }
          ]
        })
      }

      return clone;
    }

    case 'SET_LOGIN_STATUS': {
      const clone = Object.assign({}, state);
      console.log("SET_LOGIN_STATUS");
      
      clone.loginStatus = true;
      clone.loginTimestamp = Date.now();

      return clone;
    }

    case 'SET_LOGOUT_STATUS': {
      const clone = Object.assign({}, state);
      console.log("SET_LOGOUT_STATUS");
      
      clone.loginStatus = false;
      clone.loginTimestamp = 0;

      return clone;
    }

    case 'UPDATE_SOCKET_STATUS': {
      const clone = Object.assign({}, state);
      console.log("UPDATE_SOCKET_STATUS");

      let socketStatus = action.data;
      console.log("socketStatus");
      console.log(socketStatus);
      
      clone.socketStatus = socketStatus;

      return clone;
    }







    case 'UPDATE_KEYSTATE_V2':
    {
      const clone = Object.assign({}, state);
      console.log("UPDATE_KEYSTATE_V2");
      //
      ///////////////////////////////////////////////////////
      // Get current user profile
      ///////////////////////////////////////////////////////
      let loggedInUserId = '';
      try {
        loggedInUserId = clone.user.uid;
      } catch (e) {
        loggedInUserId = '';
      }
      if (loggedInUserId === undefined) {
        loggedInUserId = '';
      }
      console.log("loggedInUserId");
      console.log(loggedInUserId);

      ///////////////////////////////////////////////////////
      // Received Data
      ///////////////////////////////////////////////////////

      // RECEIVE CO-PILOT DATA
      let coPilotAction = '';
      try {
        coPilotAction = action.data.coPilotAction;
      } catch (e) {
        coPilotAction = '';
      }
      if (coPilotAction === undefined) {
        coPilotAction = '';
      }
      let coPilotId = '';
      try {
        coPilotId = action.data.coPilotId;
      } catch (e) {
        coPilotId = '';
      }
      if (coPilotId === undefined) {
        coPilotId = '';
      }

      let keyCommand = action.data.keyCommand;
      let keyCommandT = action.data.keyCommandT;
      let userId = '';
      try {
        userId = action.data.userId;
      } catch (e) {
        userId = '';
      }
      if (userId === undefined) {
        userId = '';
      }
      
      if (userId === '') {
        userId = loggedInUserId;
      }


      console.log(`keyCommand: ${JSON.stringify(keyCommand)}`);
      console.log(`keyCommandT: ${JSON.stringify(keyCommandT)}`);
      console.log(`userId: ${JSON.stringify(userId)}`);
      console.log(`coPilotAction: ${JSON.stringify(coPilotAction)}`);
      console.log(`coPilotId: ${JSON.stringify(coPilotId)}`);

      /**************************************************************
       * REQUIRED to update all state values of the key press.
       * If multiple commands use the same key, they should all transition state.
       * e.g. button to switch power on, should also toggle another control if its watching the button press.
       * --> For this reason, we update button command rather than control name.
       **************************************************************/
      //let indexOfUserData = null;
      //let keyMappingIndexesToUpdate = [];
      let keyCommandLowerCase = [];
      for (let x = 0; x < keyCommand.length; x++) {
        keyCommandLowerCase.push(keyCommand[x].toString().toLowerCase());
      }

      
      console.log(`keyCommandLowerCase: ${JSON.stringify(keyCommandLowerCase)}`);

      for (let x = 0; x < clone.userkeyStateMappings.length; x++) {
        if (coPilotAction === true) {
          console.log(`(coPilotId) coPilotAction === true`);
          /*************************************************
          * IF CO-PILOT COMMAND
          *************************************************/

          if (clone.userkeyStateMappings[x].uid === coPilotId) {
            //indexOfUserData = x;
            for (let y = 0; y < clone.userkeyStateMappings[x].keyStates.length; y++) {
              if (JSON.stringify(clone.userkeyStateMappings[x].keyStates[y].defaultGameKey) === JSON.stringify(keyCommandLowerCase)) {
                //keyMappingIndexesToUpdate.push(y);
                ///////////////// UPDATE THIS FIELD (START) ////////////////////////

                let selectionState = false;

                console.log(`(coPilotId) keyCommandT: ${keyCommandT}`);
                if (keyCommandT === "00") {
                  //This is an unknown state
                  //Get current selectionState
                  try {
                    selectionState = clone.userkeyStateMappings[x].keyStates[y].selectionState;
                  } catch (e) {
                    console.log(e);
                    console.log("(coPilotId) had error getting selectionState");
                    selectionState = false;
                  }
                  if (selectionState === undefined) {
                    selectionState = false;
                  }
                  //Toggle selectionState
                  if (selectionState === false) {
                    selectionState = true;
                  } else {
                    selectionState = false;
                  }
                } else if (keyCommandT === "01") {
                  console.log(`(coPilotId) Transitioning state to true (from false)`);
                  selectionState = true;
                } else if (keyCommandT === "10") {
                  console.log(`(coPilotId) Transitioning state to false (from true)`);
                  selectionState = false;
                }

                //console.log(`${JSON.stringify(keyCommand)} === ${JSON.stringify(["f5"])} OR ${JSON.stringify(keyCommand)} === ${JSON.stringify(["f6"])} OR ${JSON.stringify(keyCommand)} === ${JSON.stringify(["f7"])}`);
                if (JSON.stringify(keyCommand) === JSON.stringify(["f5"]) || 
                    JSON.stringify(keyCommand) === JSON.stringify(["f6"]) || 
                    JSON.stringify(keyCommand) === JSON.stringify(["f7"]) ||
                    JSON.stringify(keyCommand) === JSON.stringify(["f8"]) ||
                    JSON.stringify(keyCommand) === JSON.stringify(["F5"]) || 
                    JSON.stringify(keyCommand) === JSON.stringify(["F6"]) || 
                    JSON.stringify(keyCommand) === JSON.stringify(["F7"]) || 
                    JSON.stringify(keyCommand) === JSON.stringify(["F8"]) ) {
                  //console.log('found matching key for keyQueues (f key pressed');
                  //Add to keyQueue for processing
                  let foundItem = false;
                  for (let x = 0; x < clone.keyQueues.length; x++) {
                    if (clone.keyQueues[x].queueName === 'powerTriangle') {
                      foundItem = true;
                      let currentQueue = clone.keyQueues[x].queue;
                      currentQueue.push(keyCommand);
                      clone.keyQueues[x].queue = currentQueue;
                    }
                  }
                  if (foundItem === false) {
                    clone.keyQueues = [
                      {
                        queueName: 'powerTriangle',
                        keys: ['f5', 'f6', 'f7', 'f8'],
                        queue: [keyCommand],
                      }
                    ];
                  }
                }

                clone.userkeyStateMappings[x].keyStates[y].selectionState = selectionState;
          


                ///////////////// UPDATE THIS FIELD (END) ////////////////////////
              }
            }
          }
        } else {
          /*************************************************
          * IF LOCAL USER COMMAND
          *************************************************/

          if (clone.userkeyStateMappings[x].uid === userId) {
            //indexOfUserData = x;
            for (let y = 0; y < clone.userkeyStateMappings[x].keyStates.length; y++) {
              if (JSON.stringify(clone.userkeyStateMappings[x].keyStates[y].defaultGameKey) === JSON.stringify(keyCommandLowerCase)) {
                //keyMappingIndexesToUpdate.push(y);
                ///////////////// UPDATE THIS FIELD (START) ////////////////////////

                let selectionState = false;

                console.log(`keyCommandT: ${keyCommandT}`);
                if (keyCommandT === "00") {
                  //This is an unknown state
                  //Get current selectionState
                  try {
                    selectionState = clone.userkeyStateMappings[x].keyStates[y].selectionState;
                  } catch (e) {
                    console.log(e);
                    console.log("had error getting selectionState");
                    selectionState = false;
                  }
                  if (selectionState === undefined) {
                    selectionState = false;
                  }
                  //Toggle selectionState
                  if (selectionState === false) {
                    selectionState = true;
                  } else {
                    selectionState = false;
                  }
                } else if (keyCommandT === "01") {
                  console.log(`Transitioning state to true (from false)`);
                  selectionState = true;
                } else if (keyCommandT === "10") {
                  console.log(`Transitioning state to false (from true)`);
                  selectionState = false;
                }

                //console.log(`${JSON.stringify(keyCommand)} === ${JSON.stringify(["f5"])} OR ${JSON.stringify(keyCommand)} === ${JSON.stringify(["f6"])} OR ${JSON.stringify(keyCommand)} === ${JSON.stringify(["f7"])}`);
                if (JSON.stringify(keyCommand) === JSON.stringify(["f5"]) || 
                    JSON.stringify(keyCommand) === JSON.stringify(["f6"]) || 
                    JSON.stringify(keyCommand) === JSON.stringify(["f7"]) ||
                    JSON.stringify(keyCommand) === JSON.stringify(["f8"]) ||
                    JSON.stringify(keyCommand) === JSON.stringify(["F5"]) || 
                    JSON.stringify(keyCommand) === JSON.stringify(["F6"]) || 
                    JSON.stringify(keyCommand) === JSON.stringify(["F7"]) || 
                    JSON.stringify(keyCommand) === JSON.stringify(["F8"]) ) {
                  //console.log('found matching key for keyQueues (f key pressed');
                  //Add to keyQueue for processing
                  let foundItem = false;
                  for (let x = 0; x < clone.keyQueues.length; x++) {
                    if (clone.keyQueues[x].queueName === 'powerTriangle') {
                      foundItem = true;
                      let currentQueue = clone.keyQueues[x].queue;
                      currentQueue.push(keyCommand);
                      clone.keyQueues[x].queue = currentQueue;
                    }
                  }
                  if (foundItem === false) {
                    clone.keyQueues = [
                      {
                        queueName: 'powerTriangle',
                        keys: ['f5', 'f6', 'f7', 'f8'],
                        queue: [keyCommand],
                      }
                    ];
                  }
                }

                clone.userkeyStateMappings[x].keyStates[y].selectionState = selectionState;



                ///////////////// UPDATE THIS FIELD (END) ////////////////////////
              }
            }
          }
        } //end if copilot command
      }

 
      return clone;
    }





    

    case 'UPDATE_KEYSTATE':
    {
      const clone = Object.assign({}, state);
      console.log("UPDATE_KEYSTATE");

      ///////////////////////////////////////////////////////
      // Setup state if not initialised
      ///////////////////////////////////////////////////////
      if (clone.keyStateMappings.length === 0) {
        //load inital state
        clone.keyStateMappings = KeyMappings;
      }
      ///////////////////////////////////////////////////////
      // Update state
      ///////////////////////////////////////////////////////
      console.log(`EXAMPLE RECORD | ${clone.keyStateMappings[0].controlName} | ${clone.keyStateMappings[0].buttonName}`);

      let keyCommand = action.data.keyCommand;
      console.log(`keyCommand: ${JSON.stringify(keyCommand)}`);
      let keyCommandT = action.data.keyCommandT;
      console.log(`keyCommandT: ${JSON.stringify(keyCommandT)}`);


      ///////////////////////////////////////////////////////
      // Convert Key Command
      ///////////////////////////////////////////////////////
      //keyCommand = ["Left Control","Z"] >>> ["left","control","z"];
      let translatedKeyCommand = [];
      for (let x = 0; x < keyCommand.length; x++) {
        if (keyCommand[x] === "Left Control") {
          translatedKeyCommand.push("left");
          translatedKeyCommand.push("control");
        } else if (keyCommand[x] === "Right Control") {
          translatedKeyCommand.push("right");
          translatedKeyCommand.push("control");
        } else if (keyCommand[x] === "Left Shift") {
          translatedKeyCommand.push("left");
          translatedKeyCommand.push("shift");
        } else if (keyCommand[x] === "Right Shift") {
          translatedKeyCommand.push("right");
          translatedKeyCommand.push("shift");
        } else if (keyCommand[x] === "Left Meta") {
          translatedKeyCommand.push("left");
          translatedKeyCommand.push("meta");
        } else if (keyCommand[x] === "Right Meta") {
          translatedKeyCommand.push("right");
          translatedKeyCommand.push("meta");
        } else if (keyCommand[x] === "Left Alt") {
          translatedKeyCommand.push("left");
          translatedKeyCommand.push("alt");
        } else if (keyCommand[x] === "Right Alt") {
          translatedKeyCommand.push("right");
          translatedKeyCommand.push("alt");
        } else {
          translatedKeyCommand.push(keyCommand[x].toLowerCase());
        }
      }
      console.log(`translatedKeyCommand: ${JSON.stringify(translatedKeyCommand)}`);
      
      /**************************************************************
       * REQUIRED to update all state values of the key press.
       * If multiple commands use the same key, they should all transition state.
       * e.g. button to switch power on, should also toggle another control if its watching the button press.
       * --> For this reason, we update button command rather than control name.
       **************************************************************/
       let keyMappingIndexesToUpdate = [];
       for (let x = 0; x < clone.keyStateMappings.length; x++) {
         if (JSON.stringify(clone.keyStateMappings[x].defaultGameKey) === JSON.stringify(translatedKeyCommand)) {
           keyMappingIndexesToUpdate.push(x);
         }
       }
       console.log(`Number of Indexes to update: ${keyMappingIndexesToUpdate.length}`);

       //// FIND THE RELEVANT KEY, CHANGE THE STATE
      //const indexOfKeyCommand = clone.keyStateMappings.findIndex(keys => JSON.stringify(keys.defaultGameKey) === JSON.stringify(keyCommand));   //array === array
      //console.log(`Index of Key: ${indexOfKeyCommand}`);

      for (let x = 0; x < keyMappingIndexesToUpdate.length; x++) {

        let selectionState = false;

        console.log(`keyCommandT: ${keyCommandT}`);
        if (keyCommandT === "00") {
          //This is an unknown state
          //Get current selectionState
          console.log(`Attempting to get keyStateMapping for index: ${keyMappingIndexesToUpdate[x]}`);
          try {
            selectionState = clone.keyStateMappings[keyMappingIndexesToUpdate[x]].selectionState;
          } catch (e) {
            console.log(e);
            console.log("had error getting selectionState");
            selectionState = false;
          }
          if (selectionState === undefined) {
            selectionState = false;
          }
          //Toggle selectionState
          if (selectionState === false) {
            selectionState = true;
          } else {
            selectionState = false;
          }
        } else if (keyCommandT === "01") {
          console.log(`Transitioning state to true (from false)`);
          selectionState = true;
        } else if (keyCommandT === "10") {
          console.log(`Transitioning state to false (from true)`);
          selectionState = false;
        }

        //TESTING KEY QUEUE FOR COMPONENT VISUAL AND FUNCTIONAL CHANGES


        //keyQueues

   
        //console.log(`${JSON.stringify(keyCommand)} === ${JSON.stringify(["f5"])} OR ${JSON.stringify(keyCommand)} === ${JSON.stringify(["f6"])} OR ${JSON.stringify(keyCommand)} === ${JSON.stringify(["f7"])}`);
        if (JSON.stringify(keyCommand) === JSON.stringify(["f5"]) || 
            JSON.stringify(keyCommand) === JSON.stringify(["f6"]) || 
            JSON.stringify(keyCommand) === JSON.stringify(["f7"]) ||
            JSON.stringify(keyCommand) === JSON.stringify(["f8"]) ||
            JSON.stringify(keyCommand) === JSON.stringify(["F5"]) || 
            JSON.stringify(keyCommand) === JSON.stringify(["F6"]) || 
            JSON.stringify(keyCommand) === JSON.stringify(["F7"]) || 
            JSON.stringify(keyCommand) === JSON.stringify(["F8"]) ) {
          //console.log('found matching key for keyQueues (f key pressed');
          //Add to keyQueue for processing
          let foundItem = false;
          for (let x = 0; x < clone.keyQueues.length; x++) {
            if (clone.keyQueues[x].queueName === 'powerTriangle') {
              foundItem = true;
              let currentQueue = clone.keyQueues[x].queue;
              currentQueue.push(keyCommand);
              clone.keyQueues[x].queue = currentQueue;
            }
          }
          if (foundItem === false) {
            clone.keyQueues = [
              {
                queueName: 'powerTriangle',
                keys: ['f5', 'f6', 'f7', 'f8'],
                queue: [keyCommand],
              }
            ];
          }
        }






        let keyInputQueue = [];
        try {
          keyInputQueue = clone.keyStateMappings[keyMappingIndexesToUpdate[x]].keyInputQueue;
        } catch (e) {
          keyInputQueue = [];
        }
        if (keyInputQueue === undefined) {
          keyInputQueue = [];
        }

        keyInputQueue.push(keyCommand);




        
        console.log(`Updating state for index: ${keyMappingIndexesToUpdate[x]}`);
        console.log('adding: keyInputQueue');
        //Store selectionState
        clone.keyStateMappings[keyMappingIndexesToUpdate[x]].selectionState = selectionState;
        clone.keyStateMappings[keyMappingIndexesToUpdate[x]].keyInputQueue = keyInputQueue;
  
          
  

      } //end for loop




















      /* ORIGINAL. 
      //// FIND THE RELEVANT KEY, CHANGE THE STATE
      const indexOfKeyCommand = clone.keyStateMappings.findIndex(keys => JSON.stringify(keys.defaultGameKey) === JSON.stringify(translatedKeyCommand));   //array === array
      console.log(`Index of Key: ${indexOfKeyCommand}`);

      if (indexOfKeyCommand !== -1) {
        //Get current selectionState
        let selectionState = false;
        try {
          selectionState = clone.keyStateMappings[indexOfKeyCommand].selectionState;
        } catch (e) {
          selectionState = false;
        }
        if (selectionState === undefined) {
          selectionState = false;
        }
        //Toggle selectionState
        if (selectionState === false) {
          selectionState = true;
        } else {
          selectionState = false;
        }

        if (keyCommandT === '01') {
          clone.keyStateMappings[indexOfKeyCommand].selectionState = true;
        } else if (keyCommandT === '10') {
          clone.keyStateMappings[indexOfKeyCommand].selectionState = false;
        } else {
          //Store selectionState
          clone.keyStateMappings[indexOfKeyCommand].selectionState = selectionState;
        }


        

      } else {
        console.log('unable to find record - likely an issue setting the state store.');
      }
      */




      return clone;
    }












    case 'UPDATE_LOCAL_KEYSTATE_V2':
    {
      const clone = Object.assign({}, state);
      console.log("UPDATE_LOCAL_KEYSTATE_V2");

      ///////////////////////////////////////////////////////
      // Updating local state (will be provided the userId to update)
      ///////////////////////////////////////////////////////


      console.log(`action: ${JSON.stringify(action)}`);
      console.log(`action.data: ${JSON.stringify(action.data)}`);



      ///////////////////////////////////////////////////////
      // Received Data
      ///////////////////////////////////////////////////////
      // RECEIVE CO-PILOT DATA
      let coPilotAction = '';
      try {
        coPilotAction = action.data.gameKeyStateUpdate.coPilotAction;
      } catch (e) {
        coPilotAction = '';
      }
      if (coPilotAction === undefined) {
        coPilotAction = '';
      }
      let coPilotId = '';
      try {
        coPilotId = action.data.gameKeyStateUpdate.coPilotId;
      } catch (e) {
        coPilotId = '';
      }
      if (coPilotId === undefined) {
        coPilotId = '';
      }
      // RECEIVE GENERAL KEY DATA
      let keyCommand = [];
      try {
        keyCommand = action.data.gameKeyStateUpdate.keyCommand;
      } catch (e) {
        keyCommand = [];
      }
      if (keyCommand === undefined) {
        keyCommand = [];
      }
      let keyCommandT = '';
      try {
        keyCommandT = action.data.gameKeyStateUpdate.keyCommandT;
      } catch (e) {
        keyCommandT = '';
      }
      if (keyCommandT === undefined) {
        keyCommandT = '';
      }
      let userId = '';
      try {
        userId = action.data.gameKeyStateUpdate.userId;
      } catch (e) {
        userId = '';
      }
      if (userId === undefined) {
        userId = '';
      }


      console.log(`keyCommand: ${JSON.stringify(keyCommand)}`);
      console.log(`keyCommandT: ${JSON.stringify(keyCommandT)}`);
      console.log(`userId: ${JSON.stringify(userId)}`);
      console.log(`coPilotAction: ${JSON.stringify(coPilotAction)}`);
      console.log(`coPilotId: ${JSON.stringify(coPilotId)}`);

      /**************************************************************
       * REQUIRED to update all state values of the key press.
       * If multiple commands use the same key, they should all transition state.
       * e.g. button to switch power on, should also toggle another control if its watching the button press.
       * --> For this reason, we update button command rather than control name.
       **************************************************************/
      //let indexOfUserData = null;
      //let keyMappingIndexesToUpdate = [];
      for (let x = 0; x < clone.userkeyStateMappings.length; x++) {
        
        if (coPilotAction === true) {
          //console.log(`(coPilotId) coPilotAction === true`);
          /*************************************************
          * IF CO-PILOT COMMAND
          *************************************************/

           //console.log(`(coPilotId) ${clone.userkeyStateMappings[x].uid} === ${coPilotId}`);
          if (clone.userkeyStateMappings[x].uid === coPilotId) {
            
            //console.log(`(coPilotId) clone.userkeyStateMappings[x].uid === coPilotId`);
            //indexOfUserData = x;
            for (let y = 0; y < clone.userkeyStateMappings[x].keyStates.length; y++) {
              
              //console.log(`(coPilotId) Go through all keystates`);
              if (JSON.stringify(clone.userkeyStateMappings[x].keyStates[y].defaultGameKey) === JSON.stringify(keyCommand)) {
                //keyMappingIndexesToUpdate.push(y);
                ///////////////// UPDATE THIS FIELD (START) ////////////////////////
  
                let selectionState = false;
  
                console.log(`(coPilotId) keyCommandT: ${keyCommandT}`);
                if (keyCommandT === "00") {
                  //This is an unknown state
                  //Get current selectionState
                  try {
                    selectionState = clone.userkeyStateMappings[x].keyStates[y].selectionState;
                  } catch (e) {
                    console.log(e);
                    console.log("(coPilotId) had error getting selectionState");
                    selectionState = false;
                  }
                  if (selectionState === undefined) {
                    selectionState = false;
                  }
                  //Toggle selectionState
                  if (selectionState === false) {
                    selectionState = true;
                  } else {
                    selectionState = false;
                  }
                } else if (keyCommandT === "01") {
                  console.log(`(coPilotId) Transitioning state to true (from false)`);
                  selectionState = true;
                } else if (keyCommandT === "10") {
                  console.log(`(coPilotId) Transitioning state to false (from true)`);
                  selectionState = false;
                }
  
                //console.log(`${JSON.stringify(keyCommand)} === ${JSON.stringify(["f5"])} OR ${JSON.stringify(keyCommand)} === ${JSON.stringify(["f6"])} OR ${JSON.stringify(keyCommand)} === ${JSON.stringify(["f7"])}`);
                if (JSON.stringify(keyCommand) === JSON.stringify(["f5"]) || 
                    JSON.stringify(keyCommand) === JSON.stringify(["f6"]) || 
                    JSON.stringify(keyCommand) === JSON.stringify(["f7"]) ||
                    JSON.stringify(keyCommand) === JSON.stringify(["f8"]) ||
                    JSON.stringify(keyCommand) === JSON.stringify(["F5"]) || 
                    JSON.stringify(keyCommand) === JSON.stringify(["F6"]) || 
                    JSON.stringify(keyCommand) === JSON.stringify(["F7"]) || 
                    JSON.stringify(keyCommand) === JSON.stringify(["F8"]) ) {
                  //console.log('found matching key for keyQueues (f key pressed');
                  //Add to keyQueue for processing
                  let foundItem = false;
                  for (let x = 0; x < clone.keyQueues.length; x++) {
                    if (clone.keyQueues[x].queueName === 'powerTriangle') {
                      foundItem = true;
                      let currentQueue = clone.keyQueues[x].queue;
                      currentQueue.push(keyCommand);
                      clone.keyQueues[x].queue = currentQueue;
                    }
                  }
                  if (foundItem === false) {
                    clone.keyQueues = [
                      {
                        queueName: 'powerTriangle',
                        keys: ['f5', 'f6', 'f7', 'f8'],
                        queue: [keyCommand],
                      }
                    ];
                  }
                }
  
                clone.userkeyStateMappings[x].keyStates[y].selectionState = selectionState;
          
                ///////////////// UPDATE THIS FIELD (END) ////////////////////////
              }
            }
          }


        } else {
          /*************************************************
          * IF LOCAL USER COMMAND
          *************************************************/

           if (clone.userkeyStateMappings[x].uid === userId) {
            //indexOfUserData = x;
            for (let y = 0; y < clone.userkeyStateMappings[x].keyStates.length; y++) {
              if (JSON.stringify(clone.userkeyStateMappings[x].keyStates[y].defaultGameKey) === JSON.stringify(keyCommand)) {
                //keyMappingIndexesToUpdate.push(y);
                ///////////////// UPDATE THIS FIELD (START) ////////////////////////
  
                let selectionState = false;
  
                console.log(`keyCommandT: ${keyCommandT}`);
                if (keyCommandT === "00") {
                  //This is an unknown state
                  //Get current selectionState
                  try {
                    selectionState = clone.userkeyStateMappings[x].keyStates[y].selectionState;
                  } catch (e) {
                    console.log(e);
                    console.log("had error getting selectionState");
                    selectionState = false;
                  }
                  if (selectionState === undefined) {
                    selectionState = false;
                  }
                  //Toggle selectionState
                  if (selectionState === false) {
                    selectionState = true;
                  } else {
                    selectionState = false;
                  }
                } else if (keyCommandT === "01") {
                  console.log(`Transitioning state to true (from false)`);
                  selectionState = true;
                } else if (keyCommandT === "10") {
                  console.log(`Transitioning state to false (from true)`);
                  selectionState = false;
                }
  
                //console.log(`${JSON.stringify(keyCommand)} === ${JSON.stringify(["f5"])} OR ${JSON.stringify(keyCommand)} === ${JSON.stringify(["f6"])} OR ${JSON.stringify(keyCommand)} === ${JSON.stringify(["f7"])}`);
                if (JSON.stringify(keyCommand) === JSON.stringify(["f5"]) || 
                    JSON.stringify(keyCommand) === JSON.stringify(["f6"]) || 
                    JSON.stringify(keyCommand) === JSON.stringify(["f7"]) ||
                    JSON.stringify(keyCommand) === JSON.stringify(["f8"]) ||
                    JSON.stringify(keyCommand) === JSON.stringify(["F5"]) || 
                    JSON.stringify(keyCommand) === JSON.stringify(["F6"]) || 
                    JSON.stringify(keyCommand) === JSON.stringify(["F7"]) || 
                    JSON.stringify(keyCommand) === JSON.stringify(["F8"]) ) {
                  //console.log('found matching key for keyQueues (f key pressed');
                  //Add to keyQueue for processing
                  let foundItem = false;
                  for (let x = 0; x < clone.keyQueues.length; x++) {
                    if (clone.keyQueues[x].queueName === 'powerTriangle') {
                      foundItem = true;
                      let currentQueue = clone.keyQueues[x].queue;
                      currentQueue.push(keyCommand);
                      clone.keyQueues[x].queue = currentQueue;
                    }
                  }
                  if (foundItem === false) {
                    clone.keyQueues = [
                      {
                        queueName: 'powerTriangle',
                        keys: ['f5', 'f6', 'f7', 'f8'],
                        queue: [keyCommand],
                      }
                    ];
                  }
                }
  
  
  
  
  
                /*
                let keyInputQueue = [];
                try {
                  keyInputQueue = clone.keyStateMappings[keyMappingIndexesToUpdate[x]].keyInputQueue;
                } catch (e) {
                  keyInputQueue = [];
                }
                if (keyInputQueue === undefined) {
                  keyInputQueue = [];
                }
  
                keyInputQueue.push(keyCommand);
                */
  
  
  
  
                
                //console.log(`Updating state for index: ${keyMappingIndexesToUpdate[x]}`);
                //console.log('adding: keyInputQueue');
  
  
                //Store selectionState
                //clone.keyStateMappings[keyMappingIndexesToUpdate[x]].selectionState = selectionState;
                //clone.keyStateMappings[keyMappingIndexesToUpdate[x]].keyInputQueue = keyInputQueue;
  
  
                clone.userkeyStateMappings[x].keyStates[y].selectionState = selectionState;
          
  
  
                ///////////////// UPDATE THIS FIELD (END) ////////////////////////
              }
            }
          }


        }

      } //end for loop

      return clone;
    }































    


    case 'UPDATE_LOCAL_KEYSTATE':
    {
      const clone = Object.assign({}, state);
      console.log("UPDATE_LOCAL_KEYSTATE");

      ///////////////////////////////////////////////////////
      // Setup state if not initialised
      ///////////////////////////////////////////////////////
      if (clone.keyStateMappings.length === 0) {
        //load inital state
        clone.keyStateMappings = KeyMappings;
      }
      ///////////////////////////////////////////////////////
      // Update state
      ///////////////////////////////////////////////////////
      console.log(`EXAMPLE RECORD | ${clone.keyStateMappings[0].controlName} | ${clone.keyStateMappings[0].buttonName}`);

      let gameKeyStateUpdate = action.data.gameKeyStateUpdate;
      /*
      let gameKeyStateUpdate = {
        keyCommand: keyCommand,   //Command key to lookup and update
        keyCommandT: keyCommandT,
        updatePattern: 'toggle',  //toggle, setTo, 
        setToValue: '',
      }
      */

      let keyCommand = gameKeyStateUpdate.keyCommand;
      console.log(`keyCommand: ${JSON.stringify(keyCommand)}`);
      let keyCommandT = gameKeyStateUpdate.keyCommandT;
      console.log(`keyCommandT: ${JSON.stringify(keyCommandT)}`);
      let updatePattern = gameKeyStateUpdate.updatePattern;
      let setToValue = gameKeyStateUpdate.setToValue;
      console.log(`updatePattern: ${JSON.stringify(updatePattern)}`);
      console.log(`setToValue: ${JSON.stringify(setToValue)}`);

      /**************************************************************
       * REQUIRED to update all state values of the key press.
       * If multiple commands use the same key, they should all transition state.
       * e.g. button to switch power on, should also toggle another control if its watching the button press.
       * --> For this reason, we update button command rather than control name.
       **************************************************************/
      let keyMappingIndexesToUpdate = [];
      for (let x = 0; x < clone.keyStateMappings.length; x++) {
        if (JSON.stringify(clone.keyStateMappings[x].defaultGameKey) === JSON.stringify(keyCommand)) {
          keyMappingIndexesToUpdate.push(x);
        }
      }
      console.log(`Number of Indexes to update: ${keyMappingIndexesToUpdate.length}`);

      //// FIND THE RELEVANT KEY, CHANGE THE STATE
      //const indexOfKeyCommand = clone.keyStateMappings.findIndex(keys => JSON.stringify(keys.defaultGameKey) === JSON.stringify(keyCommand));   //array === array
      //console.log(`Index of Key: ${indexOfKeyCommand}`);

      for (let x = 0; x < keyMappingIndexesToUpdate.length; x++) {

        if (updatePattern === 'toggle') {

          let selectionState = false;
  
          console.log(`keyCommandT: ${keyCommandT}`);
          if (keyCommandT === "00") {
            //This is an unknown state
            //Get current selectionState
            console.log(`Attempting to get keyStateMapping for index: ${keyMappingIndexesToUpdate[x]}`);
            try {
              selectionState = clone.keyStateMappings[keyMappingIndexesToUpdate[x]].selectionState;
            } catch (e) {
              console.log(e);
              console.log("had error getting selectionState");
              selectionState = false;
            }
            if (selectionState === undefined) {
              selectionState = false;
            }
            //Toggle selectionState
            if (selectionState === false) {
              selectionState = true;
            } else {
              selectionState = false;
            }
          } else if (keyCommandT === "01") {
            console.log(`Transitioning state to true (from false)`);
            selectionState = true;
          } else if (keyCommandT === "10") {
            console.log(`Transitioning state to false (from true)`);
            selectionState = false;
          }

          
          console.log(`Updating state for index: ${keyMappingIndexesToUpdate[x]}`);
          //Store selectionState
          clone.keyStateMappings[keyMappingIndexesToUpdate[x]].selectionState = selectionState;
    
          
  
        }

      } //end for loop



      

      




      return clone;
    }













    case 'RESET_KEYSTATE':
    {
      const clone = Object.assign({}, state);
      ///////////////////////////////////////////////////////
      // Reset the key state
      ///////////////////////////////////////////////////////






      return clone;
    }

    


    default:
      return state;
  }
};

export default StarGlassReducer;
