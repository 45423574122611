/** AUTH ACTIONS ** */
import store from 'Store/ConfigureStore';
import ApiMonitoring from './ApiMonitoring';
import ApiUI from './UI';

///// HASHING 
import sha256 from 'crypto-js/sha256';
import sha512 from 'crypto-js/sha512';
import hmacSHA512 from 'crypto-js/hmac-sha512';
import Base64 from 'crypto-js/enc-base64';


import { v1 as uuidv1 } from 'uuid';


import { JSEncrypt } from "jsencrypt";


import GH_authApi from './api/GH_authApi';



import { updateVersion } from 'Reducers/appSlice';
import { setPortalLoginStatusR, setFireUserDataR, setLocalAccessTokenR, setLocalRefreshTokenR, setLocalDataFromAccessTokenR, logoutUserR } from 'Reducers/Auth';
import { setApiActivityR, updateApiActivityR, removeApiActivityR, } from 'Reducers/ApiUI';

import { setUserDataR, } from 'Reducers/Game';

/*
try {
  const { updateVersion } = appSlice.actions;
} catch (e) {
  console.log(e);
}
*/



/********************************************************************
 * OVERVIEW
 * - initialSignupRequest - After entering username/password and creating account in firebase. Request the inital signup process.
 * --- this is the verify activity. Send a verification email to veriify its a real email address.
 * - userIdentitiesVerificationUnauthenticated - On clicking the verification of the email, send a request to 'signup' the user.
********************************************************************/









/********************************************************************
* RE-AUTH ACTIVITY
********************************************************************/
export function reAuth(body = {}) {
  return (dispatch, getState) => {
    //const { fireAccessToken, fireUser } = getState().Auth;
    return new Promise(((resolve, reject) => {

      let { subscriptionId = '',  rs = '', ts = '' } = body;
      

      console.log("reAuth");

      let bodyData = JSON.stringify({
        subscriptionId, 
        rs, 
        ts,
      });

      GH_authApi.reAuthRequest(bodyData)
      .then((apiResponseData) => {
        console.warn("API | RESPONSE | reAuthRequest");
        console.log(apiResponseData);

        if (apiResponseData.status === true) {
          if (apiResponseData.data.appStatus === true) {
            
            let loginSuccessful = false;
            try {
              loginSuccessful = apiResponseData.data.loginSuccessful;
            } catch (e) {
              loginSuccessful = false;
            }
            if (loginSuccessful === undefined) {
              loginSuccessful = false;
            }
            let accessToken = '';
            try {
              accessToken = apiResponseData.data.accessToken;
            } catch (e) {
              accessToken = '';
            }
            if (accessToken === undefined) {
              accessToken = '';
            }
            let refreshToken = '';
            try {
              refreshToken = apiResponseData.data.refreshToken;
            } catch (e) {
              refreshToken = '';
            }
            if (refreshToken === undefined) {
              refreshToken = '';
            }
            let uid = '';
            try {
              uid = apiResponseData.data.uid;
            } catch (e) {
              uid = '';
            }
            if (uid === undefined) {
              uid = '';
            }
            let email = '';
            try {
              email = apiResponseData.data.email;
            } catch (e) {
              email = '';
            }
            if (email === undefined) {
              email = '';
            }
            let subscriptionData = {};
            try {
              subscriptionData = apiResponseData.data.subscriptionData;
            } catch (e) {
              subscriptionData = {};
            }
            if (subscriptionData === undefined) {
              subscriptionData = {};
            }

            

            //Local App - Set Access/Refresh Token
            dispatch(setLocalAccessTokenR({accessToken}));
            dispatch(setLocalRefreshTokenR({refreshToken}));
            dispatch(setLocalDataFromAccessTokenR({uid, email, subscriptionData}));
            //Local App - Set Access/Refresh Token
            //dispatch(setFireUserDataR(user));
    
            //Local App - Set Login Successful
            dispatch(setPortalLoginStatusR(loginSuccessful));

            //UPDATE API ACTIVITY
            dispatch(updateApiActivityR({btnData, status: true}));
            const myTimeoutEndOfFunction = setTimeout(function() {
              dispatch(removeApiActivityR({btnData}));
            }.bind(this, dispatch), 5000);
          }

        } else {
          //UPDATE API ACTIVITY
          dispatch(updateApiActivityR({btnData, status: false}));
          const myTimeoutEndOfFunction = setTimeout(function() {
            dispatch(removeApiActivityR({btnData}));
          }.bind(this, dispatch), 5000);

        }
        
        resolve(apiResponseData);
      })
      .catch((error) => {
       
        resolve(error);
      });
      
    }));
  };
}






/********************************************************************
* REQUEST PUBLIC ENCRYPTION KEY
********************************************************************/
export function requestPublicKey(body = {}) {
  return (dispatch, getState) => {
    const { portalLoginStatus, hudAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {

      let {  } = body;

      console.log("requestPublicKey");

      GH_authApi.requestPublicKey()
      .then((apiResponseData) => {
        console.warn("API | RESPONSE | requestPublicKey");
        //console.log(apiResponseData);

        if (apiResponseData.status === true) {
          if (apiResponseData.data.appStatus === true) {
            
          }

        } else {
         

        }
        
        resolve(apiResponseData);
      })
      .catch((error) => {
       
        resolve(error);
      });
    }));
  };
}

//https://medium.com/@vinaymahamuni/rsa-encryption-in-react-and-decryption-in-node-js-4e48dae2f072
//https://bartlomiejmika.com/post/2022/how-to-perform-rsa-encryption-in-javascript-and-golang/
function encryptMessage(message, publicKey) {
  const jsEncrypt = new JSEncrypt();
  jsEncrypt.setPublicKey(publicKey);
 
  return jsEncrypt.encrypt(message);
}


/* NODE DECRYPTION
function decryptMessage(encryptedMessage, privateKey) {
  const rsaPrivateKey = {
    key: privateKey,
    passphrase: '',
    padding: crypto.constants.RSA_PKCS1_PADDING,
  };

  const decryptedMessage = crypto.privateDecrypt(
    rsaPrivateKey,
    Buffer.from(encryptedMessage, 'base64'),
  );

  return decryptedMessage.toString('utf8');
}
*/



/********************************************************************
* REQUEST PASSWORD RESET (PHASE 1)
********************************************************************/
export function requestPasswordReset(body = {}) {
  return (dispatch, getState) => {
    const { portalLoginStatus, hudAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {

      let { loginEmail, } = body;

      console.log("requestPasswordReset");

      let bodyData = JSON.stringify({
        email: loginEmail,
      });

      GH_authApi.requestPasswordReset(bodyData, hudAccessToken)
      .then((apiResponseData) => {
        console.warn("API | RESPONSE | requestPasswordReset");
        //console.log(apiResponseData);

        if (apiResponseData.status === true) {
          if (apiResponseData.data.appStatus === true) {
            
          }

        } else {
         

        }
        
        resolve(apiResponseData);
      })
      .catch((error) => {
       
        resolve(error);
      });
    }));
  };
}

/********************************************************************
* CONFIRM PASSWORD RESET (PHASE 2)
********************************************************************/
export function confirmPasswordReset(body = {}) {
  return (dispatch, getState) => {
    const { portalLoginStatus, hudAccessToken } = getState().Auth;
    return new Promise((async (resolve, reject) => {

      let { passwordRequestId = '', verificationCode = '', password = '' } = body;


      let data = {
        passwordRequestId, 
        verificationCode, 
        password
      }


      console.log("confirmPasswordReset | Get Public Key");

      let pubKeyJSON = '';

      await GH_authApi.requestPublicKey(hudAccessToken)
      .then((apiResponseData) => {
        console.warn("API | RESPONSE | requestPublicKey");
        //console.log(apiResponseData);

        if (apiResponseData.status === true) {
          if (apiResponseData.data.appStatus === true) {
            console.log("apiResponseData.data.pubKey");
            console.log(apiResponseData.data.pubKey);
            pubKeyJSON = apiResponseData.data.pubKey;
          }

        } else {
         

        }
        
        //{"status":true,"data":{"appStatus":true,"appMessageSuccess":"","appMessageFailure":"","pubKey":"{\"publicKeyPem\":\"-----BEGIN PUBLIC KEY-----\\r\\nMIIBIjANBgkqhkiG9w0BAQEFAAOCAQ8AMIIBCgKCAQEAs+VAAtN196vs8m16PKhI\\r\\ne/vAfyDUDN6Hw26qehm/mBBCUp5ugdAA0kMvHaXiO7kRbl0pbquJYKT1z6VGF0j2\\r\\nWefXD6+lzsFbdLOmd3HnsAvR2OBagb6PeSpi8l/2x+vrynAJtvxzxuhxJr62Qghf\\r\\nK+nUzI/NZI9zU1CuB5xM0mqb6qMADQjpvNiU0YBB6NqkgxzNUJP1MH8jsSJyVS43\\r\\npWjFcR6YiNw6V2HoBayD3VsEOtnwDpvibI37QBGfeAc2G7HyXwc40al8CafwLcKF\\r\\nhy/0c7t62Sbyu7sSrqdPcNQoo3VSnk8HuNZ5inEfsfcXnOlrSEl+ECmbV0UaTK+n\\r\\nNQIDAQAB\\r\\n-----END PUBLIC KEY-----\\r\\n\"}"}}

      })
      .catch((error) => {
      
        resolve(error);
      });

      let pubKeyData = '';
      try {
        pubKeyData = JSON.parse(pubKeyJSON);
      } catch(e) {
        console.log(e);
      }

      let pubKey = pubKeyData.publicKeyPem;

      

      
      console.log("Public Key");
      console.log(pubKey);
      
      console.log("confirmPasswordReset | Encrypt Data");

      let encMessage = await encryptMessage(JSON.stringify(data), pubKey);

      console.log("encMessage");
      console.log(encMessage);


      let bodyData = JSON.stringify({
        message: encMessage,
      });

      console.log("confirmPasswordReset | Confirm password reset");

      GH_authApi.confirmPasswordReset(bodyData, hudAccessToken)
      .then((apiResponseData) => {
        console.warn("API | RESPONSE | confirmPasswordReset");
        //console.log(apiResponseData);

        if (apiResponseData.status === true) {
          if (apiResponseData.data.appStatus === true) {
            
          }

        } else {
         

        }
        
        resolve(apiResponseData);
      })
      .catch((error) => {
       
        resolve(error);
      });
    }));
  };
}




/********************************************************************
* LOGOUT USER
********************************************************************/
export function logoutUser(body = {}) {
  return (dispatch, getState) => {
    return new Promise(((resolve, reject) => {

      dispatch(logoutUserR());
      
    }));
  };
}

/********************************************************************
* REQUEST ACCOUNT DELETION
********************************************************************/
export function requestAccountDeletion(body = {}) {
  return (dispatch, getState) => {
    const { portalLoginStatus, hudAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {

      let {  } = body;

      console.log("requestAccountDeletion");

      GH_authApi.requestAccountDeletion(hudAccessToken)
      .then((apiResponseData) => {
        console.warn("API | RESPONSE | requestAccountDeletion");
        //console.log(apiResponseData);

        if (apiResponseData.status === true) {
          if (apiResponseData.data.appStatus === true) {
            
          }

        } else {
         

        }
        
        resolve(apiResponseData);
      })
      .catch((error) => {
       
        resolve(error);
      });
    }));
  };
}


/********************************************************************
* INTIAL LOGIN REQUEST
********************************************************************/
export function initialLoginRequest(body = {}) {
  return (dispatch, getState) => {
    //const { fireAccessToken, fireUser } = getState().Auth;
    return new Promise(((resolve, reject) => {

      let { btnData = false,  user = {} } = body;
      if (btnData !== false) {
        dispatch(setApiActivityR({btnData}));
      }


      console.log("initialLoginRequest");
      console.log("user");
      console.log(user);


      let fireAccessToken = '';
      try {
        fireAccessToken = user.accessToken;
      } catch (e) {
        fireAccessToken = '';
      }
      if (fireAccessToken === undefined) {
        fireAccessToken = '';
      }

      let fireUserId = '';
      try {
        fireUserId = user.uid;
      } catch (e) {
        fireUserId = '';
      }
      if (fireUserId === undefined) {
        fireUserId = '';
      }

      console.log(`Current: fireAccessToken is ${fireAccessToken}`);
      console.log(`Current: fireUserId is ${fireUserId}`);


      GH_authApi.initialLoginRequest(fireUserId, fireAccessToken)
      .then((apiResponseData) => {
        console.warn("API | RESPONSE | initialLoginRequest");
        //console.log(apiResponseData);

        if (apiResponseData.status === true) {
          if (apiResponseData.data.appStatus === true) {
            
            let loginSuccessful = false;
            try {
              loginSuccessful = apiResponseData.data.loginSuccessful;
            } catch (e) {
              loginSuccessful = false;
            }
            if (loginSuccessful === undefined) {
              loginSuccessful = false;
            }
            let accessToken = '';
            try {
              accessToken = apiResponseData.data.accessToken;
            } catch (e) {
              accessToken = '';
            }
            if (accessToken === undefined) {
              accessToken = '';
            }
            let refreshToken = '';
            try {
              refreshToken = apiResponseData.data.refreshToken;
            } catch (e) {
              refreshToken = '';
            }
            if (refreshToken === undefined) {
              refreshToken = '';
            }
            let uid = '';
            try {
              uid = apiResponseData.data.uid;
            } catch (e) {
              uid = '';
            }
            if (uid === undefined) {
              uid = '';
            }
            let email = '';
            try {
              email = apiResponseData.data.email;
            } catch (e) {
              email = '';
            }
            if (email === undefined) {
              email = '';
            }
            let subscriptionData = {};
            try {
              subscriptionData = apiResponseData.data.subscriptionData;
            } catch (e) {
              subscriptionData = {};
            }
            if (subscriptionData === undefined) {
              subscriptionData = {};
            }

            

            //Local App - Set Access/Refresh Token
            dispatch(setLocalAccessTokenR({accessToken}));
            dispatch(setLocalRefreshTokenR({refreshToken}));
            dispatch(setLocalDataFromAccessTokenR({uid, email, subscriptionData}));
            //Local App - Set Access/Refresh Token
            //dispatch(setFireUserDataR(user));
    
            //Local App - Set Login Successful
            dispatch(setPortalLoginStatusR(loginSuccessful));

            //UPDATE API ACTIVITY
            dispatch(updateApiActivityR({btnData, status: true}));

            const myTimeoutEndOfFunction = setTimeout(function() {
              dispatch(removeApiActivityR({btnData}));
            }.bind(this, dispatch), 5000);

          } else {    //appstatus === false
            //UPDATE API ACTIVITY
            dispatch(updateApiActivityR({btnData, status: false}));

          }

        } else {
          //UPDATE API ACTIVITY
          dispatch(updateApiActivityR({btnData, status: false}));
          
          const myTimeoutEndOfFunction = setTimeout(function() {
            dispatch(removeApiActivityR({btnData}));
          }.bind(this, dispatch), 5000);

        }
        
        resolve(apiResponseData);
      })
      .catch((error) => {

        //UPDATE API ACTIVITY
        dispatch(updateApiActivityR({btnData, status: false}));
        const myTimeoutEndOfFunction = setTimeout(function() {
          dispatch(removeApiActivityR({btnData}));
        }.bind(this, dispatch), 5000);

       
        resolve(error);
      });
      
    }));
  };
}


/********************************************************************
* INTIAL SIGNUP REQUEST
********************************************************************/


export function initialSignupRequest(body = {}) {
  return (dispatch, getState) => {
    //const { fireAccessToken, fireUser } = getState().Auth;
    return new Promise(((resolve, reject) => {

      let { btnData = false,  user = {} } = body;
      if (btnData !== false) {
        dispatch(setApiActivityR({btnData}));
      }


      console.log("initialSignupRequest");
      console.log("user");
      console.log(user);


      let fireAccessToken = '';
      try {
        fireAccessToken = user.accessToken;
      } catch (e) {
        fireAccessToken = '';
      }
      if (fireAccessToken === undefined) {
        fireAccessToken = '';
      }

      let fireUserId = '';
      try {
        fireUserId = user.uid;
      } catch (e) {
        fireUserId = '';
      }
      if (fireUserId === undefined) {
        fireUserId = '';
      }

      console.log(`Current: fireAccessToken is ${fireAccessToken}`);
      console.log(`Current: fireUserId is ${fireUserId}`);


      GH_authApi.initialSignupRequest(fireAccessToken)
      .then((apiResponseData) => {
        console.warn("API | RESPONSE | initialSignupRequest");
        //console.log(apiResponseData);

        if (apiResponseData.status === true) {

          
          //UPDATE API ACTIVITY
          dispatch(updateApiActivityR({btnData, status: true}));
          const myTimeoutEndOfFunction = setTimeout(function() {
            dispatch(removeApiActivityR({btnData}));
          }.bind(this, dispatch), 5000);

        } else {
         
          //UPDATE API ACTIVITY
          dispatch(updateApiActivityR({btnData, status: false}));
          const myTimeoutEndOfFunction = setTimeout(function() {
            dispatch(removeApiActivityR({btnData}));
          }.bind(this, dispatch), 5000);

        }
        
        resolve(apiResponseData);
      })
      .catch((error) => {

        //UPDATE API ACTIVITY
        dispatch(updateApiActivityR({btnData, status: false}));
        const myTimeoutEndOfFunction = setTimeout(function() {
          dispatch(removeApiActivityR({btnData}));
        }.bind(this, dispatch), 5000);

       
        resolve(error);
      });
    }));
  };
}




/********************************************************************
* VERIFY THE IDENTITY OF THE USER
********************************************************************/


////////////////////////////////////////////////////
// VERIFY AN EMAIL IDENTITY
// This is used on an email, calls our domain with the 
// verification code and the identity id. 
// We then call the api endpoint for verification.
// Allows the user to get a clean html response from the
// website.
////////////////////////////////////////////////////

export function userIdentityAuthVerification(body = {}) {
  return (dispatch, getState) => {
    //UNABLE TO SUPPORT REGION - WE DONT KNOW THE SOURCE   
    //This will be an unauthenticated verification. 
    //The request is from an email address, clicking on a link.
    return new Promise(((resolve, reject) => {
      const {
        data = {},
      } = body;
      
      let userId = '';
      try {
        userId = data.userId;
      } catch (e) {
        userId = '';
      }
      if (userId === undefined) {
        userId = '';
      }

      let signupRequestId = '';
      try {
        signupRequestId = data.signupRequestId;
      } catch (e) {
        signupRequestId = '';
      }
      if (signupRequestId === undefined) {
        signupRequestId = '';
      }

      let userVerificationCode = '';
      try {
        userVerificationCode = data.userVerificationCode;
      } catch (e) {
        userVerificationCode = '';
      }
      if (userVerificationCode === undefined) {
        userVerificationCode = '';
      }

      GH_authApi.userAccountEmailVerification(userId, signupRequestId, userVerificationCode)
      .then((apiResponseData) => {
        console.warn("API | RESPONSE | userAccountEmailVerification");
        //console.log(apiResponseData);

        if (apiResponseData.status === true) {

          ///////////////////////////////
          // APP STATUS TOAST MESSAGING
          ///////////////////////////////
          if (apiResponseData.data.appStatus === true) {
            
          } else {
            
          }
          
          
        } else {
          

        }
        
        resolve(apiResponseData);
      })
      .catch((error) => {
        resolve(error);
      });
    }));
  };
}



export function testIdentityAuthVerification(body = {}) {
  return (dispatch, getState) => {
    //UNABLE TO SUPPORT REGION - WE DONT KNOW THE SOURCE   
    //This will be an unauthenticated verification. 
    //The request is from an email address, clicking on a link.
    return new Promise(((resolve, reject) => {
      const {
        data = {},
      } = body;
      
      let userId = '';
      try {
        userId = data.userId;
      } catch (e) {
        userId = '';
      }
      if (userId === undefined) {
        userId = '';
      }

      let userVerificationCode = '';
      try {
        userVerificationCode = data.userVerificationCode;
      } catch (e) {
        userVerificationCode = '';
      }
      if (userVerificationCode === undefined) {
        userVerificationCode = '';
      }

      GH_authApi.testAccountEmailVerification(userId, userVerificationCode)
      .then((apiResponseData) => {
        console.warn("API | RESPONSE | testAccountEmailVerification");
        //console.log(apiResponseData);

        if (apiResponseData.status === true) {

          ///////////////////////////////
          // APP STATUS TOAST MESSAGING
          ///////////////////////////////
          if (apiResponseData.data.appStatus === true) {
            
          } else {
            
          }
          
          
        } else {
          

        }
        
        resolve(apiResponseData);
      })
      .catch((error) => {
        resolve(error);
      });
    }));
  };
}