import Request from './Request';
import RequestRegion from './RequestRegion';

class GH_authApi {
  /************************************************************
   * SIGNUP - via firebase, pass token to verify.
   * - this is the first phase of verifying your account - we send an email to verify
   * - After verify, you can request signup endpoint
   */

  static initialSignupRequest(fireAccessToken) {
    console.warn('API | POST | Initial Signup Activity (Authenticated)');
    return Request.POST_json(`${process.env.GH_authApi}/verify/v1`, null, fireAccessToken);
  }

  static userAccountEmailVerification(userId, signupRequestId, userVerificationCode) {
    console.warn('API | POST | Verify account email (Unauthenticated)');
    return Request.POST_json(`${process.env.GH_authApi}/signup/v1/user/${userId}/verify?id=${signupRequestId}&vid=${userVerificationCode}`, null, null);
  }

  //TEST ENDPOINT
  static testAccountEmailVerification(userId, userVerificationCode) {
    console.warn('API | POST | Verify account email (Unauthenticated)');
    return Request.POST_json(`${process.env.GH_testApi}/signup/v1/user/${userId}/verify?id=${userVerificationCode}`, null, null);
  }

  static initialLoginRequest(userId, fireAccessToken) {
    console.warn('API | POST | Initial Login Activity (Authenticated)');
    return Request.POST_json(`${process.env.GH_authApi}/login/v1/${userId}`, null, fireAccessToken);
  }

  static requestAccountDeletion(hudAccessToken) {
    console.warn('API | POST | Request Account Deletion (Authenticated)');
    return Request.DELETE_json(`${process.env.GH_authApi}/remove-account/v1`, null, hudAccessToken);
  }
  
  static requestPublicKey() {
    console.warn('API | POST | Request Public Key (Unauthenticated)');
    return Request.POST_json(`${process.env.GH_authApi}/public/v1`, null, null);
  }

  static requestPasswordReset(body, hudAccessToken) {
    console.warn('API | POST | Request Password Reset');
    return Request.POST_json(`${process.env.GH_authApi}/password-reset-request/v1`, body, hudAccessToken);
  }
  static confirmPasswordReset(body, hudAccessToken) {
    console.warn('API | POST | Confirm Password Reset');
    return Request.POST_json(`${process.env.GH_authApi}/password-reset-activity/v1`, body, hudAccessToken);
  }
  

  static reAuthRequest(body) {
    console.warn('API | POST | Re-Auth (Authenticated)');
    return Request.POST_json(`${process.env.GH_authApi}/re-auth/v1`, body, null);
  }
  












  static login(body, accessToken) {
    console.warn('API | POST | Login user');
    return Request.POST_json(`${process.env.authAPI}/v1/user-login/login`, body, accessToken);
  }
  static mfaLogin(userId, body, accessToken) {
    console.warn('API | POST | MFA user login');
    return Request.POST_json(`${process.env.authAPI}/v1/user-login/user/${userId}/mfa`, body, accessToken);
  }
  static signup(region, body, accessToken) {
    console.warn('API | POST | Signup user');
    return Request.POST_json(`${process.env.authAPI}/v1/user-signup/region/${region}`, body, accessToken);
  }


  static refreshAccessToken(userDataRegion, body, refreshToken) {
    console.warn('API | POST | Refresh Access Token');
    return RequestRegion.POST_json(userDataRegion, 'authApi', `/v1/user-refresh/refresh`, body, refreshToken);
  }
  static refreshRefreshToken(userDataRegion, body, refreshToken) {
    console.warn('API | POST | Refresh Refresh Token');
    //Note: same endpoint. The endpoint will determeine if a new refresh token is provided or not.
    return RequestRegion.POST_json(userDataRegion, 'authApi', `/v1/user-refresh/refresh`, body, refreshToken);
  }
  //DELETE USER
  static userDelete(userDataRegion, userId, body, accessToken) {
    console.warn('API | POST | Delete user');
    return RequestRegion.POST_json(userDataRegion, 'authApi', `/v1/user-delete/user/${userId}`, body, accessToken);
  }


  


  



  static loginAndLinkAccount(newFirebaseAccessToken, existingUserId, body, accessToken) {
    console.warn('API | POST | Login and link user account');
    return Request.POST_json(`${process.env.authAPI}/v1/link/${existingUserId}`, body, accessToken);
  }

  static loginAndDelete(accessToken) {
    console.log('loginAndDelete(accessToken)');
    console.log(accessToken);
    return Request.POST_json(`${process.env.authAPI}/v1/login/delete`, null, accessToken);
  }
  
  static firebaseAuthUpdate(accessToken, body) {
    console.warn('API | POST | Firebase user update');
    return Request.POST_json(`${process.env.authAPI}/v1/firebaseupdate`, body, accessToken);
  }
  
  
  



}

export default GH_authApi;

