import { v1 as uuidv1 } from 'uuid';
import { updateWindowSizeV1R } from 'Reducers/UserInterface';






/********************************************************************
* 
********************************************************************/
export function updateWindowSize(windowSize) {
  console.warn("ACTION: updateWindowSize");
  return (dispatch, getState) => {
    return new Promise(((resolve, reject) => {
      dispatch(updateWindowSizeV1R(JSON.parse(JSON.stringify(windowSize))));
      resolve();
    }));
  };
}
