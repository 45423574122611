
const initialState = {
  fireAccessToken: '',
  fireUserId: '',
  hudAccessToken: '',
  fireUser: '',
};

const resetState = {
  fireAccessToken: '',
  hudAccessToken: '',
  fireUser: '',
};





const GameHudAuthReducer = (state = initialState, action) => {
  switch (action.type) {

    case 'RESET_LOCAL_STATE':
    {
      return resetState;
    }


    case 'SET_FIRE_USER_DATA': {
      const clone = Object.assign({}, state);
      console.log("SET_FIRE_USER_DATA");

      let uid = '';
      try {
        uid = action.data.user.uid;    //TO CHECK
      } catch (e) {
        uid = '';
      }
      if (uid === undefined) {
        uid = '';
      }
      let fireAccessToken = '';
      try {
        fireAccessToken = action.data.user.accessToken;
      } catch (e) {
        fireAccessToken = '';
      }
      if (fireAccessToken === undefined) {
        fireAccessToken = '';
      }
      
      clone.fireUser = action.data.user;
      clone.fireUserId = uid;
      clone.fireAccessToken = fireAccessToken;

      return clone;
    }


    case 'SET_FIRE_ACCESS_TOKEN': {
      const clone = Object.assign({}, state);
      console.log("SET_FIRE_ACCESS_TOKEN");

      let token = '';
      try {
        token = action.data.token;
      } catch (e) {
        token = '';
      }
      if (token === undefined) {
        token = '';
      }
      
      clone.fireAccessToken = token;
      return clone;
    }
    case 'SET_HUD_ACCESS_TOKEN': {
      const clone = Object.assign({}, state);
      console.log("SET_HUD_ACCESS_TOKEN");

      let token = '';
      try {
        token = action.data.token;
      } catch (e) {
        token = '';
      }
      if (token === undefined) {
        token = '';
      }
      
      clone.hudAccessToken = token;
      return clone;
    }



    


    default:
      return state;
  }
};

export default GameHudAuthReducer;
