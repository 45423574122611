// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyB5zxdXu78QALyw3h3CP2jcUOisEFu7agI",
  authDomain: "uberdigital-d7b8b.firebaseapp.com",
  projectId: "uberdigital-d7b8b",
  storageBucket: "uberdigital-d7b8b.appspot.com",
  messagingSenderId: "21381855893",
  appId: "1:21381855893:web:fb3b0700b8882d99c3b99c",
  measurementId: "G-C64QLF369M"
};
//export const firebaseAuth = initializeApp(firebaseConfig);



let firebaseAuth = initializeApp(firebaseConfig);

export default firebaseAuth; 