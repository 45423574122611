import store from 'Store/ConfigureStore';
import { v4 as uuidv4 } from 'uuid';

import Sockette from "sockette";

import { addPilotR, removePilotR, setActivePilotR, setCopilotCrewIdR, removeCopilotCrewIdR, shiftKeyQueueItemR, shiftKeyInputQueueItemR, 
  setupBaseProfileR, changeProfileScreenR, toggleGameSyncR, setUserDataR, generateGlassIdR, setLoginStatusR, setDemoStatusR, setLogoutStatusR, 
  updateSocketStatusR, updateKeyStateV2R, updateKeyStateR, updateLocalKeyStateV2R, updateLocalKeyStateR, 
  setSocketConnectionR, setSocketDisconnectionR, updateSocketEventLogR, resetLocalStateGameR } from 'Reducers/Game';
  import { setFireUserDataR, } from 'Reducers/Auth';
  
let starCitizenSocketObj = null;





let globalActionTimerStateStore = [];

export function setGlobalActionState(data) {
  console.log("ACTION | setGlobalActionState");
  return (dispatch, getState) => {
    return new Promise(((resolve, reject) => {
      /****************************************************************
       * NOTES
       * - Fire the keyPress action immediately (as the user has pressed the button)
       * - Add the setInterval for continual processing
       * - Confirm the components can access this variable to confirm status of the function
       * --- in theory, the state store has the state as active/not active...
       ***************************************************************/

      let id = '';
      try {
        id = data.id;
      } catch (e) {
        id = '';
      }
      if (id === undefined) {
        id = '';
      }
      let intervalSec = 5;
      try {
        intervalSec = data.intervalSec;
      } catch (e) {
        intervalSec = 5;
      }
      if (intervalSec === undefined) {
        intervalSec = 5;
      }
      //Convert Seconds to Miliseconds
      let intervalMiliSec = intervalSec * 1000;
      let keyCommand = '';
      try {
        keyCommand = data.keyCommand;
      } catch (e) {
        keyCommand = '';
      }
      if (keyCommand === undefined) {
        keyCommand = '';
      }
      let keyIntent = '';
      try {
        keyIntent = data.keyIntent;
      } catch (e) {
        keyIntent = '';
      }
      if (keyIntent === undefined) {
        keyIntent = '';
      }
      let userId = '';
      try {
        userId = data.userId;
      } catch (e) {
        userId = '';
      }
      if (userId === undefined) {
        userId = '';
      }

      let foundExistingRecord = false;
      for (let x = 0; x < globalActionTimerStateStore.length; x++) {
        if (globalActionTimerStateStore[x].id === id) {
          foundExistingRecord = true;
          //update maybe? Cancel timer. ReAdd Timer with new interval + data?
        }
      }
      if (foundExistingRecord === false) {
        globalActionTimerStateStore.push({
          id: id,
          timer: 
            setInterval(function() {
              console.log(`Global Action: ${id} - Timer Action`);
              //No co-pilot functionality yet
              if (keyCommand.length > 0) {
                store.dispatch(starCitizenSocketSend({keyCommand: keyCommand, keyIntent: keyIntent, userId: userId, coPilotAction: false, coPilotId: ''}));
              }
               
            }, intervalMiliSec),
        })
      }

    }));
  };
}
export function removeGlobalActionState(data) {
  console.log("ACTION | removeGlobalActionState");
  return (dispatch, getState) => {
    return new Promise(((resolve, reject) => {

      let id = '';
      try {
        id = data.id;
      } catch (e) {
        id = '';
      }
      if (id === undefined) {
        id = '';
      }

      for (let x = 0; x < globalActionTimerStateStore.length; x++) {
        if (globalActionTimerStateStore[x].id === id) {
          clearInterval(globalActionTimerStateStore[x].timer);
          globalActionTimerStateStore.splice(x, 1);
        }
      }

    }));
  };
}




let ensureSocketRemainsActive = setInterval(function() {
  console.log(`Game Socket Connection`);
  if (starCitizenSocketObj !== null) {
    //On the interval, send a ping socket message.
    store.dispatch(starCitizenSocketPing());
  }
  console.log();
}, 600000);  //Every Minute (60000), 10 mins: 600000


// NEW CO PILOT
/*
export const addPilotRedux = (data) => ({
  type: 'ADD_PILOT',
  data
});
export const removePilotRedux = (data) => ({
  type: 'REMOVE_PILOT',
  data
});
export const setActivePilotRedux = (data) => ({
  type: 'SET_ACTIVE_PILOT',
  data
});
export const setCoPilotCrewIdRedux = (data) => ({
  type: 'SET_COPILOT_CREWID',
  data
});
export const removeCoPilotCrewIdRedux = (data) => ({
  type: 'REMOVE_COPILOT_CREWID',
  data
});
*/


export function addPilot(data) {
  console.log("ACTION | addPilot");
  return (dispatch, getState) => {
    return new Promise(((resolve, reject) => {

      //Advise socket
      try {
        dispatch(scSocket_addPilot(data));
      } catch (e) {
      }
      //Response will be sent to a function on message received.

    }));
  };
}
export function scManage_coPilotAddPilot(data) {
  console.log("ACTION | scManage_coPilotAddPilot");
  return (dispatch, getState) => {
    return new Promise(((resolve, reject) => {
      let pilotCrewId = '';
      try {
        pilotCrewId = data.pilotCrewId;
      } catch (e) {
        pilotCrewId = '';
      }
      if (pilotCrewId === undefined) {
        pilotCrewId = '';
      }    
      console.log(`pilotCrewId: ${pilotCrewId}`);

      let pilotName = '';
      try {
        pilotName = data.pilotName;
      } catch (e) {
        pilotName = '';
      }
      if (pilotName === undefined) {
        pilotName = '';
      }
      console.log(`pilotName: ${pilotName}`);

      let userId = '';
      try {
        userId = data.userId;
      } catch (e) {
        userId = '';
      }
      if (userId === undefined) {
        userId = '';
      }
      console.log(`userId: ${userId}`);

      


      //UPDATE STATE
      /*
      dispatch(addPilotRedux({
        pilotCrewId: pilotCrewId,
        pilotName: pilotName,
      }));
      */
      dispatch(addPilotR({
        pilotCrewId: pilotCrewId,
        pilotName: pilotName,
      }));

    }));
  };
}






export function removePilot(data) {
  console.log("ACTION | removePilot");
  return (dispatch, getState) => {
    return new Promise(((resolve, reject) => {
      //API CALL


      //UPDATE STATE
      //dispatch(removePilotRedux(data));
      dispatch(removePilotR(data));


    }));
  };
}
export function setActivePilot(data) {
  console.log("ACTION | setActivePilot");
  return (dispatch, getState) => {
    return new Promise(((resolve, reject) => {
      //API CALL


      //UPDATE STATE
      //dispatch(setActivePilotRedux(data));
      dispatch(setActivePilotR(data));

    }));
  };
}
//Front end request
export function setCoPilotCrewId(data) {
  console.log("ACTION | setCoPilotCrewId");
  return (dispatch, getState) => {
    return new Promise(((resolve, reject) => {

      //Advise socket of request to create crewid
      try {
        dispatch(scSocket_createCrewId());
      } catch (e) {
      }
      //Response will be sent to a function on message received.

    }));
  };
}

export function scManage_coPilotCreateCrewId(data) {
  console.log("ACTION | scManage_coPilotCreateCrewId");
  return (dispatch, getState) => {
    return new Promise(((resolve, reject) => {
      let coPilotCrewId = '';
      try {
        coPilotCrewId = data.coPilotCrewId;
      } catch (e) {
        coPilotCrewId = '';
      }
      if (coPilotCrewId === undefined) {
        coPilotCrewId = '';
      }    
      console.log(`coPilotCrewId: ${coPilotCrewId}`);

      let userId = '';
      try {
        userId = data.userId;
      } catch (e) {
        userId = '';
      }
      if (userId === undefined) {
        userId = '';
      }
      console.log(`userId: ${userId}`);


      //UPDATE STATE
      /*
      dispatch(setCoPilotCrewIdRedux({
        coPilotCrewId: coPilotCrewId,
        userId: userId,
      }));
      */
      dispatch(setCopilotCrewIdR({
        coPilotCrewId: coPilotCrewId,
        userId: userId,
      }));

      

    }));
  };
}


export function removeCoPilotCrewId(data) {
  console.log("ACTION | removeCoPilotCrewId");
  return (dispatch, getState) => {
    const { coPilot } = getState().Game;
    return new Promise(((resolve, reject) => {

      let coPilotCrewId = '';
      try {
        coPilotCrewId = coPilot.coPilotCrewId;
      } catch (e) {
        coPilotCrewId = '';
      }
      if (coPilotCrewId === undefined) {
        coPilotCrewId = '';
      }

      //Advise socket of request to create crewid
      try {
        dispatch(scSocket_removeCrewId(coPilotCrewId));
      } catch (e) {
      }
      //Response will be sent to a function on message received.

    }));
  };
}

export function scManage_coPilotRemoveCrewId(data) {
  console.log("ACTION | scManage_coPilotCreateCrewId");
  return (dispatch, getState) => {
    return new Promise(((resolve, reject) => {



      
      console.log(`ok to remove the crewid`);


      //UPDATE STATE
      //dispatch(removeCoPilotCrewIdRedux());
      dispatch(removeCopilotCrewIdR());

    }));
  };
}



export function shiftKeyQueueItem(data) {
  return (dispatch, getState) => {
    return new Promise(((resolve, reject) => {
      //UPDATE STATE
      dispatch(shiftKeyQueueItemR(data));
    }));
  };
}
export function shiftKeyInputQueueItem(data) {
  return (dispatch, getState) => {
    return new Promise(((resolve, reject) => {
      //UPDATE STATE
      dispatch(shiftKeyInputQueueItemR(data));
    }));
  };
}
export function setup_base_profile(data) {
  return (dispatch, getState) => {
    return new Promise(((resolve, reject) => {
      //UPDATE STATE
      dispatch(setupBaseProfileR(data));
    }));
  };
}
export function changeProfileScreen(data) {
  return (dispatch, getState) => {
    return new Promise(((resolve, reject) => {
      //UPDATE STATE
      dispatch(changeProfileScreenR(data));
    }));
  };
}
export function togglePausingGameSync() {
  return (dispatch, getState) => {
    return new Promise(((resolve, reject) => {
      //UPDATE STATE
      dispatch(toggleGameSyncR());
    }));
  };
}
export function setStarGlassUserData(data) {
  return (dispatch, getState) => {
    return new Promise(((resolve, reject) => {
      //UPDATE STATE
      dispatch(setUserDataR(data));         //GAME
      dispatch(setFireUserDataR(data));      //AUTH

      
    }));
  };
}



//NEW Global QUEUE
/*
export const shiftKeyQueueItem = (data) => ({
  type: 'SHIFT_KEY_QUEUE_ITEM',
  data
});


export const shiftKeyInputQueueItem = () => ({
  type: 'SHIFT_KEY_INPUT_QUEUE_ITEM',
});

export const setup_base_profile = data => ({
  type: 'SETUP_BASE_PROFILE',
  data,
});
export const changeProfileScreen = data => ({
  type: 'CHANGE_PROFILE_SCREEN',
  data,
});
export const togglePausingGameSync = () => ({
  type: 'TOGGLE_GAME_SYNC',
});

export const setStarGlassUserData = (data) => ({
  type: 'SET_USER_DATA',
  data,
});
*/



export function generateGlassId(data) {
  return (dispatch, getState) => {
    return new Promise(((resolve, reject) => {
      //UPDATE STATE
      dispatch(generateGlassIdR());
    }));
  };
}


export function setDemoStatus(e) {
  console.log("ACTION | setDemoStatus");
  return (dispatch, getState) => {
    return new Promise(((resolve, reject) => {
      //Record login status in state
      console.log("ACTION | setDemoStatus");
      dispatch(setDemoStatusR());

    }));
  };
}

export function setLoginStatus(e) {
  console.log("ACTION | setLoginStatus");
  return (dispatch, getState) => {
    return new Promise(((resolve, reject) => {
      //Record login status in state
      console.log("ACTION | setLoginStatusR");
      dispatch(setLoginStatusR());
      //Start the socket connection
      console.log("ACTION | starCitizenSocket");
      dispatch(starCitizenSocket);

      try {
        window.parent.postMessage({event: 'userLogin', eventDetail: 'userLogin' }, "*");
      } catch (e) {
        console.log(e);
      }

    }));
  };
}

export function setLogoutStatus(e) {
  console.log("ACTION | setLogoutStatus");
  return (dispatch, getState) => {
    return new Promise(((resolve, reject) => {
      //Record login status in state
      console.log("ACTION | setLogoutStatusR");
      store.dispatch(setLogoutStatusR());
      //Advise the child component if exists
      
      try {
        window.parent.postMessage({event: 'userLogout', eventDetail: 'userLogout' }, "*");
      } catch (e) {
        console.log(e);
      }


    }));
  };
}

export function updateSocketStatus(data) {
  return (dispatch, getState) => {
    return new Promise(((resolve, reject) => {
      //UPDATE STATE
      dispatch(updateSocketStatusR(data));
    }));
  };
}
export function storeKeyLocalState(data) {
  return (dispatch, getState) => {
    return new Promise(((resolve, reject) => {
      //UPDATE STATE
      dispatch(updateLocalKeyStateV2R(data));
    }));
  };
}
export function sc_updateKeyState(data) {       ///ORIGINAL???
  return (dispatch, getState) => {
    return new Promise(((resolve, reject) => {
      //UPDATE STATE
      dispatch(updateKeyStateR(data));
    }));
  };
}
export function sc_updateKeyStateV2(data) {
  return (dispatch, getState) => {
    return new Promise(((resolve, reject) => {
      //UPDATE STATE
      dispatch(updateKeyStateV2R(data));
    }));
  };
}
/*
export const generateGlassId = () => ({
  type: 'GENERATE_GLASS_ID',
});

export const setLoginStatusRedux = () => ({
  type: 'SET_LOGIN_STATUS',
});

export const setLogoutStatusRedux = () => ({
  type: 'SET_LOGOUT_STATUS',
});

export const updateSocketStatus = data => ({
  type: 'UPDATE_SOCKET_STATUS',
  data,
});

export const storeKeyLocalState = data => ({
  type: 'UPDATE_LOCAL_KEYSTATE_V2',
  data,
});
export const sc_updateKeyState = data => ({
  type: 'UPDATE_KEYSTATE',
  data,
});
export const sc_updateKeyStateV2 = data => ({
  type: 'UPDATE_KEYSTATE_V2',
  data,
});
*/




/*
export function togglePausingGameSync(e) {
  console.log("ACTION | togglePausingGameSync");
  return (dispatch, getState) => {
    return new Promise(((resolve, reject) => {
      
      try {
        dispatch(toggleGameSyncRedux);
      } catch (e) {
        console.log(e);
      }

    }));
  };
}
*/


/********* NOT SET UP IN NEW REDUX ************************************************
export const resetLocalState = () => ({
  type: 'RESET_LOCAL_STATE',
});

export const sc_socketConnected = () => ({
    type: 'SET_SOCKET_CONNECTION',
});
export const sc_socketDisconnected = () => ({
    type: 'SET_SOCKET_DISCONNECTION',
});
export const sc_updateSocketEventLog = data => ({
  type: 'UPDATE_SOCKET_EVENT_LOG',
  data,
});
 */

export function resetLocalState(data) {
  return (dispatch, getState) => {
    return new Promise(((resolve, reject) => {
      dispatch(resetLocalStateGameR());
    }));
  };
}

export function sc_socketConnected(data) {
  return (dispatch, getState) => {
    return new Promise(((resolve, reject) => {
      dispatch(setSocketConnectionR());
    }));
  };
}
export function sc_socketDisconnected(data) {
  return (dispatch, getState) => {
    return new Promise(((resolve, reject) => {
      dispatch(setSocketDisconnectionR());      
    }));
  };
}
export function sc_updateSocketEventLog(data) {
  return (dispatch, getState) => {
    return new Promise(((resolve, reject) => {
      dispatch(updateSocketEventLogR(data));
      
    }));
  };
}



  export function sc_wssOnOpen(e) {
    return (dispatch, getState) => {
      return new Promise(((resolve, reject) => {
        
        console.log('ACTION | onopen');
        console.log(e);
        //Need to emit the security for this user as a JWT to verify.

        dispatch(updateSocketStatus(true));
  
        let data = {
          type: 'wss connection open',
          timestamp: Date.now(),
        }
        dispatch(sc_updateSocketEventLog(data));
        
  
  
  
      }));
    };
  }
  
  export function sc_wssOnReconnect(e) {
    return (dispatch, getState) => {
      return new Promise(((resolve, reject) => {
        
        //console.log('ACTION | onreconnect');
        //console.log(e);

        dispatch(updateSocketStatus(true));
  
        let data = {
          type: 'wss connection re-established',
          timestamp: Date.now(),
        }
        dispatch(sc_updateSocketEventLog(data));
  
  
      }));
    };
  }
  
  export function sc_wssOnMaximum(e) {
    return (dispatch, getState) => {
      return new Promise(((resolve, reject) => {
        
        //console.log('ACTION | onmaximum');
        //console.log(e);
        
        dispatch(updateSocketStatus(false));
  
        let data = {
          type: 'wss maximum attempts',
          timestamp: Date.now(),
        }
        dispatch(sc_updateSocketEventLog(data));
  
  
      }));
    };
  }
  
  export function sc_wssOnClose(e) {
    return (dispatch, getState) => {
      return new Promise(((resolve, reject) => {
        
        //console.log('ACTION | onclose');
        //console.log(e);
        
        dispatch(updateSocketStatus(false));
  
        //reset socket to null
        starCitizenSocketObj = null;
  
        let data = {
          type: 'wss connection closed',
          timestamp: Date.now(),
        }
        dispatch(sc_updateSocketEventLog(data));
  
  
      }));
    };
  }
  
  export function sc_wssOnError(e) {
    return (dispatch, getState) => {
      return new Promise(((resolve, reject) => {
        
        //console.log('ACTION | onerror');
        //console.log(e);
        
        dispatch(updateSocketStatus(false));
  
        //reset socket to null
        starCitizenSocketObj = null;
  
        let data = {
          type: 'wss error',
          timestamp: Date.now(),
        }
        dispatch(sc_updateSocketEventLog(data));
  
  
      }));
    };
  }
  


  export function sc_wssMessageReceived(e) {
    return (dispatch, getState) => {
      const { activeProfileId, profiles } = getState().Game;
      return new Promise(((resolve, reject) => {
        console.log("sc_wssMessageReceived");
        
  
        // Determine the payload action
        let dataReceived = {};
        try {
          dataReceived = JSON.parse(e.data);
        } catch(e) {}
  
        //console.log("dataReceived");
        //console.log(dataReceived);
        //"{\"connectionId\":\"G_TT3fo_ywMCJ_A=\",\"receivedData\":{\"ty\":\"pong\",\"ts\":1633864782548,\"t\":1633864784148,\"n\":\"783aa911-3e1d-4ba7-8b58-54198896bb4b\"}}"

        let receivedData = '';
        try {
          receivedData = dataReceived.data;
        } catch (e) {
          receivedData = '';
        }
        if (receivedData === undefined) {
          receivedData = '';
        }

        let messageType = '';
        try {
          messageType = receivedData.type;
        } catch (e) {
          messageType = '';
        }
        if (messageType === undefined) {
          messageType = '';
        }

        if (messageType === "copilot-create-crewid") {
          
          dispatch(scManage_coPilotCreateCrewId(receivedData));

        } else if (messageType === "copilot-remove-crewid") {
          
          dispatch(scManage_coPilotRemoveCrewId(receivedData));

        } else if (messageType === "copilot-add-pilot") {
          
          dispatch(scManage_coPilotAddPilot(receivedData));


          


          

        } else {

          /******************************************
           * ORIGINAL KEY ACTIONS - CRITICAL
           ******************************************/

          let keyCommand = '';
          try {
            keyCommand = receivedData.keyCommand;
          } catch (e) {
            keyCommand = '';
          }
          if (keyCommand === undefined) {
            keyCommand = '';
          }

          let keyCommandT = '00';
          try {
            keyCommandT = receivedData.keyCommandT;
          } catch (e) {
            keyCommandT = '00';
          }
          if (keyCommandT === undefined) {
            keyCommandT = '00';
          }

          let secondsHeld = 0;
          try {
            secondsHeld = receivedData.secondsHeld;
          } catch (e) {
            secondsHeld = 0;
          }
          if (secondsHeld === undefined) {
            secondsHeld = 0;
          }




    
          let keyIntent = '';
          try {
            keyIntent = receivedData.keyIntent;
          } catch (e) {
            keyIntent = '';
          }
          if (keyIntent === undefined) {
            keyIntent = '';
          }

          let keyDirection = '';
          try {
            keyDirection = receivedData.direction;
          } catch (e) {
            keyDirection = '';
          }
          if (keyDirection === undefined) {
            keyDirection = '';
          }

          let transactionType = '';
          try {
            transactionType = receivedData.transactionType;
          } catch (e) {
            transactionType = '';
          }
          if (transactionType === undefined) {
            transactionType = '';
          }

          let userId = '';
          try {
            userId = receivedData.userId;
          } catch (e) {
            userId = '';
          }
          if (userId === undefined) {
            userId = '';
          }

          let coPilotAction = '';
          try {
            coPilotAction = receivedData.coPilotAction;
          } catch (e) {
            coPilotAction = '';
          }
          if (coPilotAction === undefined) {
            coPilotAction = '';
          }

          let coPilotId = '';
          try {
            coPilotId = receivedData.coPilotId;
          } catch (e) {
            coPilotId = '';
          }
          if (coPilotId === undefined) {
            coPilotId = '';
          }




          console.log('keyCommand, keyIntent RECEIVED FROM DASHBOARD');
          console.log(`keyCommand: ${keyCommand}`);
          console.log(`keyCommandT: ${keyCommandT}`);
          console.log(`keyIntent: ${keyIntent}`);
          console.log(`keyDirection: ${keyDirection}`);
          console.log(`transactionType: ${transactionType}`);
          console.log(`userId: ${userId} - blank is ok in v1`);
          console.log(`coPilotAction: ${coPilotAction}`);
          console.log(`coPilotId: ${coPilotId}`);
          console.log(`secondsHeld: ${secondsHeld}`);

          //HOW TO MANGE (HOLD - secondsHeld) FOR THE ACTION/EVENT UPDATING THE KEYS PRESSED ETC
          
        
          //UPDATE REDUX STATE (FOR THE DASHBOARD)

          //////////////////////////////////////////////////////////////
          // GLASS SCREENS | Remote Screen updates / Management
          //////////////////////////////////////////////////////////////


          console.log("============ DECISION ================");
          if (keyDirection === "ctog") {
            console.log('update the glass screens only');
          } else if (keyDirection === "gtoc") {
            console.log('update all screens, control and glass to they are all in sync');

            //////////////////////////////////////////////////////////////
            // GLASS CONTROL | Desktop device updates / management
            //////////////////////////////////////////////////////////////
            /*
            try {
              window.parent.postMessage(keyCommand, "*");
            } catch (e) {
              console.log(e);
            }
            */

            //// Send through additional data for the game desktop
            let attachedData = {
              coPilotAction: coPilotAction,
              coPilotId: coPilotId,
            }

            if (transactionType === "batchTransaction") {
              try {
                window.parent.postMessage({event: 'batchTransaction', eventDetail: keyCommand, attachedData: attachedData }, "*");
              } catch (e) {
                console.log(e);
              }
            } else {
              try {
                window.parent.postMessage({event: 'keyInput', eventDetail: keyCommand, attachedData: attachedData }, "*");
              } catch (e) {
                console.log(e);
              }
            }

            

            

          }
          console.log("============ DECISION (END) ================");



          console.log('Update the glass screens via redux state');
          /*****************************
           * MANAGEMENT
           * - REDUX LOADS THE KEYMAPPINGS INTO STATE
           * - ADDS A FIELD FOR STATE-STATUS (e.g. on/off, etc)
           * - ADDS A FIELD FOR PRESSED, RECEIVED (e.g. did the user press it, or did the screen receive the event.)
           */


          
          /******************************************************************
          * UPDATED LOGIC
          * defaultGameKey: ["b"]
          * defaultGameKeyEvent: "press", "hold"
          * > This is how we search for the key
          * > We should consider the screen the user is on to determine duplicate key mappings and what it means.
          * > e.g. on ShipScreen, press and hold B - that means Quantum, which means we need to update a few keys (like turn off landing gear).
          * 
          ******************************************************************/
          let keyWasHeldDown = false;
          //Only concerned with how long a key is held down when its pressed individually.
          if (keyCommand.length === 1) {
            if (secondsHeld > 0.750) {
              keyWasHeldDown = true;
            }
          }
          console.warn(`keyWasHeldDown: ${keyWasHeldDown}`);

          let activeScreen = '';
          for (let x = 0; x < profiles.length; x++) {
            if (profiles[x].profileId === activeProfileId) {
              activeScreen = profiles[x].activeScreen;
            }
          }
          console.warn(`activeScreen: ${activeScreen}`);
          

          let keySearch = {
            keyWasHeldDown: keyWasHeldDown,
            activeScreen: activeScreen,
            secondsHeld: secondsHeld,
          }


          //dispatch(sc_updateKeyState({keyCommand, keyCommandT, userId}));
          dispatch(sc_updateKeyStateV2({keyCommand, keySearch, keyCommandT, userId, coPilotAction, coPilotId}));

          
          /********************************
          * SPECIAL KEY ACTIVITY (HOLD EVENTS)
          ********************************/
          
          if (keyWasHeldDown === true) {
            //Additional actions on the back of a received key event (HOLD)
            //Get the active profile

            

            console.warn("attempting to look at Quantum drive other commands");
            console.log(keyCommand);
            let keyCommandLowerCase = keyCommand[0];
            keyCommandLowerCase = keyCommandLowerCase.toLowerCase();
            console.log("keyCommandLowerCase");
            console.log(keyCommandLowerCase);
 

            
            if (keyCommandLowerCase === "b" && activeScreen === "ShipScreen") {
              console.log("SHIP | Quantum Activation");
              //Reset keySearch as this is for additional options based on the main event.
              keySearch = {
                keyWasHeldDown: false,
                activeScreen: activeScreen,
                secondsHeld: 0,
              }
              dispatch(sc_updateKeyStateV2({keyCommand: ['n'], keyCommandT: "10", keySearch, userId, coPilotAction, coPilotId}));    //Lift landing gear
              dispatch(sc_updateKeyStateV2({keyCommand: ['k'], keyCommandT: "10", keySearch, userId, coPilotAction, coPilotId}));    //turn off VTOL
            }

          }

          /********************************
          * SPECIAL KEY ACTIVITY (PRESS EVENTS)
          ********************************/
          if (keyCommand === ["u"]) {
            //dispatch(sc_updateKeyState({keyCommand: ['o'], keyCommandT, userId}));    //shields
            //dispatch(sc_updateKeyState({keyCommand: ['i'], keyCommandT, userId}));    //thrust
            //dispatch(sc_updateKeyState({keyCommand: ['p'], keyCommandT, userId}));    //weapons

            keySearch = {
              keyWasHeldDown: false,
              activeScreen: activeScreen,
              secondsHeld: 0,
            }
            
            dispatch(sc_updateKeyStateV2({keyCommand: ['o'], keyCommandT, keySearch, userId, coPilotAction, coPilotId}));    //shields
            dispatch(sc_updateKeyStateV2({keyCommand: ['i'], keyCommandT, keySearch, userId, coPilotAction, coPilotId}));    //thrust
            dispatch(sc_updateKeyStateV2({keyCommand: ['p'], keyCommandT, keySearch, userId, coPilotAction, coPilotId}));    //weapons

          }

        } //end messageType


        
      }));
    };
  }

  

  
export function provideParentContainerSocketStatus(eventObj) {
  return (dispatch, getState) => {
    return new Promise(((resolve, reject) => {
      
      console.log('provideParentContainerSocketStatus');

      console.log(eventObj);

      try {
        window.parent.postMessage({event: eventObj.event, eventDetail: eventObj.eventDetail }, "*");
      } catch (e) {
        console.log(e);
      }
      



    }));
  };
}


export function starCitizenSocket() {
    return (dispatch, getState) => {
      const { glassId } = getState().Game;
      const { fireAccessToken } = getState().Auth;


      return new Promise(((resolve, reject) => {
        console.log('GP | Create Star Citizen Glass Socket');
  

        if (starCitizenSocketObj !== null) {
          console.log("GP | Socket connection is open");
        } else {
          //Open a new connection
          console.log("GP | Create new socket connection")
  
          starCitizenSocketObj = new Sockette(
            //NEW AGENT SOCKET CONNECTION
            //`wss://jxfhbeqdi6.execute-api.ap-southeast-2.amazonaws.com/prod?token=${fireAccessToken}&gid=${glassId}`,
            `wss://j3grvktqd1.execute-api.ap-southeast-2.amazonaws.com/prod?token=${fireAccessToken}&gid=${glassId}`,
            {
              timeout: 5e3,
              maxAttempts: 1,
              onopen: e => {
                console.log("GP | connected:", e);
                dispatch(sc_wssOnOpen(e));
                dispatch(sc_socketConnected());
                dispatch(provideParentContainerSocketStatus({event: 'socketInfo', eventDetail: 'socketOpen' }));

              },
              onmessage: e => {
                console.log("GP | Message Received:", e);
                dispatch(sc_wssMessageReceived(e));
              },
              onreconnect: e => {
                console.log("GP | Reconnecting...", e);
                dispatch(sc_wssOnReconnect(e));
                dispatch(sc_socketDisconnected());
                dispatch(provideParentContainerSocketStatus({event: 'socketInfo', eventDetail: 'socketReconnect' }));
              },
              onmaximum: e => {
                console.log("GP | Stop Attempting!...", e);
                dispatch(sc_wssOnMaximum(e));
                dispatch(provideParentContainerSocketStatus({event: 'socketInfo', eventDetail: 'socketMax' }));
              },
              onclose: e => {
                console.log("GP | Closed!", e);
                dispatch(sc_wssOnClose(e));
                dispatch(sc_socketDisconnected());
                dispatch(provideParentContainerSocketStatus({event: 'socketInfo', eventDetail: 'socketClosed' }));
              },
              onerror: e => {
                console.log("GP | Error: ", e);
                /***************************************
                 * PERFORM CHECKS TO DETERMINE ERROR
                 * - auth error? token expired?
                 ***************************************/

                //We haven't tested to confirm, set to log out for testing
                dispatch(setLogoutStatus());



                dispatch(sc_wssOnError(e));
                dispatch(sc_socketDisconnected());
                dispatch(provideParentContainerSocketStatus({event: 'socketInfo', eventDetail: 'socketError' }));
              }
            }
          );
        }
  

        
          
          








        //Test sending a socket connection when we establish a socket
        //socketSend();
  
        resolve(starCitizenSocketObj);
  
       
       //resolve(true);
  
      }));
    };
  }
  
  export function starCitizenSocketSend(body = {}) {
    return (dispatch, getState) => {
      const { gameSync, user, loginStatus, demoStatus } = getState().Game;
      return new Promise(((resolve, reject) => {
        if (demoStatus === true) {
          //in demo, do not send message.
          return resolve();
        }
        if (loginStatus === false) {
          //not logged in, do not send message.
          return resolve();
        }

        const { keyCommand = [], secondsHeld = 0, keyIntent = '', direction = 'gtoc', keyCommandT = '00', transactionType = '',
        coPilotAction = false, coPilotId = '', } = body;   //default is glass to control
        let { userId = '', } = body;
        
        let loggedInUserId = '';
        try {
          loggedInUserId = user.uid;
        } catch (e) {
          loggedInUserId = '';
        }
        if (loggedInUserId === undefined) {
          loggedInUserId = '';
        }

        if (userId === '') {
          userId = loggedInUserId;
        }


        if (gameSync === true) {
          console.log('ACTION | Cloud Sync Active | Send');
          starCitizenSocketObj.json({
            action: "sendMessage",
            data: {
              coPilotAction: coPilotAction,
              coPilotId: coPilotId,
              keyCommand: keyCommand,       //['n']
              secondsHeld: secondsHeld,     //3.4445454  (how many seconds the key press was held for)
              keyCommandT: keyCommandT,     //"01" <- off to on "10" <-- on to off
              keyIntent: keyIntent,
              direction: direction,
              transactionType: transactionType,
              userId: userId,
            }          
          });
        } else {
          console.log('ACTION | Cloud Sync Paused');
        }

        
        resolve();
  
      }));
    };
  }

  export function starCitizenSocketPing(body = {}) {
    return (dispatch, getState) => {
      return new Promise(((resolve, reject) => {

        const {  } = body;   //default is glass to control
        console.log('ACTION | starCitizenSocketPing');
  
        starCitizenSocketObj.json({
          action: "ping",
          data: {
            ping: Date.now(),
          }          
        });
        resolve();
  
      }));
    };
  }



  /***************************************************************************
   * CO PILOT 
   ***************************************************************************/

   export function scSocket_createCrewId(body = {}) {
    return (dispatch, getState) => {
      const { gameSync, user } = getState().Game;
      return new Promise(((resolve, reject) => {
        console.log('ACTION | scSocket_createCrewId');

        let { userId = '', } = body;

        //Potentially should send STS Token to verify ourselves. We have auth'ed the original socket tho.
        
        let loggedInUserId = '';
        try {
          loggedInUserId = user.uid;
        } catch (e) {
          loggedInUserId = '';
        }
        if (loggedInUserId === undefined) {
          loggedInUserId = '';
        }

        if (userId === '') {
          userId = loggedInUserId;
        }


        if (gameSync === true) {
          starCitizenSocketObj.json({
            action: "createCoPilotCrewId",
            data: {
              userId: userId,
            }          
          });
        } else {
          console.log('ACTION | Cloud Sync Paused');
        }

        
        resolve();
  
      }));
    };
  }

  export function scSocket_removeCrewId(body = {}) {
    return (dispatch, getState) => {
      const { gameSync, user } = getState().Game;
      return new Promise(((resolve, reject) => {
        console.log('ACTION | scSocket_removeCrewId');

        let { coPilotCrewId = '', userId = '', } = body;

        //Potentially should send STS Token to verify ourselves. We have auth'ed the original socket tho.
        
        let loggedInUserId = '';
        try {
          loggedInUserId = user.uid;
        } catch (e) {
          loggedInUserId = '';
        }
        if (loggedInUserId === undefined) {
          loggedInUserId = '';
        }

        if (userId === '') {
          userId = loggedInUserId;
        }


        if (gameSync === true) {
          starCitizenSocketObj.json({
            action: "removeCoPilotCrewId",
            data: {
              coPilotCrewId: coPilotCrewId,
              userId: userId,
            }          
          });
        } else {
          console.log('ACTION | Cloud Sync Paused');
        }

        
        resolve();
  
      }));
    };
  }
  
  export function scSocket_addPilot(body = {}) {
    return (dispatch, getState) => {
      const { gameSync, user } = getState().Game;
      return new Promise(((resolve, reject) => {
        console.log('ACTION | scSocket_addPilot');

        let { userId = '', pilotCrewId = '', pilotName = '', } = body;

        //Potentially should send STS Token to verify ourselves. We have auth'ed the original socket tho.
        
        let loggedInUserId = '';
        try {
          loggedInUserId = user.uid;
        } catch (e) {
          loggedInUserId = '';
        }
        if (loggedInUserId === undefined) {
          loggedInUserId = '';
        }

        if (userId === '') {
          userId = loggedInUserId;
        }


        if (gameSync === true) {
          starCitizenSocketObj.json({
            action: "addPilot",
            data: {
              pilotCrewId: pilotCrewId,
              pilotName: pilotName,
              userId: userId,
            }          
          });
        } else {
          console.log('ACTION | Cloud Sync Paused');
        }

        
        resolve();
  
      }));
    };
  }
  
  

  