/** BILLING ACTIONS ** */

import { v1 as uuidv1 } from 'uuid';


import GH_socialApi from './api/GH_socialApi';

import { 
  addAccountProfileDataV1R, updateProfileMetricsDataV1R,

  
  
  addAccountToPagesV1R, storeTestDataV1R, addPostDataV1R, storePostDataV1R, toggleCommentVisibilityOnPostsV1R, toggleMetricsVisibilityOnPostsV1R } from 'Reducers/Analytics';





/********************************************************************
* COLLECTING PROFILE DATA
* - Retrieve all the profiles for the users account (without PageMetrics - this data may be too large to pull down in one go)
* - With each profile, progressively download the PageMetrics data.
*   -- Iterator function
*   -- Get one set of data, check if there is more, continue. Or, stop requesting more data if no more to download.
********************************************************************/

/********************************************************************
* GET PROFILE LIST (CORE REQUEST)
********************************************************************/
export function getProfileList(body = {}) {
  return (dispatch, getState) => {
    const { hudAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {

      let {  type = 'unknown', data = {}, status = false, } = body;

      console.log("getProfileList");

      let bodyData = JSON.stringify({
        data: 'core',
      });

      GH_socialApi.getProfileListV1(bodyData, hudAccessToken)
      .then(async (apiResponseData) => {
        console.warn("API | RESPONSE | getProfileListV1");
        console.log(apiResponseData);


        if (apiResponseData.status === true) {
          if (apiResponseData.data.appStatus === true) {

            //consider inital load vs adding a new page to the dataset

            //dispatch(storeTestDataV1R([apiResponseData.data.data]));    //needs to be an array

            if (Array.isArray(apiResponseData.data.data)) {
              for (let x = 0; x < apiResponseData.data.data.length; x++) {
                dispatch(addAccountProfileDataV1R(apiResponseData.data.data[x]));    //needs to be an object

                let profileDataSet = apiResponseData.data.data[x];

                let accountPageData = {
                  pageId: profileDataSet.pageId,
                  platform: "facebook",
                  name: profileDataSet.name,
                  brandName: profileDataSet.brandName,
                  username: profileDataSet.username,
                  socialIcon: profileDataSet?.pageData?.pagePictureUrl,
                  //ssCategory: profileDataSet.ssCategoryList, (not availale?)
                  category: profileDataSet.category,
                  categoryList: profileDataSet.categoryList,
                  pageData: profileDataSet.pageData,
                  pagePerf: profileDataSet.pagePerf,
                }
                dispatch(addAccountToPagesV1R(accountPageData));

                try {
                  dispatch(getProfileMetricsData({
                    pageId: profileDataSet.pageId, 
                    start: 0,     //start record
                    limit: 13,   //max records
                  }));
                } catch (e) {
                  console.log(e);
                  console.log("error trying to dispatch to getProfileMetrics");
                }
                
              }
            }

            //metrics need to be collected separately
 
          }

        } else {
         

        }
        
        resolve();
      })
      .catch((error) => {
       
        resolve(error);
      });
    }));
  };
}

/********************************************************************
* GET PROFILE METRICS (ITERATOR)
********************************************************************/
async function* getProfileMetricsProgressively(body = {}) {
  let skip = 0;
  let skipRecords = 0;
  let { pageId = '', startRecord = 0, dataInterval = 20, maxRecords = 100, maxDate = '', hudAccessToken = '', } = body;

  while (true) {
    skipRecords = skip * dataInterval;
    //skip += dataInterval;
    console.log("getProfileMetricsProgressively (before API Call)");
    /*
    const response = await fetch(`https://api.example.com/data?count=${count}`);
    */

    let bodyData = JSON.stringify({
      pageId: pageId,
      limit: dataInterval,
      skip: skipRecords,
    });


    let recordsRemaining = 0;
    let responseData = {
      status: true,
      data: {},
      continue: true,
    }

    let promise_apiCall = new Promise((resolve, reject) => {
      GH_socialApi.getProfileMetricDataV1(bodyData, hudAccessToken)
      .then((apiResponseData) => {
        console.warn(`API | RESPONSE | getProfileMetricDataV1 (getProfileMetricsProgressively) - ${pageId} | skip: ${skipRecords} | limit: ${dataInterval}`);
        console.warn(apiResponseData);


        if (apiResponseData.status === true) {
          if (apiResponseData.data.appStatus === true) {
            
            try {
              recordsRemaining = apiResponseData.data.metricsRemaining;
            } catch (e) {
              console.error(e);
            }

            resolve({
              status: true,
              data: Object.assign({}, apiResponseData.data.data),
              continue: true,
            });


           
          } else {
            resolve({
              status: false,
              data: {},
              continue: false,
            });
          }
        } else {
          resolve({
            status: false,
            data: {},
            continue: false,
          });
        }
      })
      .catch((error) => {
        console.error(error);
        resolve({
          status: false,
          data: {},
          continue: false,
          error: error,
        });
      });
    });
    responseData = await promise_apiCall;

    //console.log("getProfileMetricsProgressively (after API Call)");
    //console.log(responseData);
    //console.log(JSON.stringify(responseData));


    //check if there are any more records to process and yield a true/false
    let cancelOperation = false;

    if (skip > maxRecords) {
      console.log("EXIT | maxRecords reached");
      responseData.continue = false;
      cancelOperation = true;
      yield responseData;
    }
    if (recordsRemaining <= 0) {
      console.log("EXIT | recordsRemaining === 0");
      responseData.continue = false;
      cancelOperation = true;
      yield responseData;
    }

    if (cancelOperation === false) {
      yield responseData;

    }
    skip++;
  }
}


/********************************************************************
* COLLECTING POST DATA
* - Query DB to find all posts (e.g. 10,000)
* - Query is based on skip/limit, e.g. get records between 0 and 20, then between 20 and 40, etc.
* - Note: Some posts may have too much data (postMetrics) to pull down in a single call.
*
* - For each post, pull down the last 6 months worth of data at max initally, then start the iterator for each. 



* - Posts can have the same issue as Pages where there is too much data to pull down.
* - With each post, progressively download the PostMetrics data.
*   -- Iterator function
*   -- Get one set of data, check if there is more, continue. Or, stop requesting more data if no more to download.
********************************************************************/

/********************************************************************
* GET PROFILE LIST (CORE REQUEST)
********************************************************************/


























/********************************************************************
* GET POST DATA (ORIGINAL)
********************************************************************/
export function getPostData(body = {}) {
  return (dispatch, getState) => {
    const { hudAccessToken } = getState().Auth;
    return new Promise((async (resolve, reject) => {

      let {  type = 'unknown', data = {}, status = false, } = body;

      console.log("getPostData");

      /***********************************************************
       * As there is a lot of data to retrieve, we need the requests
       * to be small chunks of data. 
       * We don't know how much data there will be, so we provide
       * some information with the query to determine how much we get back
       * and if there is more to follow. 
       * ) Send the limit (30)
       * ) Send the skip value (this is based on the loop counter)
       * ) How far back we want to go (we could calculate this on client side)
       ***********************************************************/

      let data_limit = 20;
      let data_skip = 0;
      let bodyData = "";
      /*
      let continueGettingPosts = false;

      GH_socialApi.getPostDataV1(bodyData, hudAccessToken)
      .then((apiResponseData) => {
        console.warn("API | RESPONSE | getPostDataV1");
        console.log(apiResponseData);

        if (apiResponseData.status === true) {
          if (apiResponseData.data.appStatus === true) {
            console.warn(`override all data`);
            dispatch(storePostDataV1R([apiResponseData.data.data]));    //needs to be an array
            continueGettingPosts = true;
          }
        } 
        resolve();
      })
      .catch((error) => {
      
        resolve(error);
      });
      */

      /************************** SYNCHRONOUS LOOP *********************************/

      /*
      let processingArr = Array.from(Array(10).keys());
      console.log("processingArr");
      console.log(processingArr);
      processingArr.forEach(function(item, x) { 
        console.log(x);
      });
      */
      /*
      let processingObj = {
        0: 0,
        1: 1,
        2: 2,
      }

      
      Object.keys(processingObj).forEach(async function(key) {
        console.log(key);
        data_skip = data_limit * key;

        bodyData = JSON.stringify({
          limit: data_limit,
          skip: data_skip,
        });

        let promise_callAPI = new Promise((resolve, reject) => {
          GH_socialApi.getPostDataV1(bodyData, hudAccessToken)
          .then((apiResponseData) => {
            console.warn("API | RESPONSE | getPostDataV1");
            console.log(apiResponseData);

            if (apiResponseData.status === true) {
              if (apiResponseData.data.appStatus === true) {

                //consider inital load vs adding a new page to the dataset

                console.log(`push new data | key: ${key}`);

                if (apiResponseData.data.data.length > 0) {
                  dispatch(addPostDataV1R(apiResponseData.data.data));
                } else {
                }

              } else {
                
              }

            } else {
            }
            
            resolve();
          })
          .catch((error) => {
          
            resolve(error);
          });
        });
        let results_callAPI = await promise_callAPI;
        console.log(`results_callAPI: ${results_callAPI} | key: ${key}`)

        
      });
      
      resolve();
      */

     


      /*
      processingArr.forEach(async function(item, x) { 
        data_skip = data_limit * x;

        bodyData = JSON.stringify({
          limit: data_limit,
          skip: data_skip,
        });

        await GH_socialApi.getPostDataV1(bodyData, hudAccessToken)
        .then((apiResponseData) => {
          console.warn("API | RESPONSE | getPostDataV1");
          console.log(apiResponseData);

          if (apiResponseData.status === true) {
            if (apiResponseData.data.appStatus === true) {

              //consider inital load vs adding a new page to the dataset

              console.log(`push new data | x: ${x}`);

              if (apiResponseData.data.data.length > 0) {
                dispatch(addPostDataV1R(apiResponseData.data.data));
              } else {
              }

            } else {
              
            }

          } else {
          }
          
          resolve();
        })
        .catch((error) => {
        
          resolve(error);
        });
      });
      */







      
      let max_apiCalls = 50;   //50;   //160;    //4 = good, 8 = videos, 10 = 900 posts, 130 is max (at groups of 30 - too much data)

      //Put a max on how many API calls (how far we go back at max)


      let apiCalls = [];

      for (let x = 0; x < max_apiCalls; x++) {
        console.log(`API Call ${x}`);
        data_skip = data_limit * x;

        bodyData = JSON.stringify({
          limit: data_limit,
          skip: data_skip,
        });

        let promise_apiCall = new Promise((resolve, reject) => {
          GH_socialApi.getPostDataV1(bodyData, hudAccessToken)
          .then((apiResponseData) => {
            console.warn("API | RESPONSE | getPostDataV1");
            console.log(apiResponseData);


            if (apiResponseData.status === true) {
              if (apiResponseData.data.appStatus === true) {

                //consider inital load vs adding a new page to the dataset

                console.log(`push new data | x: ${x}`);

                if (apiResponseData.data.data.length > 0) {
                  /*********************************************************************
                   * FORMAT & CALCULATE THE DATA FOR STORAGE
                   * - This saves on re-render times in browser
                   *********************************************************************/
                  let postData = Object.assign([], apiResponseData.data.data);

                  

                  dispatch(addPostDataV1R(postData));
                } else {
                  x = max_apiCalls;
                }

                

              } else {
                x = max_apiCalls;
              }

            } else {
              x = max_apiCalls;

            }
            
            resolve();
          })
          .catch((error) => {
          
            resolve(error);
          });
        });
        apiCalls.push(promise_apiCall)
        //let results_apiCall = await promise_apiCall;

        /*
        await GH_socialApi.getPostDataV1(bodyData, hudAccessToken)
        .then((apiResponseData) => {
          console.warn("API | RESPONSE | getPostDataV1");
          console.log(apiResponseData);


          if (apiResponseData.status === true) {
            if (apiResponseData.data.appStatus === true) {

              //consider inital load vs adding a new page to the dataset

              console.log(`push new data | x: ${x}`);

              if (apiResponseData.data.data.length > 0) {
                dispatch(addPostDataV1R(apiResponseData.data.data));
              } else {
                x = max_apiCalls;
              }

              

            } else {
              x = max_apiCalls;
            }

          } else {
            x = max_apiCalls;

          }
          
          resolve();
        })
        .catch((error) => {
        
          resolve(error);
        });
        */


      } //End For Loop





      await Promise.all(apiCalls)
      .then((values) => {
        console.log("All API Calls complete");
        resolve();
      });

      
      

    }));
  };
}




























/********************************************************************
* STORE CREDENTIALS
********************************************************************/
export function storeSocialCredentials(body = {}) {
  return (dispatch, getState) => {
    const { hudAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {

      let {  type = 'unknown', data = {}, status = false, } = body;

      console.log("storeSocialCredentials");

      let bodyData = JSON.stringify({
        type: type,
        data: data,
        status: status,
      });

      GH_socialApi.storeSocialCredentialV1(bodyData, hudAccessToken)
      .then((apiResponseData) => {
        console.warn("API | RESPONSE | storeSocialCredentialV1");
        //console.log(apiResponseData);


        if (apiResponseData.status === true) {
          if (apiResponseData.data.appStatus === true) {
            
          }

        } else {
         

        }
        
        resolve();
      })
      .catch((error) => {
       
        resolve(error);
      });
    }));
  };
}



/********************************************************************
* TRIGGER SCAN
********************************************************************/
export function triggerScan(body = {}) {
  return (dispatch, getState) => {
    const { hudAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {

      let {  type = 'unknown', data = {}, status = false, } = body;

      console.log("triggerScan");

      let bodyData = JSON.stringify({
      });

      GH_socialApi.triggerScanV1(bodyData, hudAccessToken)
      .then((apiResponseData) => {
        console.warn("API | RESPONSE | triggerScanV1");
        //console.log(apiResponseData);


        if (apiResponseData.status === true) {
          if (apiResponseData.data.appStatus === true) {
            
          }

        } else {
         

        }
        
        resolve();
      })
      .catch((error) => {
       
        resolve(error);
      });
    }));
  };
}



/********************************************************************
* GET PROFILE LIST (ORIGINAL CODE)
********************************************************************/
export function getProfileList_ORIGINAL(body = {}) {
  return (dispatch, getState) => {
    const { hudAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {

      let {  type = 'unknown', data = {}, status = false, } = body;

      console.log("getProfileList");

      let bodyData = JSON.stringify({
        data: 'core',
      });

      GH_socialApi.getProfileListV1(bodyData, hudAccessToken)
      .then((apiResponseData) => {
        console.warn("API | RESPONSE | getProfileListV1");
        console.log(apiResponseData);


        if (apiResponseData.status === true) {
          if (apiResponseData.data.appStatus === true) {

            //consider inital load vs adding a new page to the dataset

            //dispatch(storeTestDataV1R([apiResponseData.data.data]));    //needs to be an array

            if (Array.isArray(apiResponseData.data.data)) {
              for (let x = 0; x < apiResponseData.data.data.length; x++) {
                dispatch(addAccountProfileDataV1R(apiResponseData.data.data[x]));    //needs to be an object

                let profileDataSet = apiResponseData.data.data[x];

                let accountPageData = {
                  pageId: profileDataSet.pageId,
                  platform: "facebook",
                  name: profileDataSet.name,
                  brandName: profileDataSet.brandName,
                  username: profileDataSet.username,
                  socialIcon: profileDataSet?.pageData?.pagePictureUrl,
                  //ssCategory: profileDataSet.ssCategoryList, (not availale?)
                  category: profileDataSet.category,
                  categoryList: profileDataSet.categoryList,
                  pageData: profileDataSet.pageData,
                  pagePerf: profileDataSet.pagePerf,
                }
                dispatch(addAccountToPagesV1R(accountPageData));


                try {
                  dispatch(getProfileMetricsData({
                    pageId: profileDataSet.pageId, 
                    start: 0,     //start record
                    limit: 13,   //max records
                  }));
                } catch (e) {
                  console.log(e);
                  console.log("error trying to dispatch to getProfileMetrics");
                }
                
              }
            }

            //metrics need to be collected separately
 
          }

        } else {
         

        }
        
        resolve();
      })
      .catch((error) => {
       
        resolve(error);
      });
    }));
  };
}



/********************************************************************
* GET PROFILE METRICS
********************************************************************/
export function getProfileMetricsData(body = {}) {
  return (dispatch, getState) => {
    const { hudAccessToken } = getState().Auth;
    return new Promise(( async (resolve, reject) => {
      console.log("getProfileMetricsData");

      let {  pageId = '', start = 0, limit = 40, } = body;

      let loopOffset = start;
      let max_apiCalls = 50; 
      let data_limit = 30;
      let data_skip = start;
      let bodyData = {};

      //Determine the number of API calls to get the data requested (e.g. start, limit)
      let recordsToRequest = limit;   //e.g. 40
      max_apiCalls = recordsToRequest / data_limit;   //e.g. 40 records required / max records that can be requested at once. 40 / 20 = 2
      max_apiCalls = max_apiCalls + loopOffset;       //e.g. 2 + 40 = 42
      
      let apiCalls = [];



      try {
        for await (const response of getProfileMetricsProgressively({
            pageId: pageId, 
            startRecord: 0,     //start record
            dataInterval: 40,
            maxRecords: 400,
            maxDate: '',
            hudAccessToken: hudAccessToken,
          })) {
            console.log("api call done");
            console.log(response);

            if (response.data !== undefined && response.data !== {} && Object.keys(response.data).length > 0) {
              dispatch(updateProfileMetricsDataV1R(response.data));

            }

    

            if (response.status === false) {
              break;
            }
            if (response.continue === false) {
              break;
            }

            //if .... 

            //break;

        }
      } catch (e) {
        console.error(e);
      }













      /*
      for (let x = loopOffset; x < max_apiCalls; x++) {           //e.g. x = 40, x < 42
        console.log(`API Call ${x}`);
        data_skip = data_limit * x;                               //e.g. 20 * 40 = 800 (starting at), x increments each time.

        bodyData = JSON.stringify({
          pageId: pageId,
          limit: data_limit,
          skip: data_skip,
        });

        let promise_apiCall = new Promise((resolve, reject) => {
          GH_socialApi.getProfileMetricDataV1(bodyData, hudAccessToken)
          .then((apiResponseData) => {
            console.warn("API | RESPONSE | getProfileMetricDataV1");
            console.log(apiResponseData);


            if (apiResponseData.status === true) {
              if (apiResponseData.data.appStatus === true) {
                //consider inital load vs adding a new page to the dataset
                console.log(`push new data | x: ${x}`);
                if (apiResponseData.data.data.length > 0) {

                  dispatch(updateProfileMetricsDataV1R(apiResponseData.data.data));

                } else {
                  x = max_apiCalls;
                }
              } else {
                x = max_apiCalls;
              }
            } else {
              x = max_apiCalls;
            }
            resolve();
          })
          .catch((error) => {
          
            resolve(error);
          });
        });
        apiCalls.push(promise_apiCall)
        //let results_apiCall = await promise_apiCall;
      } //End For Loop

      await Promise.all(apiCalls)
      .then((values) => {
        console.log("All API Calls complete");
        resolve();
      });
      */


    }));
  };
}
















/********************************************************************
* GET PROFILE METRICS (ORIGINAL)
********************************************************************/
export function getProfileMetricsData_ORIGINAL(body = {}) {
  return (dispatch, getState) => {
    const { hudAccessToken } = getState().Auth;
    return new Promise(( async (resolve, reject) => {
      console.log("getProfileMetricsData");

      let {  pageId = '', start = 0, limit = 40, } = body;

      let loopOffset = start;
      let max_apiCalls = 10; 
      let data_limit = 2;
      let data_skip = start;
      let bodyData = {};

      //Determine the number of API calls to get the data requested (e.g. start, limit)
      let recordsToRequest = limit;   //e.g. 40
      max_apiCalls = recordsToRequest / data_limit;   //e.g. 40 records required / max records that can be requested at once. 40 / 20 = 2
      max_apiCalls = max_apiCalls + loopOffset;       //e.g. 2 + 40 = 42
      
      let apiCalls = [];

      for (let x = loopOffset; x < max_apiCalls; x++) {           //e.g. x = 40, x < 42
        console.log(`API Call ${x}`);
        data_skip = data_limit * x;                               //e.g. 20 * 40 = 800 (starting at), x increments each time.

        bodyData = JSON.stringify({
          pageId: pageId,
          limit: data_limit,
          skip: data_skip,
        });

        let promise_apiCall = new Promise((resolve, reject) => {
          GH_socialApi.getProfileMetricDataV1(bodyData, hudAccessToken)
          .then((apiResponseData) => {
            console.warn("API | RESPONSE | getProfileMetricDataV1");
            console.log(apiResponseData);


            if (apiResponseData.status === true) {
              if (apiResponseData.data.appStatus === true) {
                //consider inital load vs adding a new page to the dataset
                console.log(`push new data | x: ${x}`);
                if (apiResponseData.data.data.length > 0) {

                  dispatch(updateProfileMetricsDataV1R(apiResponseData.data.data));

                } else {
                  x = max_apiCalls;
                }
              } else {
                x = max_apiCalls;
              }
            } else {
              x = max_apiCalls;
            }
            resolve();
          })
          .catch((error) => {
          
            resolve(error);
          });
        });
        apiCalls.push(promise_apiCall)
        //let results_apiCall = await promise_apiCall;
      } //End For Loop

      await Promise.all(apiCalls)
      .then((values) => {
        console.log("All API Calls complete");
        resolve();
      });


    }));
  };
}

































/********************************************************************
* GET PROFILE DATA
********************************************************************/
export function getProfileData(body = {}) {
  return (dispatch, getState) => {
    const { hudAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {

      let {  type = 'unknown', data = {}, status = false, } = body;

      console.log("getProfileData");

      let bodyData = JSON.stringify({
        data: 'core',
      });

      GH_socialApi.getProfileDataV1(bodyData, hudAccessToken)
      .then((apiResponseData) => {
        console.warn("API | RESPONSE | getProfileDataV1");
        console.log(apiResponseData);


        if (apiResponseData.status === true) {
          if (apiResponseData.data.appStatus === true) {

            //consider inital load vs adding a new page to the dataset

            //dispatch(storeTestDataV1R([apiResponseData.data.data]));    //needs to be an array

            //Core Profile Data
            dispatch(addAccountProfileDataV1R(apiResponseData.data.data));    //needs to be an object
            //Metrics Insert/Update (supports paging - e.g. not removal, only update or insert)
            dispatch(updateProfileMetricsDataV1R(apiResponseData.data.data));    //needs to be an object
            

            let profileDataSet = apiResponseData.data.data;

            let accountPageData = {
              pageId: profileDataSet.pageId,
              platform: "facebook",
              name: profileDataSet.name,
              brandName: profileDataSet.brandName,
              username: profileDataSet.username,
              socialIcon: profileDataSet?.pageData?.pagePictureUrl,
              //ssCategory: profileDataSet.ssCategoryList, (not availale?)
              category: profileDataSet.category,
              categoryList: profileDataSet.categoryList,
              pageData: profileDataSet.pageData,
              pagePerf: profileDataSet.pagePerf,
            }
            dispatch(addAccountToPagesV1R(accountPageData));
      
            
          }

        } else {
         

        }
        
        resolve();
      })
      .catch((error) => {
       
        resolve(error);
      });
    }));
  };
}








/********************************************************************
* DB WRITE TEST
********************************************************************/
export function dbwriteTest(body = {}) {
  return (dispatch, getState) => {
    const { hudAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {

      let {  } = body;

      console.log("dbwriteTest | action");

      let bodyData = JSON.stringify({});

      GH_socialApi.dbWriteTest(hudAccessToken)
      .then((apiResponseData) => {
        console.warn("API | RESPONSE | dbWriteTest");
        //console.log(apiResponseData);


        if (apiResponseData.status === true) {
          if (apiResponseData.data.appStatus === true) {
            
          }

        } else {
         

        }
        
        resolve();
      })
      .catch((error) => {
       
        resolve(error);
      });
    }));
  };
}





/********************************************************************
* Toggle Comment Visibility on Post
********************************************************************/
export function togglePostIdShowComments(body = {}) {
  return (dispatch, getState) => {
    return new Promise(((resolve, reject) => {
      let { postId, } = body;

      console.log("togglePostIdShowComments");

   
      dispatch(toggleCommentVisibilityOnPostsV1R(postId));
      

  
    }));
  };
}
/********************************************************************
* Toggle METRICS Visibility on Post
********************************************************************/
export function togglePostIdShowMetrics(body = {}) {
  return (dispatch, getState) => {
    return new Promise(((resolve, reject) => {
      let { postId, } = body;

      console.log("togglePostIdShowMetrics");

   
      dispatch(toggleMetricsVisibilityOnPostsV1R(postId));
      

  
    }));
  };
}















