import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Route, Navigate } from 'react-router-dom';

class AdminRoute extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
    };
  }

  render() {
    const { permission, path, exact, key, userRoutePermissions } = this.props;
    
    console.log(`Admin Check: ${this.props.isAdmin}`);

    let otherProps = this.props;
    let ComponentToRender = this.props.component;

    if (this.props.isAdmin) {
      return <ComponentToRender path={path} permission={permission} {...otherProps}  />;
    }
    return <Navigate to="/login" />;
  }
}
const mapStateToProps = state => ({
  isAdmin: state.Auth.isAdmin,
});

const mapDispatchToProps = {

};

export default compose(connect(mapStateToProps, mapDispatchToProps))(AdminRoute);
