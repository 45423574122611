

import { setApiActivityR, updateApiActivityR, removeApiActivityR, } from 'Reducers/ApiUI';


export function testAction(body = {}) {
    return (dispatch, getState) => {
      const { hudAccessToken } = getState().Auth;
      return new Promise(((resolve, reject) => {
  
        let { btnData = false,  } = body;

        if (btnData !== false) {
            dispatch(setApiActivityR({btnData}));
        }



        console.log("API | testAction");
        const myTimeout = setTimeout(function() {
            console.log("API | testAction | data returned");
            
            dispatch(updateApiActivityR({btnData, status: true}));
            

        }.bind(this, dispatch), 5000);
  
        
        
        




        const myTimeoutEndOfFunction = setTimeout(function() {
            
            dispatch(removeApiActivityR({btnData}));
            resolve();
        }.bind(this, dispatch), 10000);

      }));
    };
  }