import React, { Component } from 'react';
import asyncComponent from "Helpers/AsyncFunc";
const Routes = [

  {
    path: "chat",
    navPosition: 96,
    component: asyncComponent(() => import("Containers/ZChatTest")),
    permission: [],
    navVisible: false,
    displayicon: 'fas fa-address-book',
    navTitleCollapsed: 'Uber.Digital',
    navTitleExpanded: 'Uber.Digital',
    contentTitle: 'Uber.Digital',
    

    //META tags
    metaTitle: 'Uber.Digital',
    metaDesc: 'Uber.Digital',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',
    //Open Graph tags
    ogTitle: 'Uber.Digital',
    ogSitename: '',
    ogDesc: 'Uber.Digital',
    ogType: 'website',
    ogImage: '',
    ogURL: 'https://www.gamehud.net',

    //linkGroups (URL Site Usage)
    linkGroups: [],
    linkGroupsData: [],

    breadcrumb: [
      {
        url: '/home',
        title: 'home',
      },
    ],
  },

  {
    path: "summary",
    navPosition: 96,
    component: asyncComponent(() => import("Containers/AccountSummaryV1")),
    permission: [],
    navVisible: false,
    displayicon: 'fas fa-address-book',
    navTitleCollapsed: 'Uber.Digital',
    navTitleExpanded: 'Uber.Digital',
    contentTitle: 'Uber.Digital',
    

    //META tags
    metaTitle: 'Uber.Digital',
    metaDesc: 'Uber.Digital',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',
    //Open Graph tags
    ogTitle: 'Uber.Digital',
    ogSitename: '',
    ogDesc: 'Uber.Digital',
    ogType: 'website',
    ogImage: '',
    ogURL: 'https://www.gamehud.net',

    //linkGroups (URL Site Usage)
    linkGroups: [],
    linkGroupsData: [],

    breadcrumb: [
      {
        url: '/home',
        title: 'home',
      },
    ],
  },
  {
    path: "content-feed",
    navPosition: 96,
    component: asyncComponent(() => import("Containers/AccountPostsV1")),
    permission: [],
    navVisible: false,
    displayicon: 'fas fa-address-book',
    navTitleCollapsed: 'Uber.Digital',
    navTitleExpanded: 'Uber.Digital',
    contentTitle: 'Uber.Digital',
    

    //META tags
    metaTitle: 'Uber.Digital',
    metaDesc: 'Uber.Digital',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',
    //Open Graph tags
    ogTitle: 'Uber.Digital',
    ogSitename: '',
    ogDesc: 'Uber.Digital',
    ogType: 'website',
    ogImage: '',
    ogURL: 'https://www.gamehud.net',

    //linkGroups (URL Site Usage)
    linkGroups: [],
    linkGroupsData: [],

    breadcrumb: [
      {
        url: '/home',
        title: 'home',
      },
    ],
  },
  {
    path: "profiles/audiences",
    navPosition: 96,
    component: asyncComponent(() => import("Containers/ProfilesAudiencesV1")),
    permission: [],
    navVisible: false,
    displayicon: 'fas fa-address-book',
    navTitleCollapsed: 'Uber.Digital',
    navTitleExpanded: 'Uber.Digital',
    contentTitle: 'Uber.Digital',
    

    //META tags
    metaTitle: 'Uber.Digital',
    metaDesc: 'Uber.Digital',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',
    //Open Graph tags
    ogTitle: 'Uber.Digital',
    ogSitename: '',
    ogDesc: 'Uber.Digital',
    ogType: 'website',
    ogImage: '',
    ogURL: 'https://www.gamehud.net',

    //linkGroups (URL Site Usage)
    linkGroups: [],
    linkGroupsData: [],

    breadcrumb: [
      {
        url: '/home',
        title: 'home',
      },
    ],
  },
  {
    path: "profiles/audiences/world",
    navPosition: 96,
    component: asyncComponent(() => import("Containers/ProfilesAudiencesV1_world")),
    permission: [],
    navVisible: false,
    displayicon: 'fas fa-address-book',
    navTitleCollapsed: 'Uber.Digital',
    navTitleExpanded: 'Uber.Digital',
    contentTitle: 'Uber.Digital',
    

    //META tags
    metaTitle: 'Uber.Digital',
    metaDesc: 'Uber.Digital',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',
    //Open Graph tags
    ogTitle: 'Uber.Digital',
    ogSitename: '',
    ogDesc: 'Uber.Digital',
    ogType: 'website',
    ogImage: '',
    ogURL: 'https://www.gamehud.net',

    //linkGroups (URL Site Usage)
    linkGroups: [],
    linkGroupsData: [],

    breadcrumb: [
      {
        url: '/home',
        title: 'home',
      },
    ],
  },
  {
    path: "profiles/audiences/pie",
    navPosition: 96,
    component: asyncComponent(() => import("Containers/ProfilesAudiencesV1_pie")),
    permission: [],
    navVisible: false,
    displayicon: 'fas fa-address-book',
    navTitleCollapsed: 'Uber.Digital',
    navTitleExpanded: 'Uber.Digital',
    contentTitle: 'Uber.Digital',
    

    //META tags
    metaTitle: 'Uber.Digital',
    metaDesc: 'Uber.Digital',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',
    //Open Graph tags
    ogTitle: 'Uber.Digital',
    ogSitename: '',
    ogDesc: 'Uber.Digital',
    ogType: 'website',
    ogImage: '',
    ogURL: 'https://www.gamehud.net',

    //linkGroups (URL Site Usage)
    linkGroups: [],
    linkGroupsData: [],

    breadcrumb: [
      {
        url: '/home',
        title: 'home',
      },
    ],
  },
  {
    path: "profiles/engagement",
    navPosition: 96,
    component: asyncComponent(() => import("Containers/ProfilesEngagementsV1")),
    permission: [],
    navVisible: false,
    displayicon: 'fas fa-address-book',
    navTitleCollapsed: 'Uber.Digital',
    navTitleExpanded: 'Uber.Digital',
    contentTitle: 'Uber.Digital',
    

    //META tags
    metaTitle: 'Uber.Digital',
    metaDesc: 'Uber.Digital',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',
    //Open Graph tags
    ogTitle: 'Uber.Digital',
    ogSitename: '',
    ogDesc: 'Uber.Digital',
    ogType: 'website',
    ogImage: '',
    ogURL: 'https://www.gamehud.net',

    //linkGroups (URL Site Usage)
    linkGroups: [],
    linkGroupsData: [],

    breadcrumb: [
      {
        url: '/home',
        title: 'home',
      },
    ],
  },
  {
    path: "profiles/frequency",
    navPosition: 96,
    component: asyncComponent(() => import("Containers/ProfilesFrequencyV1")),
    permission: [],
    navVisible: false,
    displayicon: 'fas fa-address-book',
    navTitleCollapsed: 'Uber.Digital',
    navTitleExpanded: 'Uber.Digital',
    contentTitle: 'Uber.Digital',
    

    //META tags
    metaTitle: 'Uber.Digital',
    metaDesc: 'Uber.Digital',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',
    //Open Graph tags
    ogTitle: 'Uber.Digital',
    ogSitename: '',
    ogDesc: 'Uber.Digital',
    ogType: 'website',
    ogImage: '',
    ogURL: 'https://www.gamehud.net',

    //linkGroups (URL Site Usage)
    linkGroups: [],
    linkGroupsData: [],

    breadcrumb: [
      {
        url: '/home',
        title: 'home',
      },
    ],
  },
  {
    path: "profiles/growth",
    navPosition: 96,
    component: asyncComponent(() => import("Containers/ProfilesGrowthV1")),
    permission: [],
    navVisible: false,
    displayicon: 'fas fa-address-book',
    navTitleCollapsed: 'Uber.Digital',
    navTitleExpanded: 'Uber.Digital',
    contentTitle: 'Uber.Digital',
    

    //META tags
    metaTitle: 'Uber.Digital',
    metaDesc: 'Uber.Digital',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',
    //Open Graph tags
    ogTitle: 'Uber.Digital',
    ogSitename: '',
    ogDesc: 'Uber.Digital',
    ogType: 'website',
    ogImage: '',
    ogURL: 'https://www.gamehud.net',

    //linkGroups (URL Site Usage)
    linkGroups: [],
    linkGroupsData: [],

    breadcrumb: [
      {
        url: '/home',
        title: 'home',
      },
    ],
  },
  {
    path: "profiles/impressions",
    navPosition: 96,
    component: asyncComponent(() => import("Containers/ProfilesImpressionsV1")),
    permission: [],
    navVisible: false,
    displayicon: 'fas fa-address-book',
    navTitleCollapsed: 'Uber.Digital',
    navTitleExpanded: 'Uber.Digital',
    contentTitle: 'Uber.Digital',
    

    //META tags
    metaTitle: 'Uber.Digital',
    metaDesc: 'Uber.Digital',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',
    //Open Graph tags
    ogTitle: 'Uber.Digital',
    ogSitename: '',
    ogDesc: 'Uber.Digital',
    ogType: 'website',
    ogImage: '',
    ogURL: 'https://www.gamehud.net',

    //linkGroups (URL Site Usage)
    linkGroups: [],
    linkGroupsData: [],

    breadcrumb: [
      {
        url: '/home',
        title: 'home',
      },
    ],
  },
  {
    path: "profiles/link-clicks",
    navPosition: 96,
    component: asyncComponent(() => import("Containers/ProfilesLinkClicksV1")),
    permission: [],
    navVisible: false,
    displayicon: 'fas fa-address-book',
    navTitleCollapsed: 'Uber.Digital',
    navTitleExpanded: 'Uber.Digital',
    contentTitle: 'Uber.Digital',
    

    //META tags
    metaTitle: 'Uber.Digital',
    metaDesc: 'Uber.Digital',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',
    //Open Graph tags
    ogTitle: 'Uber.Digital',
    ogSitename: '',
    ogDesc: 'Uber.Digital',
    ogType: 'website',
    ogImage: '',
    ogURL: 'https://www.gamehud.net',

    //linkGroups (URL Site Usage)
    linkGroups: [],
    linkGroupsData: [],

    breadcrumb: [
      {
        url: '/home',
        title: 'home',
      },
    ],
  },
  {
    path: "profiles/messages",
    navPosition: 96,
    component: asyncComponent(() => import("Containers/ProfilesMessagesV1")),
    permission: [],
    navVisible: false,
    displayicon: 'fas fa-address-book',
    navTitleCollapsed: 'Uber.Digital',
    navTitleExpanded: 'Uber.Digital',
    contentTitle: 'Uber.Digital',
    

    //META tags
    metaTitle: 'Uber.Digital',
    metaDesc: 'Uber.Digital',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',
    //Open Graph tags
    ogTitle: 'Uber.Digital',
    ogSitename: '',
    ogDesc: 'Uber.Digital',
    ogType: 'website',
    ogImage: '',
    ogURL: 'https://www.gamehud.net',

    //linkGroups (URL Site Usage)
    linkGroups: [],
    linkGroupsData: [],

    breadcrumb: [
      {
        url: '/home',
        title: 'home',
      },
    ],
  },
  {
    path: "profiles/themes",
    navPosition: 96,
    component: asyncComponent(() => import("Containers/ProfilesThemesV1")),
    permission: [],
    navVisible: false,
    displayicon: 'fas fa-address-book',
    navTitleCollapsed: 'Uber.Digital',
    navTitleExpanded: 'Uber.Digital',
    contentTitle: 'Uber.Digital',
    

    //META tags
    metaTitle: 'Uber.Digital',
    metaDesc: 'Uber.Digital',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',
    //Open Graph tags
    ogTitle: 'Uber.Digital',
    ogSitename: '',
    ogDesc: 'Uber.Digital',
    ogType: 'website',
    ogImage: '',
    ogURL: 'https://www.gamehud.net',

    //linkGroups (URL Site Usage)
    linkGroups: [],
    linkGroupsData: [],

    breadcrumb: [
      {
        url: '/home',
        title: 'home',
      },
    ],
  },
  {
    path: "profiles/video-views",
    navPosition: 96,
    component: asyncComponent(() => import("Containers/ProfilesVideoViewsV1")),
    permission: [],
    navVisible: false,
    displayicon: 'fas fa-address-book',
    navTitleCollapsed: 'Uber.Digital',
    navTitleExpanded: 'Uber.Digital',
    contentTitle: 'Uber.Digital',
    

    //META tags
    metaTitle: 'Uber.Digital',
    metaDesc: 'Uber.Digital',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',
    //Open Graph tags
    ogTitle: 'Uber.Digital',
    ogSitename: '',
    ogDesc: 'Uber.Digital',
    ogType: 'website',
    ogImage: '',
    ogURL: 'https://www.gamehud.net',

    //linkGroups (URL Site Usage)
    linkGroups: [],
    linkGroupsData: [],

    breadcrumb: [
      {
        url: '/home',
        title: 'home',
      },
    ],
  },
  {
    path: "profiles/settings",
    navPosition: 96,
    component: asyncComponent(() => import("Containers/ProfilesSettingsV1")),
    permission: [],
    navVisible: false,
    displayicon: 'fas fa-address-book',
    navTitleCollapsed: 'Uber.Digital',
    navTitleExpanded: 'Uber.Digital',
    contentTitle: 'Uber.Digital',
    

    //META tags
    metaTitle: 'Uber.Digital',
    metaDesc: 'Uber.Digital',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',
    //Open Graph tags
    ogTitle: 'Uber.Digital',
    ogSitename: '',
    ogDesc: 'Uber.Digital',
    ogType: 'website',
    ogImage: '',
    ogURL: 'https://www.gamehud.net',

    //linkGroups (URL Site Usage)
    linkGroups: [],
    linkGroupsData: [],

    breadcrumb: [
      {
        url: '/home',
        title: 'home',
      },
    ],
  },

























  
  {
    path: "grid",
    navPosition: 96,
    component: asyncComponent(() => import("Containers/GridTest")),
    permission: [],
    navVisible: false,
    displayicon: 'fas fa-address-book',
    navTitleCollapsed: 'Uber.Digital',
    navTitleExpanded: 'Uber.Digital',
    contentTitle: 'Uber.Digital',
    

    //META tags
    metaTitle: 'Uber.Digital',
    metaDesc: 'Uber.Digital',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',
    //Open Graph tags
    ogTitle: 'Uber.Digital',
    ogSitename: '',
    ogDesc: 'Uber.Digital',
    ogType: 'website',
    ogImage: '',
    ogURL: 'https://www.gamehud.net',

    //linkGroups (URL Site Usage)
    linkGroups: [],
    linkGroupsData: [],

    breadcrumb: [
      {
        url: '/home',
        title: 'home',
      },
    ],
  },
  
  {
    path: "db",
    navPosition: 96,
    component: asyncComponent(() => import("Containers/DBWriteTest")),
    permission: [],
    navVisible: false,
    displayicon: 'fas fa-address-book',
    navTitleCollapsed: 'Uber.Digital',
    navTitleExpanded: 'Uber.Digital',
    contentTitle: 'Uber.Digital',
    

    //META tags
    metaTitle: 'Uber.Digital',
    metaDesc: 'Uber.Digital',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',
    //Open Graph tags
    ogTitle: 'Uber.Digital',
    ogSitename: '',
    ogDesc: 'Uber.Digital',
    ogType: 'website',
    ogImage: '',
    ogURL: 'https://www.gamehud.net',

    //linkGroups (URL Site Usage)
    linkGroups: [],
    linkGroupsData: [],

    breadcrumb: [
      {
        url: '/home',
        title: 'home',
      },
    ],
  },
  
  {
    path: "profiles",
    navPosition: 96,
    component: asyncComponent(() => import("Containers/AccountProfilesTest")),
    permission: [],
    navVisible: false,
    displayicon: 'fas fa-address-book',
    navTitleCollapsed: 'Uber.Digital',
    navTitleExpanded: 'Uber.Digital',
    contentTitle: 'Uber.Digital',
    

    //META tags
    metaTitle: 'Uber.Digital',
    metaDesc: 'Uber.Digital',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',
    //Open Graph tags
    ogTitle: 'Uber.Digital',
    ogSitename: '',
    ogDesc: 'Uber.Digital',
    ogType: 'website',
    ogImage: '',
    ogURL: 'https://www.gamehud.net',

    //linkGroups (URL Site Usage)
    linkGroups: [],
    linkGroupsData: [],

    breadcrumb: [
      {
        url: '/home',
        title: 'home',
      },
    ],
  },
  

  {
    path: "profiles2",
    navPosition: 96,
    component: asyncComponent(() => import("Containers/AccountProfilesTest2")),
    permission: [],
    navVisible: false,
    displayicon: 'fas fa-address-book',
    navTitleCollapsed: 'Uber.Digital',
    navTitleExpanded: 'Uber.Digital',
    contentTitle: 'Uber.Digital',
    

    //META tags
    metaTitle: 'Uber.Digital',
    metaDesc: 'Uber.Digital',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',
    //Open Graph tags
    ogTitle: 'Uber.Digital',
    ogSitename: '',
    ogDesc: 'Uber.Digital',
    ogType: 'website',
    ogImage: '',
    ogURL: 'https://www.gamehud.net',

    //linkGroups (URL Site Usage)
    linkGroups: [],
    linkGroupsData: [],

    breadcrumb: [
      {
        url: '/home',
        title: 'home',
      },
    ],
  },

  {
    path: "charts1",
    navPosition: 96,
    component: asyncComponent(() => import("Containers/AccountCharts1")),
    permission: [],
    navVisible: false,
    displayicon: 'fas fa-address-book',
    navTitleCollapsed: 'Uber.Digital',
    navTitleExpanded: 'Uber.Digital',
    contentTitle: 'Uber.Digital',
    

    //META tags
    metaTitle: 'Uber.Digital',
    metaDesc: 'Uber.Digital',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',
    //Open Graph tags
    ogTitle: 'Uber.Digital',
    ogSitename: '',
    ogDesc: 'Uber.Digital',
    ogType: 'website',
    ogImage: '',
    ogURL: 'https://www.gamehud.net',

    //linkGroups (URL Site Usage)
    linkGroups: [],
    linkGroupsData: [],

    breadcrumb: [
      {
        url: '/home',
        title: 'home',
      },
    ],
  },
  
  
  /******** TESTING ************************************************************ */
  
  {
    path: "connect",
    navPosition: 96,
    component: asyncComponent(() => import("Containers/AccountConnect_facebook")),
    permission: [],
    navVisible: false,
    displayicon: 'fas fa-address-book',
    navTitleCollapsed: 'Uber.Digital',
    navTitleExpanded: 'Uber.Digital',
    contentTitle: 'Uber.Digital',
    

    //META tags
    metaTitle: 'Uber.Digital',
    metaDesc: 'Uber.Digital',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',
    //Open Graph tags
    ogTitle: 'Uber.Digital',
    ogSitename: '',
    ogDesc: 'Uber.Digital',
    ogType: 'website',
    ogImage: '',
    ogURL: 'https://www.gamehud.net',

    //linkGroups (URL Site Usage)
    linkGroups: [],
    linkGroupsData: [],

    breadcrumb: [
      {
        url: '/home',
        title: 'home',
      },
    ],
  },

  {
    path: "charts",
    navPosition: 96,
    component: asyncComponent(() => import("Containers/AccountChartsTest")),
    permission: [],
    navVisible: false,
    displayicon: 'fas fa-address-book',
    navTitleCollapsed: 'Uber.Digital',
    navTitleExpanded: 'Uber.Digital',
    contentTitle: 'Uber.Digital',
    

    //META tags
    metaTitle: 'Uber.Digital',
    metaDesc: 'Uber.Digital',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',
    //Open Graph tags
    ogTitle: 'Uber.Digital',
    ogSitename: '',
    ogDesc: 'Uber.Digital',
    ogType: 'website',
    ogImage: '',
    ogURL: 'https://www.gamehud.net',

    //linkGroups (URL Site Usage)
    linkGroups: [],
    linkGroupsData: [],

    breadcrumb: [
      {
        url: '/home',
        title: 'home',
      },
    ],
  },


  

  /******** FINISH - TESTING ************************************************************ */

  {
    path: "account",
    navPosition: 96,
    component: asyncComponent(() => import("Containers/AccountV1")),
    permission: [],
    navVisible: false,
    displayicon: 'fas fa-address-book',
    navTitleCollapsed: 'Uber.Digital',
    navTitleExpanded: 'Uber.Digital',
    contentTitle: 'Uber.Digital',
    

    //META tags
    metaTitle: 'Uber.Digital',
    metaDesc: 'Uber.Digital',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',
    //Open Graph tags
    ogTitle: 'Uber.Digital',
    ogSitename: '',
    ogDesc: 'Uber.Digital',
    ogType: 'website',
    ogImage: '',
    ogURL: 'https://www.gamehud.net',

    //linkGroups (URL Site Usage)
    linkGroups: [],
    linkGroupsData: [],

    breadcrumb: [
      {
        url: '/home',
        title: 'home',
      },
    ],
  },
  {
    path: "account-settings",
    navPosition: 96,
    component: asyncComponent(() => import("Containers/AccountSettingsV1")),
    permission: [],
    navVisible: false,
    displayicon: 'fas fa-address-book',
    navTitleCollapsed: 'Uber.Digital',
    navTitleExpanded: 'Uber.Digital',
    contentTitle: 'Uber.Digital',
    

    //META tags
    metaTitle: 'Uber.Digital',
    metaDesc: 'Uber.Digital',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',
    //Open Graph tags
    ogTitle: 'Uber.Digital',
    ogSitename: '',
    ogDesc: 'Uber.Digital',
    ogType: 'website',
    ogImage: '',
    ogURL: 'https://www.gamehud.net',

    //linkGroups (URL Site Usage)
    linkGroups: [],
    linkGroupsData: [],

    breadcrumb: [
      {
        url: '/home',
        title: 'home',
      },
    ],
  },
  {
    path: "subscribe",
    navPosition: 96,
    component: asyncComponent(() => import("Containers/Account_subscribeV1")),
    permission: [],
    navVisible: false,
    displayicon: 'fas fa-address-book',
    navTitleCollapsed: 'Uber.Digital',
    navTitleExpanded: 'Uber.Digital',
    contentTitle: 'Uber.Digital',
    

    //META tags
    metaTitle: 'Uber.Digital',
    metaDesc: 'Uber.Digital',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',
    //Open Graph tags
    ogTitle: 'Uber.Digital',
    ogSitename: '',
    ogDesc: 'Uber.Digital',
    ogType: 'website',
    ogImage: '',
    ogURL: 'https://www.gamehud.net',

    //linkGroups (URL Site Usage)
    linkGroups: [],
    linkGroupsData: [],

    breadcrumb: [
      {
        url: '/home',
        title: 'home',
      },
    ],
  },
  {
    path: "subscribe/status/:subscriptionId",
    navPosition: 96,
    component: asyncComponent(() => import("Containers/Account_subscribeStatusV1")),
    permission: [],
    navVisible: false,
    displayicon: 'fas fa-address-book',
    navTitleCollapsed: 'Uber.Digital',
    navTitleExpanded: 'Uber.Digital',
    contentTitle: 'Uber.Digital',
    

    //META tags
    metaTitle: 'Uber.Digital',
    metaDesc: 'Uber.Digital',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',
    //Open Graph tags
    ogTitle: 'Uber.Digital',
    ogSitename: '',
    ogDesc: 'Uber.Digital',
    ogType: 'website',
    ogImage: '',
    ogURL: 'https://www.gamehud.net',

    //linkGroups (URL Site Usage)
    linkGroups: [],
    linkGroupsData: [],

    breadcrumb: [
      {
        url: '/home',
        title: 'home',
      },
    ],
  },
  {
    path: "subscribe/status/:subscriptionId/:rs",
    navPosition: 96,
    component: asyncComponent(() => import("Containers/Account_subscribeStatusV1")),
    permission: [],
    navVisible: false,
    displayicon: 'fas fa-address-book',
    navTitleCollapsed: 'Uber.Digital',
    navTitleExpanded: 'Uber.Digital',
    contentTitle: 'Uber.Digital',
    

    //META tags
    metaTitle: 'Uber.Digital',
    metaDesc: 'Uber.Digital',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',
    //Open Graph tags
    ogTitle: 'Uber.Digital',
    ogSitename: '',
    ogDesc: 'Uber.Digital',
    ogType: 'website',
    ogImage: '',
    ogURL: 'https://www.gamehud.net',

    //linkGroups (URL Site Usage)
    linkGroups: [],
    linkGroupsData: [],

    breadcrumb: [
      {
        url: '/home',
        title: 'home',
      },
    ],
  },
  {
    path: "subscribe/status/:subscriptionId/:rs/:ts",
    navPosition: 96,
    component: asyncComponent(() => import("Containers/Account_subscribeStatusV1")),
    permission: [],
    navVisible: false,
    displayicon: 'fas fa-address-book',
    navTitleCollapsed: 'Uber.Digital',
    navTitleExpanded: 'Uber.Digital',
    contentTitle: 'Uber.Digital',
    

    //META tags
    metaTitle: 'Uber.Digital',
    metaDesc: 'Uber.Digital',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',
    //Open Graph tags
    ogTitle: 'Uber.Digital',
    ogSitename: '',
    ogDesc: 'Uber.Digital',
    ogType: 'website',
    ogImage: '',
    ogURL: 'https://www.gamehud.net',

    //linkGroups (URL Site Usage)
    linkGroups: [],
    linkGroupsData: [],

    breadcrumb: [
      {
        url: '/home',
        title: 'home',
      },
    ],
  },
  {
    path: "subscription/detail",
    navPosition: 96,
    component: asyncComponent(() => import("Containers/Account_subscriptionDetailV1")),
    permission: [],
    navVisible: false,
    displayicon: 'fas fa-address-book',
    navTitleCollapsed: 'Uber.Digital',
    navTitleExpanded: 'Uber.Digital',
    contentTitle: 'Uber.Digital',
    

    //META tags
    metaTitle: 'Uber.Digital',
    metaDesc: 'Uber.Digital',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',
    //Open Graph tags
    ogTitle: 'Uber.Digital',
    ogSitename: '',
    ogDesc: 'Uber.Digital',
    ogType: 'website',
    ogImage: '',
    ogURL: 'https://www.gamehud.net',

    //linkGroups (URL Site Usage)
    linkGroups: [],
    linkGroupsData: [],

    breadcrumb: [
      {
        url: '/home',
        title: 'home',
      },
    ],
  },
  
  {
    path: "support",
    navPosition: 96,
    component: asyncComponent(() => import("Containers/AccountSupportV1")),
    permission: [],
    navVisible: false,
    displayicon: 'fas fa-address-book',
    navTitleCollapsed: 'Uber.Digital',
    navTitleExpanded: 'Uber.Digital',
    contentTitle: 'Uber.Digital',
    

    //META tags
    metaTitle: 'Uber.Digital',
    metaDesc: 'Uber.Digital',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',
    //Open Graph tags
    ogTitle: 'Uber.Digital',
    ogSitename: '',
    ogDesc: 'Uber.Digital',
    ogType: 'website',
    ogImage: '',
    ogURL: 'https://www.gamehud.net',

    //linkGroups (URL Site Usage)
    linkGroups: [],
    linkGroupsData: [],

    breadcrumb: [
      {
        url: '/home',
        title: 'home',
      },
    ],
  },
  {
    path: "signup",
    navPosition: 96,
    component: asyncComponent(() => import("Containers/AccountSignupV1")),
    permission: [],
    navVisible: false,
    displayicon: 'fas fa-address-book',
    navTitleCollapsed: 'Uber.Digital',
    navTitleExpanded: 'Uber.Digital',
    contentTitle: 'Uber.Digital',
    

    //META tags
    metaTitle: 'Uber.Digital',
    metaDesc: 'Uber.Digital',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',
    //Open Graph tags
    ogTitle: 'Uber.Digital',
    ogSitename: '',
    ogDesc: 'Uber.Digital',
    ogType: 'website',
    ogImage: '',
    ogURL: 'https://www.gamehud.net',

    //linkGroups (URL Site Usage)
    linkGroups: [],
    linkGroupsData: [],

    breadcrumb: [
      {
        url: '/home',
        title: 'home',
      },
    ],
  },
  {
    path: "login",
    navPosition: 96,
    component: asyncComponent(() => import("Containers/AccountLoginV1")),
    permission: [],
    navVisible: false,
    displayicon: 'fas fa-address-book',
    navTitleCollapsed: 'Uber.Digital',
    navTitleExpanded: 'Uber.Digital',
    contentTitle: 'Uber.Digital',
    

    //META tags
    metaTitle: 'Uber.Digital',
    metaDesc: 'Uber.Digital',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',
    //Open Graph tags
    ogTitle: 'Uber.Digital',
    ogSitename: '',
    ogDesc: 'Uber.Digital',
    ogType: 'website',
    ogImage: '',
    ogURL: 'https://www.gamehud.net',

    //linkGroups (URL Site Usage)
    linkGroups: [],
    linkGroupsData: [],

    breadcrumb: [
      {
        url: '/home',
        title: 'home',
      },
    ],
  },
  {
    path: "logout",
    navPosition: 96,
    component: asyncComponent(() => import("Containers/AccountLogoutV1")),
    permission: [],
    navVisible: false,
    displayicon: 'fas fa-address-book',
    navTitleCollapsed: 'Uber.Digital',
    navTitleExpanded: 'Uber.Digital',
    contentTitle: 'Uber.Digital',
    

    //META tags
    metaTitle: 'Uber.Digital',
    metaDesc: 'Uber.Digital',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',
    //Open Graph tags
    ogTitle: 'Uber.Digital',
    ogSitename: '',
    ogDesc: 'Uber.Digital',
    ogType: 'website',
    ogImage: '',
    ogURL: 'https://www.gamehud.net',

    //linkGroups (URL Site Usage)
    linkGroups: [],
    linkGroupsData: [],

    breadcrumb: [
      {
        url: '/home',
        title: 'home',
      },
    ],
  },
  {
    path: "password-reset/:passwordRequestId/:verificationCode",
    navPosition: 96,
    component: asyncComponent(() => import("Containers/AdminUserPasswordResetEmail")),
    permission: [],
    navVisible: false,
    displayicon: 'fas fa-address-book',
    navTitleCollapsed: 'Uber.Digital',
    navTitleExpanded: 'Uber.Digital',
    contentTitle: 'Uber.Digital',
    

    //META tags
    metaTitle: 'Uber.Digital',
    metaDesc: 'Uber.Digital',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',
    //Open Graph tags
    ogTitle: 'Uber.Digital',
    ogSitename: '',
    ogDesc: 'Uber.Digital',
    ogType: 'website',
    ogImage: '',
    ogURL: 'https://www.gamehud.net',

    //linkGroups (URL Site Usage)
    linkGroups: [],
    linkGroupsData: [],

    breadcrumb: [
      {
        url: '/home',
        title: 'home',
      },
    ],
  },


  

  
  









  
  {
    path: "user-identity-verification/email/:userId/:signupRequestId/:verificationCode",
    navPosition: 100,
    component: asyncComponent(() => import("Containers/AdminUserIdentityVerificationEmail")),
    permission: [],
    navVisible: false,
    displayicon: 'far fa-address-book',
    navTitleCollapsed: 'Email Verification',
    navTitleExpanded: 'Email Verification',
    contentTitle: 'Email Verification',

    //META tags
    metaTitle: 'Uber.Digital',
    metaDesc: 'Uber.Digital',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',
    //Open Graph tags
    ogTitle: 'Uber.Digital',
    ogSitename: '',
    ogDesc: 'Uber.Digital',
    ogType: 'website',
    ogImage: '',
    ogURL: '',

    //linkGroups (URL Site Usage)
    linkGroups: ['adminpagelisting'],
    linkGroupsData: [],

    breadcrumb: [
      {
        url: '/home',
        title: 'Home',
      },
    ],

  },
  



  




  /*********************************************************************************************************************
   *********************************************************************************************************************
   *********************************************************************************************************************
   * PUBLIC ROUTES     |     PUBLIC ROUTES     |     PUBLIC ROUTES     |     PUBLIC ROUTES     |     PUBLIC ROUTES     |
   *********************************************************************************************************************
   *********************************************************************************************************************
   *********************************************************************************************************************/

  {
    path: "",
    navPosition: 96,
    component: asyncComponent(() => import("Containers/AccountV1")),
    permission: [],
    navVisible: false,
    displayicon: 'fas fa-address-book',
    navTitleCollapsed: 'Uber.Digital',
    navTitleExpanded: 'Uber.Digital',
    contentTitle: 'Uber.Digital',
    

    //META tags
    metaTitle: 'Uber.Digital',
    metaDesc: 'Uber.Digital',
    metaCanonical: '',
    metaRobots: 'index,follow',
    //Open Graph tags
    ogTitle: 'Uber.Digital',
    ogSitename: '',
    ogDesc: 'Uber.Digital',
    ogType: 'website',
    ogImage: '',
    ogURL: '',

    //linkGroups (URL Site Usage)
    linkGroups: [],
    linkGroupsData: [],

    breadcrumb: [
      {
        url: '/home',
        title: 'home',
      },
    ],
  },
  
  

  {
    path: "legal",
    navPosition: 100,
    component: asyncComponent(() => import("Containers/Public_LegalV1")),
    permission: [],
    navVisible: false,
    displayicon: 'far fa-address-book',
    navTitleCollapsed: '',
    navTitleExpanded: '',
    contentTitle: '',

    //META tags
    metaTitle: '',
    metaDesc: '',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',

    //linkGroups (URL Site Usage)
    linkGroups: ['adminpagelisting'],
    linkGroupsData: [],

    breadcrumb: [
      {
        url: '/home',
        title: 'Home',
      },
    ],

    rightcrumb: [
    ],

    navDropDown: false,
    navDropDownItems: [],
    navDropDownUserState: [],

  },
  {
    path: "privacy",
    navPosition: 100,
    component: asyncComponent(() => import("Containers/Public_PrivacyPolicyV1")),
    permission: [],
    navVisible: false,
    displayicon: 'far fa-address-book',
    navTitleCollapsed: '',
    navTitleExpanded: '',
    contentTitle: '',

    //META tags
    metaTitle: '',
    metaDesc: '',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',

    //linkGroups (URL Site Usage)
    linkGroups: ['adminpagelisting'],
    linkGroupsData: [],

    breadcrumb: [
      {
        url: '/home',
        title: 'Home',
      },
    ],

    rightcrumb: [
    ],

    navDropDown: false,
    navDropDownItems: [],
    navDropDownUserState: [],

  },
  {
    path: "terms-of-use",
    navPosition: 100,
    component: asyncComponent(() => import("Containers/Public_TermsOfUseV1")),
    permission: [],
    navVisible: false,
    displayicon: 'far fa-address-book',
    navTitleCollapsed: '',
    navTitleExpanded: '',
    contentTitle: '',

    //META tags
    metaTitle: '',
    metaDesc: '',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',

    //linkGroups (URL Site Usage)
    linkGroups: ['adminpagelisting'],
    linkGroupsData: [],

    breadcrumb: [
      {
        url: '/home',
        title: 'Home',
      },
    ],

    rightcrumb: [
    ],

    navDropDown: false,
    navDropDownItems: [],
    navDropDownUserState: [],

  },
  {
    path: "security",
    navPosition: 100,
    component: asyncComponent(() => import("Containers/Public_OurSecurityV1")),
    permission: [],
    navVisible: false,
    displayicon: 'far fa-address-book',
    navTitleCollapsed: '',
    navTitleExpanded: '',
    contentTitle: '',

    //META tags
    metaTitle: '',
    metaDesc: '',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',

    //linkGroups (URL Site Usage)
    linkGroups: ['adminpagelisting'],
    linkGroupsData: [],

    breadcrumb: [
      {
        url: '/home',
        title: 'Home',
      },
    ],

    rightcrumb: [
    ],

    navDropDown: false,
    navDropDownItems: [],
    navDropDownUserState: [],

  },


  
  
















  





  {
    path: "permission-denied",
    navPosition: 100,
    component: asyncComponent(() => import("Containers/RootPermissionDenied")),
    permission: [],
    navVisible: false,
    displayicon: 'far fa-address-book',
    navTitleCollapsed: 'Permission Denied',
    navTitleExpanded: 'Permission Denied',
    contentTitle: 'Permission Denied',

    //META tags
    metaTitle: 'GuardPoint',
    metaDesc: 'Permission Denied',
    metaCanonical: '',
    metaRobots: 'noindex,nofollow',

    //linkGroups (URL Site Usage)
    linkGroups: ['adminpagelisting'],
    linkGroupsData: [],

    breadcrumb: [
      {
        url: '/home',
        title: 'Home',
      },
    ],

    rightcrumb: [
    ],

    navDropDown: false,
    navDropDownItems: [],
    navDropDownUserState: [],

  },
  
  


  
]
export default Routes;


