import { combineReducers } from 'redux';


import AppSlice from './AppSlice';

import authSlice from './Auth';
import gameSlice from './Game';
import portalSlice from './Portal';
import apiUiSlice from './ApiUI';

import analyticsSlice from './Analytics';
import userInterfaceSlice from './UserInterface';




import Socket from './Socket';
import UI from './UI';
import ApiActions from './ApiActions';
import CommonActions from './CommonActions';

import StarGlass from './StarGlass';
import GameHudAuth from './GameHudAuth';


const appReducer = combineReducers({
  AppSlice,   //new style
  Auth: authSlice,  
  Analytics: analyticsSlice,
  UserInterface: userInterfaceSlice,
  Game: gameSlice, 

  Portal: portalSlice, 
  ApiUI: apiUiSlice, 

  


  StarGlass,  //NEW
  GameHudAuth,  //NEW


  Socket,
  ApiActions,
  UI,
  CommonActions,


});


/** ********************************************************
 * rootReducer. This is where we can reset/clear state.
 * Otherwise, we return the appReducer which creates all
 * the state for the components requried.
********************************************************** */

const rootReducer = (state, action) => {
  switch (action.type) {

    case 'RESET_STATE':
    {
      state = undefined;
      return state;
    }
    
    case 'REMOVE_STATE_STRUCTURE': 
    {
      const { App, } = state;
      state = { App };
      return state;
    }  

    default:
      return appReducer(state, action);
  }
};
export default rootReducer;
