/** UI ACTIONS ** */

import { v1 as uuidv1 } from 'uuid';


//Store
import store from 'Store/ConfigureStore';

export const authStatusFalse_UI = () => ({
  type: 'SET_IS_AUTHENTICATED',
  status: false,
});
export const addGlobalToastMessage = data => ({
  type: 'ADD_GLOBAL_TOAST_MESSAGE',
  data,
});

export const removeGlobalToastMessage = data => ({
  type: 'REMOVE_GLOBAL_TOAST_MESSAGE',
  data,
});

/*
export const updateWindowSize = windowSize => ({
  type: 'SET_WINDOW_SIZE',
  windowSize,
});
*/




export const setSidebarState = sidebarOpen => ({
  type: 'SET_SIDEBAR_OPEN_STATE',
  sidebarOpen,
});

export const setSubSidebarState = sidebarOpen => ({
  type: 'SET_SUB_SIDEBAR_OPEN_STATE',
  sidebarOpen,
});

export const setAppbarHeight = setAppbarHeight => ({
  type: 'SET_APP_BAR_HEIGHT',
  setAppbarHeight,
});






export function updateUIApiActivity(body = {}) {
  return (dispatch, getState) => {
    dispatch(updateUIApiActivityAction(body));
    // Set a timeout to update
    setTimeout(() => {
      dispatch(removeUIApiActivityAction(body));
    }, 10000);
  };
}

//Inbound from Containers & Components
export const setUIApiActivity = obj => ({
  type: 'SET_UI_API_ACTIVITY',
  obj,
});
export const updateUIApiActivityAction = obj => ({
  type: 'UPDATE_UI_API_ACTIVITY',
  obj,
});
export const removeUIApiActivityAction = obj => ({
  type: 'REMOVE_UI_API_ACTIVITY',
  obj,
});




export const removeApiActiveEvent = data => ({
  type: 'REMOVE_API_ACTIVE_EVENT',
  data,
});
export const cleanApiActiveEvents = () => ({
  type: 'CLEAN_API_ACTIVE_EVENTS',
});




///////////////////////////////////////////////////
// UPDATE THE STATE OF API ACTIONS ON A TIMER INTERVAL
///////////////////////////////////////////////////
/*
let uiApiStateTimer = setInterval(function() {
  


   console.log("store");
   console.log(store);






  const { ApiActive, ApiInProgress } = store.getState().ApiActions;



  
  let currentTime = Date.now();
  let checkInterval = 20; //20 seconds

  //console.log(`Update API ACTIONS state: ${ApiActive.length} | Every 60 seconds`);

  ///////// BUSINESS DATASETS //////////
  let ApiActiveItems = 0;
  try {
    ApiActiveItems = ApiActive.length;
  } catch (e) {
    ApiActiveItems = 0;
  }

  if (ApiActiveItems > 0) {
    console.log("Process ApiActive");

    for (let x = 0; x < ApiActive.length; x++) {

      if (typeof ApiActive[x] === 'object' && ApiActive[x] !== null) {
        let ApiActiveTimestamp = ApiActive[x].timestamp;
        let ApiActiveTimestampDateObj = new Date(ApiActiveTimestamp);
        let ApiActiveTimestampFutureDateObj = new Date(ApiActiveTimestampDateObj.getTime() + checkInterval*1000);
          
        let updateSet = {
          id: ApiActive[x].id,
        }

        //console.log(`TO REMOVE API ACTION?: ${currentTime} > ${ApiActiveTimestampFutureDateObj.valueOf()}`);
        if (currentTime > ApiActiveTimestampFutureDateObj.valueOf()) {
          //console.log("remove this item");
          store.dispatch(removeApiActiveEvent(updateSet));
        }
      } else {
        store.dispatch(cleanApiActiveEvents());
      }

    }
  } else {
  }
  


}, 60000); //10 sec    //30000 = 30 seconds
*/



























//Inbound from Actions
class ApiUI {

  static setUIApiActivity = obj => ({
    type: 'SET_UI_API_ACTIVITY',
    obj,
  });
  static updateUIApiActivityAction = obj => ({
    type: 'UPDATE_UI_API_ACTIVITY',
    obj,
  });
  static removeUIApiActivityAction = obj => ({
    type: 'REMOVE_UI_API_ACTIVITY',
    obj,
  });
  static updateUIApiActivity(body = {}) {
    return (dispatch, getState) => {
      dispatch(ApiUI.updateUIApiActivityAction(body));
      // Set a timeout to update
      setTimeout(() => {
        dispatch(ApiUI.removeUIApiActivityAction(body));
      }, 10000);
    };
  }
  
}


export const setUiRadioOptions = obj => ({
  type: 'SET_UI_RADIO_OPTION',
  obj,
});
export const setUiCheckboxOptions = obj => ({
  type: 'SET_UI_CHECKBOX_OPTION',
  obj,
});
export const setUiToggleOptions = obj => ({
  type: 'SET_UI_TOGGLE_OPTION',
  obj,
});
export const setUiTabOptions = obj => ({
  type: 'SET_UI_TAB_OPTION',
  obj,
});


export default ApiUI;
