import React from 'react';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { Route, Navigate } from 'react-router-dom';

console.log("Loading CommonTransit Component");


import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import { removeGlobalToastMessage } from 'Actions';

/////////////////////////////////////////////////
// TOAST MESSAGES FOR USER
/////////////////////////////////////////////////
toast.configure();


/*****************************************************
 * COMMON TRANSIT
 * Once a route authorisation is completed, 
 * common transit is loaded which is redux connected.
 * This will provide common functionality across all pages
 * such as "Toast Notifications"
 * 
 * 
 * 
 *****************************************************/

class CommonTransit extends React.Component {
  constructor(props) {
    super(props);
    this.toastGlobalMessaging = this.toastGlobalMessaging.bind(this);
    this.state = {
      uiToastMessages: [],
    };
    
  }

  componentDidMount() {
    const thisComponent = this.constructor.name;
    //console.log(`Component: ${thisComponent}`);
  }

  componentDidUpdate(prevProps, prevState) {
    //console.log("componentDidUpdate - Root/commonTransit");
    if (JSON.stringify(prevProps.uiToastMessages) !== JSON.stringify(this.props.uiToastMessages)) {
      if (this.props.uiToastMessages !== prevState.uiToastMessages) {
        //console.log("uiToastMessages !== local state");
        this.setState({
          uiToastMessages: this.props.uiToastMessages,
        });
        this.toastGlobalMessaging(this.props.uiToastMessages);
      } else {
        //console.log("uiToastMessages === local state")
      }
    } else {
      //console.log("uiToastMessage PrevProps === props");
    }

  }


  /////////////////////////////////////////////////
  // TOAST MESSAGES FOR USER
  /////////////////////////////////////////////////
  // Global toast notifications are managed here
  toastGlobalMessaging(uiToastMessages) {
    //console.log("toastGlobalMessaging");
    //console.log(uiToastMessages);
 
    try {
      for (let x = 0; x < uiToastMessages.length; x++) {
        //console.log(uiToastMessages[x].id);
        //console.log(uiToastMessages[x].message);
        //console.log(uiToastMessages[x].type);
        //console.log(uiToastMessages[x].seen);
   

       if (uiToastMessages[x].seen === false) {
         //Show toast message, then mark as seen true and then delete to make sure it doesn't come up again.
   
         let toastClassName = '';
         let toastProgressClassName = '';
         if (uiToastMessages[x].type === 'success') {
           toastClassName = 'toastSuccess';
           toastProgressClassName = 'toastSuccessProgress';
   
           toast.success(uiToastMessages[x].message, {
             closeOnClick:true,
             position: toast.POSITION.TOP_RIGHT,
             autoClose: 20000,
             className: toastClassName,
             bodyClassName: "grow-font-size",
             progressClassName: toastProgressClassName
           });
   
         } else if (uiToastMessages[x].type === 'warn') {
           toastClassName = 'toastWarning';
           toastProgressClassName = 'toastWarningProgress';
   
           toast.warn(uiToastMessages[x].message, {
             closeOnClick:true,
             position: toast.POSITION.TOP_RIGHT,
             autoClose: 20000,
             className: toastClassName,
             bodyClassName: "grow-font-size",
             progressClassName: toastProgressClassName
           });
   
         } else if (uiToastMessages[x].type === 'error') {
           toastClassName = 'toastError';
           toastProgressClassName = 'toastErrorProgress';
   
           toast.error(uiToastMessages[x].message, {
             closeOnClick:true,
             position: toast.POSITION.TOP_RIGHT,
             autoClose: 20000,
             className: toastClassName,
             bodyClassName: "grow-font-size",
             progressClassName: toastProgressClassName
           });
   
         } else if (uiToastMessages[x].type === 'info') {
           toastClassName = 'toastInfo';
           toastProgressClassName = 'toastInfoProgress';
   
           toast.info(uiToastMessages[x].message, {
             closeOnClick:true,
             position: toast.POSITION.TOP_RIGHT,
             autoClose: 20000,
             className: toastClassName,
             bodyClassName: "grow-font-size",
             progressClassName: toastProgressClassName
           });
   
         } else if (uiToastMessages[x].type === 'default') {
           toastClassName = 'toastDefault';
           toastProgressClassName = 'toastDefaultProgress';
   
           toast(uiToastMessages[x].message, {
             closeOnClick:true,
             position: toast.POSITION.TOP_RIGHT,
             autoClose: 20000,
             className: toastClassName,
             bodyClassName: "grow-font-size",
             progressClassName: toastProgressClassName
           });
   
         }

         //console.log(uiToastMessages[x].id);
   
         let data = {
           id: uiToastMessages[x].id,
         }
         this.props.removeGlobalToastMessage(data);
         
   
         //this.props.ACTION_REMOVE_THIS_TOAST_MESSAGE (Reference the ID for removal of that item in case there are multipels)
       }
      }
    } catch (e) {
      console.warn("Error reading uiToastMessages");
      console.warn(e);
    }
     
  }
  

  render() {
    const { exact, key, path, 
       
    userRoutePermissions, userHasRoutePermission, userBusinessRoutePermissions } = this.props;

    console.log(`====== Common Transit ======`);

    const DynamicRouteComponent = this.props.component;

    return (
      <Route render={(props) => <DynamicRouteComponent 
        userHasRoutePermission={userHasRoutePermission} 
        userBusinessRoutePermissions={userBusinessRoutePermissions} 
        {...props} /> } path={path} />
    )
    
  }
}


const mapStateToProps = (state, ownProps) => {
  let uiToastMessages = [];
  try {
    uiToastMessages = state.UI.uiToastMessages.filter(x => x.seen === false);
  } catch (e) {
    console.warn("uiToastMessages ERROR");
    console.warn(e);
  }

  return {
    uiToastMessages: Object.assign([], state.UI.uiToastMessages),
  };
};

const mapDispatchToProps = {
  removeGlobalToastMessage,
};

//Controls mapStateToProps firing
const mergeProps = (next, prev) => {
  return (
    prev.UI.uiToastMessages === next.UI.uiToastMessages
  );
}

export default compose(connect(mapStateToProps, mapDispatchToProps, null, mergeProps))(CommonTransit);
