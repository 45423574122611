import React, { Component } from "react";
const KeyMappings = [
  /**********************************************************************************************************
   * ********************************************************************************************************
   * *****  LOCAL COMPONENTS (MANAGING STATE) 
   * ********************************************************************************************************
   * *******************************************************************************************************/
   {
    showInGameControls: false,
    controlName: "powerTriangle",
    defaultGameKey: ["powerTriangle"],
    defaultGameKeyLookup: ["powerTriangle"],
    defaultGameKeyEvent: "press",
    gameKeyText: "",
    gameKeyDefaultKeyBinding: true,
    gameKeyName: "",
    buttonName: "",
    textHelp: "",
    style: "",
    controlType: "",
    category: "",
    faIcon: "",
  },

  /**********************************************************************************************************
   * ********************************************************************************************************
   * *****  GLOBAL 
   * ********************************************************************************************************
   * *******************************************************************************************************/
  {
    controlName: "mobi",
    defaultGameKey: ["F1"],
    defaultGameKeyLookup: ["F1"],
    defaultGameKeyEvent: "press",
    gameKeyText: "F1",
    gameKeyDefaultKeyBinding: true,
    gameKeyName: "Mobi",
    buttonName: "Mobi",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "person",
    faIcon: "fas fa-grip-horizontal",
  },
  {
    controlName: "starMap",
    defaultGameKey: ["F2"],
    defaultGameKeyLookup: ["F2"],
    defaultGameKeyEvent: "press",
    gameKeyText: "F2",
    gameKeyDefaultKeyBinding: true,
    gameKeyName: "Star Map",
    buttonName: "StarMap",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "person",
    faIcon: "fas fa-globe-asia",
  },
  {
    controlName: "atc",
    defaultGameKey: ["left", "alt", "left", "shift", "n"],
    defaultGameKeyLookup: ["left", "alt", "left", "shift", "n"],
    defaultGameKeyEvent: "press",
    gameKeyText: "Left Alt + Left Shift + N",
    gameKeyDefaultKeyBinding: false,
    gameKeyName: "ATC",
    buttonName: "ATC",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "person",
    faIcon: "fas fa-bullhorn",
  },
  {
    controlName: "globalChat",
    defaultGameKey: ["F12"],
    defaultGameKeyLookup: ["f12"],
    defaultGameKeyEvent: "press",
    gameKeyText: "F12",
    gameKeyDefaultKeyBinding: true,
    gameKeyName: "Global Chat",
    buttonName: "Chat",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "person",
    faIcon: "far fa-comment",
  },
  {
    controlName: "groupChat",
    defaultGameKey: ["tab"],
    defaultGameKeyLookup: ["tab"],
    defaultGameKeyEvent: "press",
    gameKeyText: "TAB",
    gameKeyDefaultKeyBinding: false,
    gameKeyName: "Group Chat",
    buttonName: "Group Chat",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "person",
    faIcon: "fas fa-bullhorn",
  },


  /**********************************************************************************************************
   * ********************************************************************************************************
   * *****  FLIGHT 
   * ********************************************************************************************************
   * *******************************************************************************************************/
   {
    controlName: "flightPower",
    defaultGameKey: ["u"],
    defaultGameKeyLookup: ["u"],
    defaultGameKeyEvent: "press",
    gameKeyText: "U",
    gameKeyDefaultKeyBinding: true,
    gameKeyName: "Power",
    buttonName: "PWR",
    textHelp: "",
    style: "toggle-primary",
    controlType: "button",
    category: "flight",
  },
  {
    controlName: "flightShields",
    defaultGameKey: ["o"],
    defaultGameKeyLookup: ["o"],
    defaultGameKeyEvent: "press",
    gameKeyText: "O",
    gameKeyDefaultKeyBinding: true,
    gameKeyName: "Shields",
    buttonName: "SHLD",
    textHelp: "",
    style: "toggle-primary",
    controlType: "button",
    category: "flight",
  },
  {
    controlName: "flightThrust",
    defaultGameKey: ["i"],
    defaultGameKeyLookup: ["i"],
    defaultGameKeyEvent: "press",
    gameKeyText: "I",
    gameKeyDefaultKeyBinding: true,
    gameKeyName: "Thrust",
    buttonName: "THRST",
    textHelp: "",
    style: "toggle-primary",
    controlType: "button",
    category: "flight",
  },
  {
    controlName: "flightWeapons",
    defaultGameKey: [{k: 'p', d: 10}],
    defaultGameKeyLookup: ["p"],
    defaultGameKeyEvent: "press",
    gameKeyText: "P",
    gameKeyDefaultKeyBinding: true,
    gameKeyName: "Weapons",
    buttonName: "WEP",
    textHelp: "",
    style: "toggle-primary",
    controlType: "button",
    category: "flight",
  },
  {
    controlName: "flightReady",
    defaultGameKey: ["r"],
    defaultGameKeyLookup: ["r"],
    defaultGameKeyEvent: "press",
    gameKeyText: "R",
    gameKeyDefaultKeyBinding: true,
    gameKeyName: "Flight Ready",
    buttonName: "Flight Ready",
    textHelp: "",
    style: "primary",
    controlType: "button",
    category: "flight",
  },
  {
    controlName: "exitSeat",
    defaultGameKey: [{k: 'y', d: 3000}],
    defaultGameKeyLookup: ["y"],
    defaultGameKeyEvent: "hold",
    gameKeyText: "Y (Hold)",
    gameKeyDefaultKeyBinding: true,
    gameKeyName: "Exit Seat",
    //defaultGameKey: [{k:"y",d:2000}],   //key: "y", delay: 2 seconds
    buttonName: "Exit Seat",
    textHelp: "",
    style: "primary",
    controlType: "button",
    category: "flight",
  },





  
  {
    controlName: "increaseCapacitorsWeapons",
    defaultGameKey: ["f5"],
    defaultGameKeyLookup: ["f5"],
    defaultGameKeyEvent: "press",
    gameKeyText: "F5",
    gameKeyDefaultKeyBinding: true,
    gameKeyName: "",
    buttonName: "Weapons",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "flight",
  },
  {
    controlName: "increaseCapacitorsShields",
    defaultGameKey: ["f7"],
    defaultGameKeyLookup: ["f7"],
    defaultGameKeyEvent: "press",
    gameKeyText: "F7",
    gameKeyDefaultKeyBinding: true,
    gameKeyName: "",
    buttonName: "Shields",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "flight",
  },
  {
    controlName: "increaseCapacitorsThrusters",
    defaultGameKey: ["f6"],
    defaultGameKeyLookup: ["f6"],
    defaultGameKeyEvent: "press",
    gameKeyText: "F6",
    gameKeyDefaultKeyBinding: true,
    gameKeyName: "",
    buttonName: "Thrusters",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "flight",
  },
  {
    controlName: "resetCapacitors",
    defaultGameKey: ["f8"],
    defaultGameKeyLookup: ["f8"],
    defaultGameKeyEvent: "press",
    gameKeyText: "F8",
    gameKeyDefaultKeyBinding: true,
    gameKeyName: "Reset Capacitors",
    buttonName: "Reset Capacitors",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "flight",
  },

  
  {
    controlName: "decreaseThrottle",
    defaultGameKey: ["f9"],
    defaultGameKeyLookup: ["f9"],
    defaultGameKeyEvent: "press",
    gameKeyText: "F9",
    gameKeyDefaultKeyBinding: true,
    gameKeyName: "Decrease Throttle",
    buttonName: "Throttle",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "flight",
  },
  {
    controlName: "decreaseThrottleToMin",
    defaultGameKey: ["f9","f9"],
    defaultGameKeyLookup: ["f9", "f9"],
    defaultGameKeyEvent: "press",
    gameKeyText: "F9 (Double Tap)",
    gameKeyDefaultKeyBinding: true,
    gameKeyName: "Throttle to Min",
    buttonName: "Throttle",
    textHelp: "",
    leftText: "",
    rightText: "",
    baseText: "to Min",
    style: "tap-primary-text",
    controlType: "button",
    category: "flight",
  },
  {
    controlName: "increaseThrottle",
    defaultGameKey: ["f10"],
    defaultGameKeyLookup: ["f10"],
    defaultGameKeyEvent: "press",
    gameKeyText: "F10",
    gameKeyDefaultKeyBinding: true,
    gameKeyName: "Increase Throttle",
    buttonName: "Throttle",
    textHelp: "",
    style: "tap-primary-text",
    controlType: "button",
    category: "flight",
  },
  {
    controlName: "increaseThrottleToMax",
    defaultGameKey: ["f10","f10"],
    defaultGameKeyLookup: ["f10","f10"],
    defaultGameKeyEvent: "press",
    gameKeyText: "F10 (Double Tap)",
    gameKeyDefaultKeyBinding: true,
    gameKeyName: "Throttle to Max",
    buttonName: "Throttle",
    textHelp: "",
    leftText: "",
    rightText: "",
    baseText: "to Max",
    style: "tap-primary-text",
    controlType: "button",
    category: "flight",
  },
  
  
  {
    controlName: "decoupleMode",
    defaultGameKey: ["left", "alt", "c"],
    defaultGameKeyLookup: ["left", "alt", "c"],
    defaultGameKeyEvent: "press",
    gameKeyText: "Left Alt + C",
    gameKeyDefaultKeyBinding: false,
    gameKeyName: "",
    buttonName: "Decouple",
    textHelp: "",
    style: "toggle-primary",
    controlType: "button",
    category: "flight",
  },

  
  {
    controlName: "speedLimiterIncrease",
    defaultGameKey: [],
    defaultGameKeyLookup: [],
    defaultGameKeyEvent: "press",
    gameKeyText: "NOT SET",
    buttonName: "Speed Limiter",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "flight",
  },
  {
    controlName: "speedLimiterDecrease",
    defaultGameKey: [],
    defaultGameKeyLookup: [],
    defaultGameKeyEvent: "press",
    gameKeyText: "NOT SET",
    buttonName: "Speed Limiter",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "flight",
  },
  {
    controlName: "speedLimiterReset",
    defaultGameKey: [],
    defaultGameKeyLookup: [],
    defaultGameKeyEvent: "press",
    gameKeyText: "NOT SET",
    buttonName: "Speed Limiter",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "flight",
  },

  
  {
    controlName: "ping",
    defaultGameKey: ["tab"],
    defaultGameKeyLookup: ["tab"],
    defaultGameKeyEvent: "press",
    gameKeyText: "TAB",
    gameKeyDefaultKeyBinding: true,
    gameKeyName: "Ping",
    buttonName: "Ping",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "flight",
  },

  {
    controlName: "continuousPing",
    defaultGameKey: ["tab"],
    defaultGameKeyLookup: ["tab"],
    defaultGameKeyEvent: "infi",
    gameKeyText: "TAB",
    gameKeyDefaultKeyBinding: false,
    gameKeyName: "Ping+",
    buttonName: "Ping+",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "flight",
    showGameKey: false,     /******* THIS IS CUSTOM - DONT SHOW USER ************/
  },
  {
    controlName: "continuousScan",  /***NOT A REAL COMMAND - TESTING ONLY */
    defaultGameKey: ["v"],
    defaultGameKeyLookup: ["v"],
    defaultGameKeyEvent: "infi",
    gameKeyText: "V",
    gameKeyDefaultKeyBinding: false,
    gameKeyName: "Scan+",
    buttonName: "Scan+",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "flight",
    showGameKey: false,     /******* THIS IS CUSTOM - DONT SHOW USER ************/
  },

  
  {
    controlName: "targetCycleAttackers",
    defaultGameKey: ["4"],
    defaultGameKeyLookup: ["4"],
    defaultGameKeyEvent: "press",
    gameKeyText: "4",
    gameKeyDefaultKeyBinding: true,
    gameKeyName: "",
    buttonName: "Cycle Attackers",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "flight",
  },
  {
    controlName: "targetCycleHostiles",
    defaultGameKey: ["5"],
    defaultGameKeyLookup: ["5"],
    defaultGameKeyEvent: "press",
    gameKeyText: "5",
    gameKeyDefaultKeyBinding: true,
    gameKeyName: "",
    buttonName: "Cycle Hostiles",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "flight",
  },
  {
    controlName: "targetCycleFriendlies",
    defaultGameKey: ["6"],
    defaultGameKeyLookup: ["6"],
    defaultGameKeyEvent: "press",
    gameKeyText: "6",
    gameKeyDefaultKeyBinding: true,
    gameKeyName: "",
    buttonName: "Cycle Friendlies",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "flight",
  },
  {
    controlName: "targetUnlockTarget",
    defaultGameKey: ["left", "alt", "t"],
    defaultGameKeyLookup: ["left", "alt", "t"],
    defaultGameKeyEvent: "press",
    gameKeyDefaultKeyBinding: true,
    gameKeyName: "Unlock Target",
    gameKeyText: "Left Alt + t",
    buttonName: "Unlock Target",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "flight",
  },










  {
    controlName: "autoLand", /* globalDelayPressMillisec (might need to set and then unset)*/
    defaultGameKey: [{k: 'n', d: 3000}],
    defaultGameKeyLookup: ["n"],
    defaultGameKeyEvent: "hold",
    gameKeyText: "N (Hold)",
    gameKeyDefaultKeyBinding: true,
    gameKeyName: "Auto Dock / Land",
    buttonName: "Auto Dock",
    textHelp: "",
    style: "primary",
    controlType: "button",
    category: "flight",
  },
  {
    controlName: "landingGear",
    defaultGameKey: ["n"],
    defaultGameKeyLookup: ["n"],
    defaultGameKeyEvent: "press",
    gameKeyText: "N",
    gameKeyDefaultKeyBinding: true,
    gameKeyName: "Landing Gear",
    defaultState: true,
    buttonName: "Gear",
    textHelp: "Raise/Lower",
    style: "toggle-primary",
    controlType: "button",
    category: "flight",
  },
  {
    controlName: "moveForward",
    defaultGameKey: ["w"],
    defaultGameKeyLookup: ["w"],
    defaultGameKeyEvent: "press",
    gameKeyText: "W",
    buttonName: "Fwd",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "flight",
  },
  {
    controlName: "moveBackward",
    defaultGameKey: ["s"],
    defaultGameKeyLookup: ["s"],
    defaultGameKeyEvent: "press",
    gameKeyText: "S",
    buttonName: "Back",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "flight",
  },
  {
    controlName: "jump",
    defaultGameKey: ["space"],
    defaultGameKeyLookup: ["space"],
    defaultGameKeyEvent: "press",
    gameKeyText: "Spacebar",
    buttonName: "Jump",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "flight",
  },
 
 
  {
    controlName: "weapons",
    defaultGameKey: ["p"],
    defaultGameKeyLookup: ["p"],
    defaultGameKeyEvent: "press",
    gameKeyText: "N",
    buttonName: "Weapons",
    textHelp: "",
    style: "warning",
    controlType: "button",
    category: "flight",
  },
  {
    controlName: "eject",
    defaultGameKey: ["left", "shift", "u"], //this has right alt + Y too
    defaultGameKeyLookup: ["left", "shift", "u"],
    defaultGameKeyEvent: "press",
    gameKeyText: "Left Shift + U",
    gameKeyDefaultKeyBinding: true,
    gameKeyName: "Eject",
    buttonName: "Eject",
    textHelp: "",
    style: "warning-special",
    controlType: "button",
    category: "flight",
  },
  {
    controlName: "selfDestruct",
    defaultGameKey: ["back_space"],
    defaultGameKeyLookup: ["back_space"],
    defaultGameKeyEvent: "press",
    gameKeyText: "Backspace",
    gameKeyDefaultKeyBinding: true,
    gameKeyName: "Self Destruct",
    buttonName: "Self Destruct",
    textHelp: "",
    style: "warning-special",
    controlType: "button",
    category: "flight",
  },
  {
    controlName: "lights",
    defaultGameKey: ["l"],
    defaultGameKeyLookup: ["l"],
    defaultGameKeyEvent: "press",
    gameKeyText: "L",
    gameKeyDefaultKeyBinding: true,
    gameKeyName: "Lights",
    buttonName: "Lights",
    textHelp: "",
    style: "toggle-primary",
    controlType: "button",
    category: "flight",
  },


  {
    controlName: "vtol",
    defaultGameKey: ["k"],
    defaultGameKeyLookup: ["k"],
    defaultGameKeyEvent: "press",
    gameKeyText: "K",
    gameKeyDefaultKeyBinding: true,
    gameKeyName: "VTOL",
    buttonName: "VTOL",
    textHelp: "",
    style: "toggle-primary",
    controlType: "button",
    category: "flight",
  },
  {
    controlName: "cruiseControl",
    defaultGameKey: ["c"],
    defaultGameKeyLookup: ["c"],
    defaultGameKeyEvent: "press",
    gameKeyText: "C",
    gameKeyDefaultKeyBinding: true,
    gameKeyName: "Cruise Control",
    buttonName: "Cruise",
    textHelp: "",
    style: "toggle-primary",
    controlType: "button",
    category: "flight",
  },

  {
    controlName: "requestLanding",
    defaultGameKey: ["left", "alt", "left", "shift", "n"],
    defaultGameKeyLookup: ["left", "alt", "left", "shift", "n"],
    defaultGameKeyEvent: "press",
    gameKeyText: "Left Alt + Left Shift + N",
    gameKeyDefaultKeyBinding: false,
    gameKeyName: "",
    buttonName: "Req Land",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "flight",
  },



  
  {
    controlName: "doorsOpenAll",
    defaultGameKey: ["left", "alt", "q"],
    defaultGameKeyLookup: ["left", "alt", "q"],
    defaultGameKeyEvent: "press",
    gameKeyText: "Left Alt + Q",
    gameKeyDefaultKeyBinding: false,
    gameKeyName: "Open Doors",
    buttonName: "Open",
    textHelp: "",
    leftText: "",
    rightText: "",
    baseText: "Doors",
    style: "tap-primary",
    controlType: "button",
    category: "flight",
  },
  {
    controlName: "doorsCloseAll",
    defaultGameKey: ["left", "alt", "w"],
    defaultGameKeyLookup: ["left", "alt", "w"],
    defaultGameKeyEvent: "press",
    gameKeyText: "Left Alt + W",
    gameKeyDefaultKeyBinding: false,
    gameKeyName: "Close Doors",
    buttonName: "Close",
    textHelp: "",
    leftText: "",
    rightText: "",
    baseText: "Doors",
    style: "tap-primary",
    controlType: "button",
    category: "flight",
  },
  {
    controlName: "doorsLockAll",
    defaultGameKey: ["left", "alt", "e"],
    defaultGameKeyLookup: ["left", "alt", "e"],
    defaultGameKeyEvent: "press",
    gameKeyText: "Left Alt + E",
    gameKeyDefaultKeyBinding: false,
    gameKeyName: "",
    buttonName: "Lock",
    textHelp: "",
    leftText: "",
    rightText: "",
    baseText: "Doors",
    style: "tap-primary",
    controlType: "button",
    category: "flight",
  },
  {
    controlName: "doorsUnlockAll",
    defaultGameKey: ["left", "alt", "r"],
    defaultGameKeyLookup: ["left", "alt", "r"],
    defaultGameKeyEvent: "press",
    gameKeyText: "Left Alt + R",
    gameKeyDefaultKeyBinding: false,
    gameKeyName: "",
    buttonName: "Unlock",
    textHelp: "",
    leftText: "",
    rightText: "",
    baseText: "Doors",
    style: "tap-primary",
    controlType: "button",
    category: "flight",
  },



  {
    controlName: "misslesTypeCycleForward",
    defaultGameKey: [""],
    defaultGameKeyLookup: [""],
    defaultGameKeyEvent: "press",
    gameKeyText: "NOT SET",
    buttonName: "Type",
    textHelp: "",
    leftText: "Cycle",
    rightText: "",
    baseText: "Forward",
    shortName: "Cycle",
    style: "tap-primary-text",
    controlType: "button",
    category: "flight",
  },
  {
    controlName: "misslesTypeCycleBackwards",
    defaultGameKey: [""],
    defaultGameKeyLookup: [""],
    defaultGameKeyEvent: "press",
    gameKeyText: "NOT SET",
    buttonName: "Type",
    textHelp: "",
    leftText: "Cycle",
    rightText: "",
    baseText: "Backwards",
    shortName: "Cycle",
    style: "tap-primary-text",
    controlType: "button",
    category: "flight",
  },
  {
    controlName: "misslesIncreaseArmed",
    defaultGameKey: ["g"],
    defaultGameKeyLookup: ["g"],
    defaultGameKeyEvent: "press",
    gameKeyText: "G",
    buttonName: "Increase",
    textHelp: "",
    leftText: "",
    rightText: "",
    baseText: "Armed",
    shortName: "Inc.",
    style: "tap-primary-text",
    controlType: "button",
    category: "flight",
  },
  {
    controlName: "misslesResetArmed",
    defaultGameKey: ["left", "alt", "g"],
    defaultGameKeyLookup: ["left", "alt", "g"],
    defaultGameKeyEvent: "press",
    gameKeyText: "Left Alt + G",
    buttonName: "Armed",
    textHelp: "",
    leftText: "",
    rightText: "",
    baseText: "Reset",
    shortName: "Reset",
    style: "tap-primary-text",
    controlType: "button",
    category: "flight",
  },
  {
    controlName: "scanningMode",
    defaultGameKey: ["v"],
    defaultGameKeyLookup: ["v"],
    defaultGameKeyEvent: "press",
    gameKeyText: "V",
    gameKeyDefaultKeyBinding: true,
    gameKeyName: "Scanning Mode",
    buttonName: "Scanning Mode",
    textHelp: "",
    style: "warning-special",
    controlType: "button",
    category: "flight",
  },
  {
    controlName: "scanningPing",
    defaultGameKey: ["tab"],
    defaultGameKeyLookup: ["tab"],
    defaultGameKeyEvent: "press",
    gameKeyText: "TAB",
    buttonName: "Ping",
    textHelp: "",
    leftText: "",
    rightText: "",
    baseText: "",
    style: "tap-primary-text",
    controlType: "button",
    category: "flight",
  },
  {
    controlName: "scanningIncreasePingAngle",
    defaultGameKey: ["."],
    defaultGameKeyLookup: ["."],
    defaultGameKeyEvent: "press",
    gameKeyText: ".",
    buttonName: "Ping",
    textHelp: "",
    leftText: "Increase",
    rightText: "",
    baseText: "Angle",
    textHelp: "",
    style: "tap-primary-text",
    controlType: "button",
    category: "flight",
  },
  {
    controlName: "scanningDecreasePingAngle",
    defaultGameKey: [","],
    defaultGameKeyLookup: [","],
    defaultGameKeyEvent: "press",
    gameKeyText: ",",
    buttonName: "Ping",
    textHelp: "",
    leftText: "",
    rightText: "Decrease",
    baseText: "Angle",
    textHelp: "",
    style: "tap-primary-text",
    controlType: "button",
    category: "flight",
  },

  
  {
    controlName: "targetingGimbal",
    defaultGameKey: ["g"],
    defaultGameKeyLookup: ["g"],
    defaultGameKeyEvent: "press",
    gameKeyText: "G",
    buttonName: "Gimbal",
    textHelp: "",
    leftText: "",
    rightText: "",
    baseText: "",
    shortName: "Gimbal",
    style: "tap-primary",
    controlType: "button",
    category: "flight",
  },
  {
    controlName: "targetingCycleTargets",
    defaultGameKey: ["t"],
    defaultGameKeyLookup: ["t"],
    defaultGameKeyEvent: "press",
    gameKeyText: "T",
    buttonName: "Targets",
    textHelp: "",
    leftText: "",
    rightText: "",
    baseText: "Cycle",
    shortName: "Cycle",
    style: "tap-primary-text",
    controlType: "button",
    category: "flight",
  },
  {
    controlName: "targetingUnlockTarget",
    defaultGameKey: ["left", "alt", "t"],
    defaultGameKeyLookup: ["left", "alt", "t"],
    defaultGameKeyEvent: "press",
    gameKeyText: "Left Alt + T",
    buttonName: "Targets",
    textHelp: "",
    leftText: "",
    rightText: "",
    baseText: "Unlock",
    shortName: "Unlock",
    style: "tap-primary-text",
    controlType: "button",
    category: "flight",
  },

  {
    controlName: "decreaseShipPower",
    defaultGameKey: ["f9"],
    defaultGameKeyLookup: ["f9"],
    defaultGameKeyEvent: "press",
    gameKeyText: "F9",
    buttonName: "Power",
    textHelp: "",
    leftText: "",
    rightText: "",
    baseText: "Decrease",
    style: "tap-primary-text",
    controlType: "button",
    category: "flight",
  },
  {
    controlName: "increaseShipPower",
    defaultGameKey: ["f10"],
    defaultGameKeyLookup: ["f10"],
    defaultGameKeyEvent: "press",
    gameKeyText: "F10",
    buttonName: "Power",
    textHelp: "",
    leftText: "",
    rightText: "",
    baseText: "Increase",
    style: "tap-primary-text",
    controlType: "button",
    category: "flight",
  },



  {
    controlName: "shieldsReset",
    defaultGameKey: ["numpad5"],
    defaultGameKeyLookup: ["numpad5"],
    defaultGameKeyEvent: "press",
    gameKeyText: "Numpad 5",
    buttonName: "Shields",
    textHelp: "",
    leftText: "",
    rightText: "",
    baseText: "Reset",
    style: "tap-primary-text",
    controlType: "button",
    category: "flight",
  },
  {
    controlName: "shieldsRaiseFront",
    defaultGameKey: ["numpad8"],
    defaultGameKeyLookup: ["numpad8"],
    defaultGameKeyEvent: "press",
    gameKeyText: "Numpad 8",
    buttonName: "Shields",
    textHelp: "",
    leftText: "Raise",
    rightText: "",
    baseText: "Front",
    style: "tap-primary-text",
    controlType: "button",
    category: "flight",
  },
  {
    controlName: "shieldsRaiseBack",
    defaultGameKey: ["numpad2"],
    defaultGameKeyLookup: ["numpad2"],
    defaultGameKeyEvent: "press",
    gameKeyText: "Numpad 2",
    buttonName: "Shields",
    textHelp: "",
    leftText: "Raise",
    rightText: "",
    baseText: "Back",
    style: "tap-primary-text",
    controlType: "button",
    category: "flight",
  },
  {
    controlName: "shieldsRaiseLeft",
    defaultGameKey: ["numpad4"],
    defaultGameKeyLookup: ["numpad4"],
    defaultGameKeyEvent: "press",
    gameKeyText: "Numpad 4",
    buttonName: "Shields",
    textHelp: "",
    leftText: "Raise",
    rightText: "",
    baseText: "Left",
    style: "tap-primary-text",
    controlType: "button",
    category: "flight",
  },
  {
    controlName: "shieldsRaiseRight",
    defaultGameKey: ["numpad6"],
    defaultGameKeyLookup: ["numpad6"],
    defaultGameKeyEvent: "press",
    gameKeyText: "Numpad 6",
    buttonName: "Shields",
    textHelp: "",
    leftText: "Raise",
    rightText: "",
    baseText: "Right",
    style: "tap-primary-text",
    controlType: "button",
    category: "flight",
  },
  {
    controlName: "shieldsRaiseTop",
    defaultGameKey: ["numpad7"],
    defaultGameKeyLookup: ["numpad7"],
    defaultGameKeyEvent: "press",
    gameKeyText: "Numpad 7",
    buttonName: "Shields",
    textHelp: "",
    leftText: "Raise",
    rightText: "",
    baseText: "Top",
    style: "tap-primary-text",
    controlType: "button",
    category: "flight",
  },
  {
    controlName: "shieldsRaiseBottom",
    defaultGameKey: ["numpad1"],
    defaultGameKeyLookup: ["numpad1"],
    defaultGameKeyEvent: "press",
    gameKeyText: "Numpad 1",
    buttonName: "Shields",
    textHelp: "",
    leftText: "Raise",
    rightText: "",
    baseText: "Bottom",
    style: "tap-primary-text",
    controlType: "button",
    category: "flight",
  },


  
  {
    controlName: "turretGyroStabilization",
    defaultGameKey: ["x"],
    defaultGameKeyLookup: ["x"],
    defaultGameKeyEvent: "press",
    gameKeyText: "X",
    buttonName: "Gyro",
    textHelp: "",
    shortName: "G.Stab.",
    style: "tap-primary",
    controlType: "button",
    category: "flight",
  },
  {
    controlName: "turretGyroRecentre",
    defaultGameKey: [{k: 'c', d: 3000}],
    defaultGameKeyLookup: ["c"],
    defaultGameKeyEvent: "hold",
    gameKeyText: "C (Hold)",
    buttonName: "Gyro",
    textHelp: "",
    leftText: "",
    rightText: "",
    baseText: "Re-Centre",
    shortName: "G.Cen.",
    style: "tap-primary-text",
    controlType: "button",
    category: "flight",
  },
  {
    controlName: "turretESP",
    defaultGameKey: ["e"],
    defaultGameKeyLookup: ["e"],
    defaultGameKeyEvent: "press",
    gameKeyText: "E",
    buttonName: "E.S.P.",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "flight",
  },
  {
    controlName: "turretCycleFiringMode",
    defaultGameKey: ["b"],
    defaultGameKeyLookup: ["b"],
    defaultGameKeyEvent: "press",
    gameKeyText: "B",
    buttonName: "Firing",
    textHelp: "",
    leftText: "Cycle",
    rightText: "",
    baseText: "Mode",
    style: "tap-primary-text",
    controlType: "button",
    category: "flight",
  },

  {
    controlName: "quantumTravelSystem",
    defaultGameKey: ["b"],
    defaultGameKeyLookup: ["b"],
    defaultGameKeyEvent: "press",
    gameKeyText: "B",
    buttonName: "Quantum",
    textHelp: "",
    leftText: "Travel",
    rightText: "",
    baseText: "System",
    style: "tap-primary-text",
    controlType: "button",
    category: "flight",
  },
  {
    controlName: "quantumDrive",
    defaultGameKey: [{k: 'b', d: 3000}],    //For sending
    defaultGameKeyLookup: ["b"],            //For searching the key
    defaultGameKeyEvent: "hold",            //For searching the key
    gameKeyText: "B (Hold)",
    buttonName: "Quantum",
    textHelp: "",
    leftText: "",
    rightText: "",
    baseText: "Engage",
    style: "tap-primary-text",
    controlType: "button",
    category: "flight",
  },
  
  



  /**********************************************************************************************************
   * ********************************************************************************************************
   * *****  TURRETS 
   * ********************************************************************************************************
   * *******************************************************************************************************/
  

  {
    controlName: "decoy",
    defaultGameKey: ["h"],
    defaultGameKeyLookup: ["h"],
    defaultGameKeyEvent: "press",
    gameKeyText: "H",
    buttonName: "Decoy",
    textHelp: "Avoid",
    style: "primary",
    controlType: "button",
    category: "flight",
  },
  {
    controlName: "decoyBurstIncrease",
    defaultGameKey: ["right", "alt", "h"],
    defaultGameKeyLookup: ["right", "alt", "h"],
    defaultGameKeyEvent: "press",
    gameKeyText: "Right Alt + H",
    buttonName: "Decoy",
    textHelp: "Avoid",
    style: "primary",
    controlType: "button",
    category: "flight",
  },
  {
    controlName: "decoyBurstDecrease",
    defaultGameKey: ["left", "alt", "h"],
    defaultGameKeyLookup: ["left", "alt", "h"],
    defaultGameKeyEvent: "press",
    gameKeyText: "Left Alt + H",
    buttonName: "Decoy",
    textHelp: "Avoid",
    style: "primary",
    controlType: "button",
    category: "flight",
  },
  {
    controlName: "decoyPanic",
    defaultGameKey: [],
    defaultGameKeyLookup: [],
    defaultGameKeyEvent: "press",
    gameKeyText: "NOT SET",
    buttonName: "Decoy Panic",
    textHelp: "",
    style: "primary-special",
    controlType: "button",
    category: "flight",
  },
  {
    controlName: "noise",
    defaultGameKey: ["j"],
    defaultGameKeyLookup: ["j"],
    defaultGameKeyEvent: "press",
    gameKeyText: "J",
    buttonName: "Noise",
    textHelp: "Avoid",
    style: "primary-special",
    controlType: "button",
    category: "flight",
  },

    /**********************************************************************************************************
   * ********************************************************************************************************
   * *****  PERSON 
   * ********************************************************************************************************
   * *******************************************************************************************************/
  
  {
    controlName: "inventory",
    defaultGameKey: ["i"],
    defaultGameKeyLookup: ["i"],
    defaultGameKeyEvent: "press",
    gameKeyText: "I",
    buttonName: "Inv",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "person",
    faIcon: "fas fa-archive",
  },
  {
    controlName: "wipeVisor", /**/
    defaultGameKey: ["left", "alt", "x"],
    defaultGameKeyLookup: ["left", "alt", "x"],
    defaultGameKeyEvent: "press",
    gameKeyText: "Left Alt + X",
    buttonName: "Visor",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "person",
    faIcon: "fas fa-sign-language",
  },
  {
    controlName: "dropItem", /**/
    defaultGameKey: ["equals"],
    defaultGameKeyLookup: ["equals"],
    defaultGameKeyEvent: "press",
    gameKeyText: "=",
    buttonName: "Drop",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "person",
    faIcon: "fas fa-caret-down",
  },
  {
    controlName: "holsterItem",
    defaultGameKey: [{k: 'r', d: 3000}],
    defaultGameKeyLookup: ["r"],
    defaultGameKeyEvent: "hold",
    gameKeyText: "R (Hold)",
    gameKeyDefaultKeyBinding: true,
    gameKeyName: "Holster Item",
    buttonName: "Holster Item",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "person",
  },

  
  {
    controlName: "selectSidearm",
    defaultGameKey: ["1"],
    defaultGameKeyLookup: ["1"],
    defaultGameKeyEvent: "press",
    gameKeyText: "1",
    buttonName: "Sidearm",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "person",
  },
  {
    controlName: "selectPrimaryWeapon",
    defaultGameKey: ["2"],
    defaultGameKeyLookup: ["2"],
    defaultGameKeyEvent: "press",
    gameKeyText: "2",
    buttonName: "Primary",
    textHelp: "",
    leftText: "",
    rightText: "",
    baseText: "Weapon",
    style: "tap-primary-text",
    controlType: "button",
    category: "person",
  },
  {
    controlName: "selectSecondaryWeapon",
    defaultGameKey: ["3"],
    defaultGameKeyLookup: ["3"],
    defaultGameKeyEvent: "press",
    gameKeyText: "3",
    buttonName: "Secondary",
    textHelp: "",
    leftText: "",
    rightText: "",
    baseText: "Weapon",
    style: "tap-primary-text",
    controlType: "button",
    category: "person",
  },
  {
    controlName: "selectGadget",
    defaultGameKey: ["4"],
    defaultGameKeyLookup: ["4"],
    defaultGameKeyEvent: "press",
    gameKeyText: "4",
    buttonName: "Gadget",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "person",
  },
  {
    controlName: "inspectItem",
    defaultGameKey: [""],
    defaultGameKeyLookup: [""],
    defaultGameKeyEvent: "press",
    gameKeyText: "NOT SET",
    buttonName: "Inspect",
    textHelp: "",
    leftText: "",
    rightText: "",
    baseText: "Item",
    style: "tap-primary-text",
    controlType: "button",
    category: "person",
  },
  {
    controlName: "customizeItem",
    defaultGameKey: ["j"],
    defaultGameKeyLookup: ["j"],
    defaultGameKeyEvent: "press",
    gameKeyText: "J",
    buttonName: "Customize",
    textHelp: "",
    leftText: "",
    rightText: "",
    baseText: "Item",
    style: "tap-primary-text",
    controlType: "button",
    category: "person",
  },
  {
    controlName: "underbarrelAttachmentAction",
    defaultGameKey: ["u"],
    defaultGameKeyLookup: ["u"],
    defaultGameKeyEvent: "press",
    gameKeyText: "U",
    buttonName: "Under",
    textHelp: "",
    leftText: "",
    rightText: "",
    baseText: "Barrel",
    style: "tap-primary-text",
    controlType: "button",
    category: "person",
  },
  {
    controlName: "changeFireMode",
    defaultGameKey: ["v"],
    defaultGameKeyLookup: ["v"],
    defaultGameKeyEvent: "press",
    gameKeyText: "V",
    buttonName: "Firing",
    textHelp: "",
    leftText: "Change",
    rightText: "",
    baseText: "Mode",
    style: "tap-primary-text",
    controlType: "button",
    category: "person",
  },
  {
    controlName: "medPen",
    defaultGameKey: ["c"],
    defaultGameKeyLookup: ["c"],
    defaultGameKeyEvent: "press",
    gameKeyText: "C",
    buttonName: "MedPen",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "person",
  },
  {
    controlName: "activateMedicalBeacon",
    defaultGameKey: [{k: 'm', d: 3000}],
    defaultGameKeyLookup: ["m"],
    defaultGameKeyEvent: "hold",
    gameKeyText: "M (Hold)",
    buttonName: "Medical",
    textHelp: "",
    leftText: "Activate",
    rightText: "",
    baseText: "Beacon",
    style: "tap-primary-text",
    controlType: "button",
    category: "person",
  },
  {
    controlName: "torch",
    defaultGameKey: ["t"],
    defaultGameKeyLookup: ["t"],
    defaultGameKeyEvent: "press",
    gameKeyText: "t",
    buttonName: "Torch",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "person",
  },














  /**********************************************************************************************************
   * ********************************************************************************************************
   * *****  SOCIAL 
   * ********************************************************************************************************
   * *******************************************************************************************************/
  
   {
    controlName: "proximityVoice",
    defaultGameKey: ["left", "alt", "numbpad-"],
    defaultGameKeyLookup: ["left", "alt", "numbpad-"],
    defaultGameKeyEvent: "press",
    gameKeyText: "Left Alt + Numpad -",
    gameKeyDefaultKeyBinding: true,
    gameKeyName: "Voice (Proximity)",
    buttonName: "Voice (Proximity)",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "social",
    faIcon: "fas fa-bullhorn",
  },
  {
    controlName: "emotesYes",
    defaultGameKey: ["numpad4"],
    defaultGameKeyLookup: ["numpad4"],
    defaultGameKeyEvent: "press",
    gameKeyText: "Numpad 4",
    gameKeyDefaultKeyBinding: true,
    gameKeyName: "Yes",
    buttonName: "Yes",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "social",
    faIcon: "",
  },
  {
    controlName: "emotesNo",
    defaultGameKey: ["numpad6"],
    defaultGameKeyLookup: ["numpad6"],
    defaultGameKeyEvent: "press",
    gameKeyText: "Numpad 6",
    gameKeyDefaultKeyBinding: true,
    gameKeyName: "No",
    buttonName: "No",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "social",
    faIcon: "",
  },
  {
    controlName: "emotesForward",
    defaultGameKey: ["numpad5"],
    defaultGameKeyLookup: ["numpad5"],
    defaultGameKeyEvent: "press",
    gameKeyText: "Numpad 5",
    gameKeyDefaultKeyBinding: true,
    gameKeyName: "Forward",
    buttonName: "Forward",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "social",
    faIcon: "",
  },
  {
    controlName: "emotesStop",
    defaultGameKey: ["numpad2"],
    defaultGameKeyLookup: ["numpad2"],
    defaultGameKeyEvent: "press",
    gameKeyText: "Numpad 2",
    gameKeyDefaultKeyBinding: true,
    gameKeyName: "Stop",
    buttonName: "Stop",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "social",
    faIcon: "",
  },


  {
    controlName: "emotesClap",
    defaultGameKey: ["right","alt","0"],
    defaultGameKeyLookup: ["right","alt","0"],
    defaultGameKeyEvent: "press",
    gameKeyText: "Right Alt + 0",
    gameKeyDefaultKeyBinding: false,
    gameKeyName: "Clap",
    buttonName: "Clap",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "social",
    faIcon: "",
  },
  {
    controlName: "emotesCheer",
    defaultGameKey: ["right","alt","1"],
    defaultGameKeyLookup: ["right","alt","1"],
    defaultGameKeyEvent: "press",
    gameKeyText: "Right Alt + 1",
    gameKeyDefaultKeyBinding: false,
    gameKeyName: "Cheer",
    buttonName: "Cheer",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "social",
    faIcon: "",
  },
  {
    controlName: "emotesWave",
    defaultGameKey: ["right","alt","2"],
    defaultGameKeyLookup: ["right","alt","2"],
    defaultGameKeyEvent: "press",
    gameKeyText: "Right Alt + 2",
    gameKeyDefaultKeyBinding: false,
    gameKeyName: "Wave",
    buttonName: "Wave",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "social",
    faIcon: "",
  },
  {
    controlName: "emotesDance",
    defaultGameKey: ["right","alt","3"],
    defaultGameKeyLookup: ["right","alt","3"],
    defaultGameKeyEvent: "press",
    gameKeyText: "Right Alt + 3",
    gameKeyDefaultKeyBinding: false,
    gameKeyName: "Dance",
    buttonName: "Dance",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "social",
    faIcon: "",
  },
  {
    controlName: "emotesForward",
    defaultGameKey: ["right","alt","4"],
    defaultGameKeyLookup: ["numpad4"],
    defaultGameKeyEvent: "press",
    gameKeyText: "Right Alt + 4",
    gameKeyDefaultKeyBinding: false,
    gameKeyName: "Forward",
    buttonName: "Forward",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "social",
    faIcon: "",
  },
  {
    controlName: "emotesAttention",
    defaultGameKey: ["right","alt","5"],
    defaultGameKeyLookup: ["right","alt","5"],
    defaultGameKeyEvent: "press",
    gameKeyText: "Right Alt + 5",
    gameKeyDefaultKeyBinding: false,
    gameKeyName: "Attention",
    buttonName: "Attention",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "social",
    faIcon: "",
  },
  {
    controlName: "emotesBlah",
    defaultGameKey: ["right","alt","6"],
    defaultGameKeyLookup: ["right","alt","6"],
    defaultGameKeyEvent: "press",
    gameKeyText: "Right Alt + 6",
    gameKeyDefaultKeyBinding: false,
    gameKeyName: "Blah",
    buttonName: "Blah",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "social",
    faIcon: "",
  },
  {
    controlName: "emotesDisagree",
    defaultGameKey: ["right","alt","7"],
    defaultGameKeyLookup: ["right","alt","7"],
    defaultGameKeyEvent: "press",
    gameKeyText: "Right Alt + 7",
    gameKeyDefaultKeyBinding: false,
    gameKeyName: "Disagree",
    buttonName: "Disagree",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "social",
    faIcon: "",
  },
  {
    controlName: "emotesSalute",
    defaultGameKey: ["right","alt","8"],
    defaultGameKeyLookup: ["right","alt","8"],
    defaultGameKeyEvent: "press",
    gameKeyText: "Right Alt + 8",
    gameKeyDefaultKeyBinding: false,
    gameKeyName: "Salute",
    buttonName: "Salute",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "social",
    faIcon: "",
  },
  {
    controlName: "emotesLaugh",
    defaultGameKey: ["right","alt","9"],
    defaultGameKeyLookup: ["right","alt","9"],
    defaultGameKeyEvent: "press",
    gameKeyText: "Right Alt + 9",
    gameKeyDefaultKeyBinding: false,
    gameKeyName: "Laugh",
    buttonName: "Laugh",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "social",
    faIcon: "",
  },
  





  /**********************************************************************************************************
   * ********************************************************************************************************
   * *****  MINING 
   * ********************************************************************************************************
   * *******************************************************************************************************/
  



  


  /**********************************************************************************************************
   * ********************************************************************************************************
   * *****  TURRETS 
   * ********************************************************************************************************
   * *******************************************************************************************************/
  


  
  {
    controlName: "leftAltX1",
    defaultGameKey: ["left", "alt", "x"],
    buttonName: "leftAltX1",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "test",
  },
  {
    controlName: "leftAltX2",
    defaultGameKey: ["alt", "left", "x"],
    buttonName: "leftAltX2",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "test",
  },
  {
    controlName: "leftAltX3",
    defaultGameKey: ["altleft", "x"],
    buttonName: "leftAltX3",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "test",
  },
  {
    controlName: "leftAltX4",
    defaultGameKey: ["leftalt", "x"],
    buttonName: "leftAltX4",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "test",
  },
  {
    controlName: "leftAltX5",
    defaultGameKey: ["left_alt", "x"],
    buttonName: "leftAltX5",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "test",
  },
  {
    controlName: "leftAltX6",
    defaultGameKey: ["alt_left", "x"],
    buttonName: "leftAltX6",
    textHelp: "",
    style: "tap-primary",
    controlType: "button",
    category: "test",
  },

  {
    controlName: "all",
    defaultGameKey: ["n"],
    buttonName: "all",
    textHelp: "all",
    style: "",
    controlType: "button",
    category: "all",
  },
  
]
export default KeyMappings;


