/** BILLING ACTIONS ** */

import { v1 as uuidv1 } from 'uuid';

import GH_billingApi from './api/GH_billingApi';



import { updateVersion } from 'Reducers/appSlice';
import { setPortalLoginStatusR, setSubscriptionsR } from 'Reducers/Auth';
import { setApiActivityR, updateApiActivityR, removeApiActivityR, } from 'Reducers/ApiUI';

import { setUserDataR, } from 'Reducers/Game';





/********************************************************************
* FREE SERVICE - SUBSCRIBING TO THE FREE SERVICE
********************************************************************/
export function subscribeToFreeService(body = {}) {
  return (dispatch, getState) => {
    const { hudAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {

      let {  } = body;

      console.log("subscribeToFreePlan");

      let bodyData = JSON.stringify({
        selectedPlan: "sub00",        //FREE PLAN
      });

      GH_billingApi.subscribeToFreePlan(bodyData, hudAccessToken)
      .then((apiResponseData) => {
        console.warn("API | RESPONSE | subscribeToFreePlan");
        //console.log(apiResponseData);

        if (apiResponseData.status === true) {
          if (apiResponseData.data.appStatus === true) {
            
            //update subscription status in redux TODO
            let subscriptionData = [];
            try {
              subscriptionData = apiResponseData.data.subscriptions;
            } catch (e) {
              subscriptionData = [];
            }
            if (subscriptionData === undefined) {
              subscriptionData = [];
            }
            dispatch(setSubscriptionsR({subscriptionData}));

          }

        } else {
         

        }
        
        resolve(apiResponseData);
      })
      .catch((error) => {
       
        resolve(error);
      });
    }));
  };
}


/********************************************************************
* STRIPE - BILLING PAYMENT INTENT
********************************************************************/
export function billingPaymentIntent(body = {}) {
  return (dispatch, getState) => {
    const { hudAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {

      let {  } = body;

      console.log("billingPaymentIntent");

      let bodyData = JSON.stringify({
        payment: "10000",
      });

      GH_billingApi.billingPaymentIntent(bodyData, hudAccessToken)
      .then((apiResponseData) => {
        console.warn("API | RESPONSE | billingPaymentIntent");
        //console.log(apiResponseData);

        let paymentIntentResponse = {};

        if (apiResponseData.status === true) {
          if (apiResponseData.data.appStatus === true) {
            paymentIntentResponse = apiResponseData.data.paymentIntent;
          }

        } else {
         

        }
        
        resolve(paymentIntentResponse);
      })
      .catch((error) => {
       
        resolve(error);
      });
    }));
  };
}

/********************************************************************
* STRIPE - BILLING CREATE CUSTOMER WITH SUBSCRIPTION SELECTION
********************************************************************/
export function billingCreateCustomer(body = {}) {
  return (dispatch, getState) => {
    const { hudAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {

      let { subscription } = body;

      console.log("billingCreateCustomer");

      let bodyData = JSON.stringify({
        subscription: subscription,
      });

      GH_billingApi.billingCreateCustomer(bodyData, hudAccessToken)
      .then((apiResponseData) => {
        console.warn("API | RESPONSE | billingCreateCustomer");
        //console.log(apiResponseData);

        let subscriptionId = '';
        let clientSecret = '';
        let rs = '';
        let ts = '';
        

        if (apiResponseData.status === true) {
          if (apiResponseData.data.appStatus === true) {
            subscriptionId = apiResponseData.data.subscriptionId;
            clientSecret = apiResponseData.data.clientSecret;
            rs = apiResponseData.data.rs;
            ts = apiResponseData.data.ts;
          }

        } else {
         

        }

        console.log("subscriptionId");
        console.log(subscriptionId);
        console.log("clientSecret");
        console.log(clientSecret);

        
        resolve({
          subscriptionId,
          clientSecret,
          rs,
          ts,
        });
      })
      .catch((error) => {
       
        resolve(error);
      });
    }));
  };
}



/********************************************************************
* STRIPE - CHECK STATUS OF ORDER
********************************************************************/
export function checkStatusOfOrder(body = {}) {
  return (dispatch, getState) => {
    const { hudAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {

      let { subscriptionId = '', } = body;

      console.log("checkStatusOfOrder");

      let apiBody = JSON.stringify({
        subscriptionId: subscriptionId,
      });

      GH_billingApi.checkStatusOfOrder(apiBody, hudAccessToken)
      .then((apiResponseData) => {
        console.warn("API | RESPONSE | checkStatusOfOrder");
        //console.log(apiResponseData);

        let userSubscriptionStatus = '';
        let subscriptionData = {};

        if (apiResponseData.status === true) {
          if (apiResponseData.data.appStatus === true) {
            userSubscriptionStatus = apiResponseData.data.userSubscriptionStatus;
            subscriptionData = apiResponseData.data.subscriptionData;
          }

        } else {
         

        }

        console.log(`userSubscriptionStatus: ${userSubscriptionStatus} | checkStatusOfOrder Response`);
   
        resolve({
          userSubscriptionStatus,
          subscriptionData,
        });
      })
      .catch((error) => {
       
        resolve(error);
      });
    }));
  };
}





/********************************************************************
* STRIPE - CANCEL SUBSCRIPTION
********************************************************************/
export function userSubscriptionCancel(body = {}) {
  return (dispatch, getState) => {
    const { hudAccessToken } = getState().Auth;
    return new Promise(((resolve, reject) => {
      let { btnData = false,  subscriptionId = '', } = body;
      if (btnData !== false) {
        dispatch(setApiActivityR({btnData}));
      }

      console.log("userSubscriptionCancel");

      let apiBody = JSON.stringify({
        subscriptionId: subscriptionId,
      });

      GH_billingApi.subscriptionCancel(apiBody, hudAccessToken)
      .then((apiResponseData) => {
        console.warn("API | RESPONSE | subscriptionCancel");
        //console.log(apiResponseData);
        let errorAction = false;
        try {
          errorAction = apiResponseData.errorAction;
        } catch (e) {
          errorAction = false;
        }
        if (errorAction === undefined) {
          errorAction = false;
        }
        if (errorAction !== false) {
          if (errorAction === "relogin") {
            dispatch(setPortalLoginStatusR(false));
          }
        }
       

        if (apiResponseData.status === true) {
          if (apiResponseData.data.appStatus === true) {
           
            
          }

          //UPDATE API ACTIVITY
          dispatch(updateApiActivityR({btnData, status: true}));
          const myTimeoutEndOfFunction = setTimeout(function() {
            dispatch(removeApiActivityR({btnData}));
          }.bind(this, dispatch), 5000);

        } else {
          //UPDATE API ACTIVITY
          dispatch(updateApiActivityR({btnData, status: false}));
          const myTimeoutEndOfFunction = setTimeout(function() {
            dispatch(removeApiActivityR({btnData}));
          }.bind(this, dispatch), 5000);

        }

        
        
        resolve();
      })
      .catch((error) => {
       
        resolve(error);
      });
    }));
  };
}




































