import { v1 as uuidv1 } from 'uuid';
import { addTestPageDataV1R, addAccountToPagesV1R, createAccountGroupV1R } from 'Reducers/Analytics';


import {
  intlFormat,
  addHours,
  addDays,
  addWeeks,
  addMonths,
  addQuarters,
  addYears,
  subDays,
  subMonths,
  subQuarters,
  subYears,
  endOfDay,
  startOfDay,
  startOfYear,
  startOfMonth,
  startOfYesterday,
  startOfQuarter,
  endOfQuarter,
  endOfYesterday,
  endOfMonth,
  endOfYear,
  startOfWeek,
  endOfWeek,
  isSameDay,
  differenceInCalendarDays,
  eachHourOfInterval,
  eachDayOfInterval,
  eachWeekOfInterval,
  eachMonthOfInterval,
  eachQuarterOfInterval,
  eachYearOfInterval,
  lastDayOfMonth,
  lastDayOfQuarter,
  lastDayOfYear,
  getDaysInMonth,
  isWithinInterval,
  formatISO,
  getDate,
  isSunday,
  isMonday,
  isTuesday,
  isWednesday,
  isThursday,
  isFriday,
  isSaturday,
  differenceInDays,
} from "date-fns";
import { zonedTimeToUtc, utcToZonedTime, format, toDate } from 'date-fns-tz';


function mod(n, m) {
  return ((n % m) + m) % m;
}

/********************************************************************
* TEST | generateNewCustomerDataSet
********************************************************************/
export function generateNewCustomerDataSet(data = {}) {
  return (dispatch, getState) => {
    return new Promise(((resolve, reject) => {

      let { pageId = "", platform = "facebook", businessName = "", businessUserName = "" } = data;
      if (pageId === "") {
        pageId = `${Math.floor(Math.random() * (9999998888555551 - 1000103 + 1) + 1000103)}`;
      }
      var businessNames = [];
      var selectedBusinessName = [];
      if (businessName === "") {
        businessNames = ['Percepta','Exela Movers','Ibotta, Inc.','Wanderu','Aceable, Inc.','Intrepid Travel','Defendify','Twisters Gymnastics Academy','Aims Community College','Kaboom Fireworks','Compass Mortgage','Marathon Physical Therapy','Semicolon Bookstore','9Yards Media','When Pigs Fly','Light As a Feather','More Than Words','Top It Off','Bent Out of Shape Jewelry','To Each Their Own, LLC','Like You Mean It Productions','What You Will Yoga','EBAs','Soft As a Grape','Atlantis Sports Club & Spa','Saga Innovations','Atlas Ventures','Titan alarm','Influitive','Spinfluence','Intellivision','Perficient','Omnilert','Technologent','Securiteam','Innometrics','Puzzle Huddle','Hobby Lobby','The Knickknack Shack','Los Amigos Taqueria','Plan4Demand','Chic Boutique Consignments','ElemenOPillows',];
        selectedBusinessName = businessNames[Math.floor(Math.random()*businessNames.length)];
        businessName = `${selectedBusinessName} ${Math.floor(Math.random() * (999 - 20 + 1) + 20)}`;
        businessUserName = `${selectedBusinessName} ${Math.floor(Math.random() * (999 - 20 + 1) + 20)}`.toLowerCase();
      }

      let socialIcons = ['https://uberdigital-assets.s3.ap-southeast-2.amazonaws.com/socials/socialPillLogoBrand-Hill.png', 'https://uberdigital-assets.s3.ap-southeast-2.amazonaws.com/socials/socialPillLogoBrand-Hill.png', 'https://uberdigital-assets.s3.ap-southeast-2.amazonaws.com/socials/socialPillLogoBrand-Hill.png'];
      let selectedSocialIcon = socialIcons[Math.floor(Math.random()*socialIcons.length)];





      let pageText1 = {};
      let pageText2 = {};
      let pageText3 = {};
      let pageText4 = {};
      


      /************************************************************************************************************
       * CREATE NEW DATA SET FOR TESTING ALTERNATIVE ACCOUNT
      ************************************************************************************************************/

      const testDataDays = eachDayOfInterval({
        start: new Date(2020, 1, 1),
        end: new Date(2023, 1, 10)
      });
      console.log("testDataDays");
      console.log(testDataDays);
      //=> [
      //   Mon Oct 06 2014 00:00:00,
      //   Tue Oct 07 2014 00:00:00,
      //   Wed Oct 08 2014 00:00:00,
      //   Thu Oct 09 2014 00:00:00,
      //   Fri Oct 10 2014 00:00:00
      // ]

      let newTestDataSampleRecord = [];
      let newTestDataSampleRecord2 = [];
      let newTestDataSampleRecord3 = [];
      const usersBrowserTimeZone = "Australia/Brisbane";
      console.log(`usersBrowserTimeZone: ${usersBrowserTimeZone}`);

      for (let xxxxx = 0; xxxxx < testDataDays.length; xxxxx++) {
        let parsedDate = new Date(testDataDays[xxxxx]);
        let toUTCString = parsedDate.toUTCString();

        console.log(`parsedDate: ${parsedDate} ===> toUTCString: ${toUTCString}`);


        //let utcDate = zonedTimeToUtc(testDataDays[xxxxx], timeZone)

        newTestDataSampleRecord.push({
          fieldEndTime: toUTCString,   //formatISO(testDataDays[xxxxx]),
          fieldValue: 1,
        });
        newTestDataSampleRecord2.push({
          fieldEndTime: toUTCString,   //formatISO(testDataDays[xxxxx]),
          fieldValue: mod(xxxxx, 3),
        });
        newTestDataSampleRecord3.push({
          fieldEndTime: toUTCString,   //formatISO(testDataDays[xxxxx]),
          fieldValue: mod(xxxxx, 10),
        });
      }

      let newTestDataSampleMetricGroup1 = {
        _id: 'test1',
        name: 'page_impressions',
        title: 'Daily Total Impressions',
        desc: 'Daily: the number of views...',
        metrics: newTestDataSampleRecord,
      }

      let newTestDataSampleMetricGroup2 = {
        _id: 'test2',
        name: 'page_impressions_paid',
        title: 'Daily Total Paid Impressions',
        desc: 'Daily: the number of paid views...',
        metrics: newTestDataSampleRecord2,
      }

      let newTestDataSampleMetricGroup3 = {
        _id: 'test3',
        name: 'page_impressions_organic',
        title: 'Daily Total Organic Impressions',
        desc: 'Daily: the number of organic views...',
        metrics: newTestDataSampleRecord,
      }

      /****************************************
       * ADD TEST ACCOUNT 1
       ****************************************/

      let newCustomerRecord = {
        _id: Math.floor(Math.random() * (289318391831 - 2324 + 1) + 2324).toString(),
        pageId: pageId,
        platform: platform,
        name: businessName,
        brandName: businessName,
        username: businessUserName,
        socialIcon: selectedSocialIcon,
        ssCategory: "Shopping District",
        category: "Shopping District",
        pageMetrics: {
          daily: [newTestDataSampleMetricGroup1, newTestDataSampleMetricGroup2, newTestDataSampleMetricGroup3]
        }
      }

      dispatch(addTestPageDataV1R(newCustomerRecord));      //adds the data to the analytics (would be received as part of account login)

      //Adds the account to the pages selection for options to select via filters
      pageText1 = {
        pageId: pageId,
        platform: platform,
        name: businessName,
        brandName: businessName,
        username: businessUserName,
        socialIcon: selectedSocialIcon,
        ssCategory: "Shopping District",
        category: "Shopping District",
      }
      dispatch(addAccountToPagesV1R(pageText1));


      

      /****************************************
       * ADD TEST ACCOUNT 2
       ****************************************/
      pageId = `${Math.floor(Math.random() * (9999998888555551 - 1000103 + 1) + 1000103)}`;
      selectedBusinessName = businessNames[Math.floor(Math.random()*businessNames.length)];
      businessName = `${selectedBusinessName} ${Math.floor(Math.random() * (999 - 20 + 1) + 20)}`;
      businessUserName = `${selectedBusinessName} ${Math.floor(Math.random() * (999 - 20 + 1) + 20)}`.toLowerCase();
      selectedSocialIcon = socialIcons[Math.floor(Math.random()*socialIcons.length)];

      
      newTestDataSampleMetricGroup1 = {
        _id: 'test1',
        name: 'page_impressions',
        title: 'Daily Total Impressions',
        desc: 'Daily: the number of views...',
        metrics: newTestDataSampleRecord3,
      }

      newTestDataSampleMetricGroup2 = {
        _id: 'test2',
        name: 'page_impressions_paid',
        title: 'Daily Total Paid Impressions',
        desc: 'Daily: the number of paid views...',
        metrics: newTestDataSampleRecord3,
      }

      newTestDataSampleMetricGroup3 = {
        _id: 'test3',
        name: 'page_impressions_organic',
        title: 'Daily Total Organic Impressions',
        desc: 'Daily: the number of organic views...',
        metrics: newTestDataSampleRecord2,
      }

      newCustomerRecord = {
        _id: Math.floor(Math.random() * (289318391831 - 2324 + 1) + 2324).toString(),
        pageId: pageId,
        platform: platform,
        name: businessName,
        brandName: businessName,
        username: businessUserName,
        socialIcon: selectedSocialIcon,
        ssCategory: "Shopping District",
        category: "Shopping District",
        pageMetrics: {
          daily: [newTestDataSampleMetricGroup1, newTestDataSampleMetricGroup2, newTestDataSampleMetricGroup3]
        }
      }

      dispatch(addTestPageDataV1R(newCustomerRecord));      //adds the data to the analytics (would be received as part of account login)

      //Adds the account to the pages selection for options to select via filters
      pageText2 = {
        pageId: pageId,
        platform: platform,
        name: businessName,
        brandName: businessName,
        username: businessUserName,
        socialIcon: selectedSocialIcon,
        ssCategory: "Shopping District",
        category: "Shopping District",
      }
      dispatch(addAccountToPagesV1R(pageText2));

      

      /****************************************
       * ADD TEST ACCOUNT 3
       ****************************************/
       pageId = `${Math.floor(Math.random() * (9999998888555551 - 1000103 + 1) + 1000103)}`;
       selectedBusinessName = businessNames[Math.floor(Math.random()*businessNames.length)];
       businessName = `${selectedBusinessName} ${Math.floor(Math.random() * (999 - 20 + 1) + 20)}`;
       businessUserName = `${selectedBusinessName} ${Math.floor(Math.random() * (999 - 20 + 1) + 20)}`.toLowerCase();
       selectedSocialIcon = socialIcons[Math.floor(Math.random()*socialIcons.length)];
       
       newTestDataSampleMetricGroup1 = {
         _id: 'test1',
         name: 'page_impressions',
         title: 'Daily Total Impressions',
         desc: 'Daily: the number of views...',
         metrics: newTestDataSampleRecord,
       }
 
       newTestDataSampleMetricGroup2 = {
         _id: 'test2',
         name: 'page_impressions_paid',
         title: 'Daily Total Paid Impressions',
         desc: 'Daily: the number of paid views...',
         metrics: newTestDataSampleRecord,
       }
 
       newTestDataSampleMetricGroup3 = {
         _id: 'test3',
         name: 'page_impressions_organic',
         title: 'Daily Total Organic Impressions',
         desc: 'Daily: the number of organic views...',
         metrics: newTestDataSampleRecord,
       }
 
       newCustomerRecord = {
         _id: Math.floor(Math.random() * (289318391831 - 2324 + 1) + 2324).toString(),
         pageId: pageId,
         platform: platform,
         name: businessName,
         brandName: businessName,
         username: businessUserName,
         socialIcon: selectedSocialIcon,
         ssCategory: "Shopping District",
         category: "Shopping District",
         pageMetrics: {
           daily: [newTestDataSampleMetricGroup1, newTestDataSampleMetricGroup2, newTestDataSampleMetricGroup3]
         }
       }
 
       dispatch(addTestPageDataV1R(newCustomerRecord));      //adds the data to the analytics (would be received as part of account login)
 
       //Adds the account to the pages selection for options to select via filters
       pageText3 = {
        pageId: pageId,
        platform: platform,
        name: businessName,
        brandName: businessName,
        username: businessUserName,
        socialIcon: selectedSocialIcon,
        ssCategory: "Shopping District",
        category: "Shopping District",
      }
       dispatch(addAccountToPagesV1R(pageText3));


       /****************************************
       * ADD TEST ACCOUNT 4
       ****************************************/
        pageId = `${Math.floor(Math.random() * (9999998888555551 - 1000103 + 1) + 1000103)}`;
        selectedBusinessName = businessNames[Math.floor(Math.random()*businessNames.length)];
        businessName = `${selectedBusinessName} ${Math.floor(Math.random() * (999 - 20 + 1) + 20)}`;
        businessUserName = `${selectedBusinessName} ${Math.floor(Math.random() * (999 - 20 + 1) + 20)}`.toLowerCase();
        selectedSocialIcon = socialIcons[Math.floor(Math.random()*socialIcons.length)];
        
        newTestDataSampleMetricGroup1 = {
          _id: 'test1',
          name: 'page_impressions',
          title: 'Daily Total Impressions',
          desc: 'Daily: the number of views...',
          metrics: newTestDataSampleRecord,
        }
  
        newTestDataSampleMetricGroup2 = {
          _id: 'test2',
          name: 'page_impressions_paid',
          title: 'Daily Total Paid Impressions',
          desc: 'Daily: the number of paid views...',
          metrics: newTestDataSampleRecord,
        }
  
        newTestDataSampleMetricGroup3 = {
          _id: 'test3',
          name: 'page_impressions_organic',
          title: 'Daily Total Organic Impressions',
          desc: 'Daily: the number of organic views...',
          metrics: newTestDataSampleRecord,
        }
  
        newCustomerRecord = {
          _id: Math.floor(Math.random() * (289318391831 - 2324 + 1) + 2324).toString(),
          pageId: pageId,
          platform: platform,
          name: businessName,
          brandName: businessName,
          username: businessUserName,
          socialIcon: selectedSocialIcon,
          ssCategory: "Shopping District",
          category: "Shopping District",
          pageMetrics: {
            daily: [newTestDataSampleMetricGroup1, newTestDataSampleMetricGroup2, newTestDataSampleMetricGroup3]
          }
        }
  
        dispatch(addTestPageDataV1R(newCustomerRecord));      //adds the data to the analytics (would be received as part of account login)
  
        //Adds the account to the pages selection for options to select via filters
        pageText4 = {
          pageId: pageId,
          platform: platform,
          name: businessName,
          brandName: businessName,
          username: businessUserName,
          socialIcon: selectedSocialIcon,
          ssCategory: "Shopping District",
          category: "Shopping District",
        }
        dispatch(addAccountToPagesV1R(pageText4));



       /****************************************
       * CREATE GROUP 1
       ****************************************/
      dispatch(createAccountGroupV1R({
        groupId: Math.floor(Math.random() * (50) + 2).toString(),
        name: "Fitness Leaders",
        businesses: [pageText1, pageText2],
      }));

      /****************************************
       * CREATE GROUP 2
       ****************************************/
       dispatch(createAccountGroupV1R({
        groupId: Math.floor(Math.random() * (50) + 2).toString(),
        name: "Retail Leaders",
        businesses: [pageText3, pageText4],
      }));

      
    }));
  };
}



