import { createSlice } from '@reduxjs/toolkit';
import { v1 as uuidv1 } from 'uuid';
import KeyMappings from 'Core/KeyMappings';

const initialState = {
  windowSize: {},
};
  

const resetState = {
  
};

function getVal(params) {
  let {source, defaultValue} = params;
  let value = defaultValue;
  try {
    value = source;
  } catch (e) {
    value = defaultValue;
  }
  if (value === undefined) {
    value = defaultValue;
  }
  return value;
}


const userInterfaceSlice = createSlice({
    name: 'userInterface',
    initialState,
    reducers: {
        resetLocalStateUserInterfaceR: () => resetState,
        /*
        resetLocalStateGameR(state, action) {
          console.log("resetLocalStateGameR");
            
          return resetState;


        },
        */

        updateWindowSizeV1R(state, action) {
          console.log("action | updateWindowSizeV1R");
          console.log(action);
          console.log("action.payload");
          console.log(action.payload);
          console.log(JSON.stringify(action.payload));

          //Doesn't appear to work...
          state.windowSize = action.payload;
   


        },

        
        
        

    }
})

export const { resetLocalStateUserInterfaceR, updateWindowSizeV1R,

} = userInterfaceSlice.actions;

export default userInterfaceSlice.reducer;


