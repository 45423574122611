
import { configureStore } from '@reduxjs/toolkit';
import logger from 'redux-logger';
import thunk from 'redux-thunk';
import rootReducer from 'Reducers';
import { loadLocalStorageState } from 'Store/localStorage';
import { loadSessionStorageState } from 'Store/sessionStorage';
//const persistedState = loadLocalStorageState();
const persistedState = loadSessionStorageState();

/*******************************
 * Haven't worked out how to operate both Local and Session at the same time.
 * Example. When Payment is complete, provided a redirect link. User credentials are lost.
 * We could temp reference localStorage for this.
 *******************************/

let store = configureStore({
  reducer: rootReducer,
  preloadedState: persistedState, 
  devTools: process.env.NODE_ENV !== 'production',
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat([logger, thunk]),
});


export default store; 
