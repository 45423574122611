// Local Storage (maintains all state on page refresh)
//Converted to operate out of session storage
export const loadLocalStorageState = () => {
  try {
    //const serializedState = localStorage.getItem('state');
    const serializedState = localStorage.getItem('state');
    if (serializedState === null) {
      return undefined;
    }
    return JSON.parse(serializedState);
  } catch (err) {
    return undefined;
  }
};

export const saveLocalStorageState = (state) => {
  try {
    const serializedState = JSON.stringify(state);
    //localStorage.setItem('state', serializedState);
    localStorage.setItem('state', serializedState);
  } catch (err) {
    // Ignore write errors
  }
};


export const deleteLocalStorageState = () => {
  try {
    //localStorage.setItem('state', null);
    localStorage.setItem('state', null);
  } catch (err) {
    // Ignore write errors
  }
};