import { createSlice } from '@reduxjs/toolkit';
import { v1 as uuidv1 } from 'uuid';
import KeyMappings from 'Core/KeyMappings';

const initialState = {
  gameHudPubKey: '',

};
  

const resetState = {
  
};

const portalSlice = createSlice({
    name: 'portal',
    initialState,
    reducers: {
        resetLocalStatePortalR: () => resetState,
        /*
        addPilotR(state, action) {
          console.log("action");
          console.log(action);
          console.log("action.payload");
          console.log(action.payload);

          let pilotCrewId = action.payload.pilotCrewId;
          let pilotName = action.payload.pilotName;
          console.log(`pilotCrewId: ${pilotCrewId}`);
          console.log(`pilotName: ${pilotName}`);

          //check if the pilot is already setup
          let pilotAlreadySetup = false;
          for (let x = 0; x < state.coPilot.pilots.length; x++) {
            if (state.coPilot.pilots[x].pilotCrewId === pilotCrewId) {
              state.coPilot.pilots[x].pilotName = pilotName;
            }
          }
          if (pilotAlreadySetup === false) {
            state.coPilot.pilots.push({
              pilotCrewId: pilotCrewId,
              pilotName: pilotName,
            });
          }

          


        }*/
        
        

    }
})

export const { resetLocalStatePortalR, 

} = portalSlice.actions;

export default portalSlice.reducer;
