import Request from './Request';
import RequestRegion from './RequestRegion';

class GH_socialApi {

  static storeSocialCredentialV1(body, hudAccessToken) {
    console.warn('API | POST | Socials - Store Credentials');
    return Request.POST_json(`${process.env.GH_socialApi}/store-credential/v1`, body, hudAccessToken);
  }

  static triggerScanV1(body, hudAccessToken) {
    console.warn('API | POST | Socials - Trigger Scan');
    return Request.POST_json(`${process.env.GH_socialApi}/trigger-scan/v1`, body, hudAccessToken);
  }
  
  static getProfileListV1(body, hudAccessToken) {
    console.warn('API | POST | Socials - Get Profile List');
    return Request.POST_json(`${process.env.GH_socialApi}/get-profile-list/v1`, body, hudAccessToken);
  }
  static getProfileMetricDataV1(body, hudAccessToken) {
    console.warn('API | POST | Socials - Get Profile Metric Data');
    return Request.POST_json(`${process.env.GH_socialApi}/get-profile-metrics/v1`, body, hudAccessToken);
  }

  static getProfileDataV1(body, hudAccessToken) {
    console.warn('API | POST | Socials - Get Profile Data');
    return Request.POST_json(`${process.env.GH_socialApi}/get-profiles/v1`, body, hudAccessToken);
  }
  static getPostDataV1(body, hudAccessToken) {
    console.warn('API | POST | Socials - Get Post Data');
    return Request.POST_json(`${process.env.GH_socialApi}/get-posts/v1`, body, hudAccessToken);
  }


  
  static dbWriteTest(hudAccessToken) {
    console.warn('API | POST | DB Write Test');
    return Request.POST_json(`${process.env.GH_socialApi}/db-write-test/v1`, null, hudAccessToken);
  }


  

  

}

export default GH_socialApi;

